$base-font: 'Manrope';

$color-primary-green: #03cc90 !default;
$color-primary-mid-dark-green: #02a172 !default;
$color-primary-dark-green: #018d63 !default;
$color-primary-red: #f23030 !default;
$color-primary-pink: #fb9cb3 !default;
$color-primary-yellow: #fecf2f !default;
$color-primary-orange: #f3631b !default;
$color-primary-cream: #f4ede1 !default;

$color-secondary-lighter-green: #59d7af !default;
$color-secondary-pattern-green: #d0f2e7 !default;
$color-secondary-blue: #7fb2ff !default;
$color-secondary-blue-dark: #688da6 !default;
$color-secondary-lighter-orange: #fcab54 !default;
$color-secondary-pale-green: #e6d8f2 !default;
$color-secondary-pale-pink: #ddd5f7 !default;
$color-secondary-pale-yellow: #fffaea !default;

$background-white: #ffffff !default;
$background-card: #f6f8fb !default;
$background-dark-gray: #d1d9d7 !default;
$background-light-blue-300: #e1f7ff;
$background-light-blue-500: #acd8eb;

$color-system-inactive: #bdcdd8 !default;
$color-system-stroke-gray: #d8dddc !default;
$color-system-blur-black: #000000 !default;
$color-system-blur-blue: #0d3778 !default;
$color-system-blur-white: #ffffff !default;
$color-system-divider-gray: #d0c8c8 !default;

$color-text-black: #232323;
$color-text-light-black: #3f3f3f;
$color-text-gray: #6f8799;

$color-b-w-1000: #000;
$color-b-w-900: #232323;
$color-b-w-800: #3f3f3f;
$color-b-w-300: #d0cbcb;
$color-b-w-200: #e7e7e7;
$color-b-w-100: #ebeeef;
$color-b-w-50: #f1f3f6;
$color-b-w-20: #f9f9fa;
$color-b-w-10: #f6f8fb;
$color-b-w-0: #fff;

$color-slate-700: #116089;
$color-slate-600: #5f798c;
$color-slate-550: #6f8799;
$color-slate-500: #8faabd;
$color-slate-400: #c1cdd6;
$color-slate-350: #d1d9df;
$color-slate-300: #c7dde7;
$color-slate-250: #edf0f4;
$color-slate-200: #dbe7ed;
$color-slate-100: #e7eff3;
$color-slate-50: #f3f7f9;

$color-blue-600: #3e61df;
$color-blue-500: #5e9eff;
$color-blue-400: #7fb2ff;
$color-blue-200: #cad5fb;
$color-blue-100: #e1edff;
$color-blue-50: #f0f7fe;

$color-green-900: #072c1d;
$color-green-800: #0a422b;
$color-green-700: #0e583a;
$color-green-600: #13794f;
$color-green-500: #03cc90;
$color-green-400: #59d7af;
$color-green-300: #acebd7;
$color-green-200: #d0f2e7;
$color-green-100: #d7f7f0;
$color-green-50: #e6f8f2;
$color-green-30: #effbf7;

$color-yellow-700: #f9a40f;
$color-yellow-600: #f9b531;
$color-yellow-500: #efbc50;
$color-yellow-400: #fcda9d;
$color-yellow-350: #ffecac;
$color-yellow-300: #ffe9c9;
$color-yellow-200: #fff3db;
$color-yellow-100: #fffaea;

$color-orange-700: #a64d1d;
$color-orange-650: #c45313;
$color-orange-600: #ff6600;
$color-orange-400: #ff842c;
$color-orange-300: #ffa84a;
$color-orange-200: #ffd6b9;
$color-orange-150: #fde0d1;
$color-orange-100: #ffe9d4;
$color-orange-50: #fdeddc;

$color-red-500: #db1919;
$color-red-300: #fa8989;
$color-red-200: #ffdbe3;
$color-red-100: #ffefef;
$color-red-50: #fff5f7;

$color-pink-400: #fb9cb3;
$color-pink-200: #feebf0;

$color-purple-500: #8654b9;
$color-purple-200: #ecdff9;

$color-brown-500: #bf7b3a;
$color-brown-400: #d79d68;
$color-brown-200: #e1c3b9;
$color-brown-100: #f4ede1;

$color-teal-600: #14808f;

$color-gradient-08: linear-gradient(89.79deg, #7fb2ff 15.84%, #f1f6ff 124.49%);
$color-gradient-07: linear-gradient(
  89.79deg,
  #7fb2ff 15.84%,
  #fb9cb3 81.81%,
  #faaf05 149.99%
);
$color-gradient-06: linear-gradient(
  93.37deg,
  #03cc90 -60.11%,
  #03cc90 -60.08%,
  #7fb2ff 78.66%,
  #fb9cb3 154.89%
);
$color-gradient-05: linear-gradient(93.4deg, #45a5e9 8.91%, #295e83 174.37%);
$color-gradient-04: linear-gradient(
  93.41deg,
  #03cc90 -53.1%,
  #7fb2ff 44.95%,
  #295e83 126.43%
);
$color-gradient-03: linear-gradient(
  273.45deg,
  #295e83 -28.02%,
  #7fb2ff 36.31%,
  #fb9cb3 80.75%,
  #faaf05 144.59%
);
$color-gradient-02: linear-gradient(
  93.42deg,
  #03cc90 10.03%,
  #7fb2ff 67.7%,
  #295e83 125.88%
);
$color-gradient-01: linear-gradient(89.79deg, #7fb2ff 15.84%, #fb9cb3 111.54%);

// Map colors
$color-primary: $color-primary-green !default;
$color-primary-light: $color-secondary-lighter-green !default;
$color-primary-dark: $color-primary-dark-green !default;
$color-primary-mid-dark: $color-primary-mid-dark-green !default;

$color-warn: $color-primary-red;
$color-warn-light: #ff4444;
$color-warn-dark: #b20000;

$light-background: #fafafa;
$dark-background: #2c2c2c;
// Max layout width
$layout-max-width: 1440px !default;

//Materiel styles
$mat-color-primary: $color-primary;
$mat-color-primary-light: $color-primary-light;
$mat-color-primary-dark: $color-primary-dark;
$color-mat-accent: $color-primary;
$color-mat-accent-light: $color-primary-light;
$color-mat-accent-dark: $color-primary-dark;
$mat-dark-text: $color-text-black;
$mat-light-text: $color-b-w-0;
$mat-light-background: $light-background;
$mat-dark-background: $dark-background;
$mat-warn-color: $color-warn;
$mat-warn-color-light: $color-warn-light;
$mat-warn-color-dark: $color-warn-dark;
