@use '@angular/material' as mat;
// Material custom theme
@import '@angular/material/theming';
// Generator: https://materialtheme.arcsine.dev

// Light Theme Text
$dark-primary-text: rgba($mat-dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$button-color: $color-green-600;
$button-color-darker: $color-green-800;
$button-color-bright: #03cc90;
$button-color-mid-light: $color-green-200;
$button-color-lighter: $color-green-50;

$mat-light-theme-foreground: (
  base: $mat-dark-text,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($mat-dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: $mat-dark-text,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($mat-dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-primary-text: $mat-light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $mat-light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($mat-light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: $mat-dark-text,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $mat-light-text,
  icons: $mat-light-text,
  text: $mat-light-text,
  slider-min: $mat-light-text,
  slider-off: rgba($mat-light-text, 0.3),
  slider-off-active: rgba($mat-light-text, 0.3),
);

// Background config
// Light bg
$light-bg-darker-5: darken($mat-light-background, 5%);
$light-bg-darker-10: darken($mat-light-background, 10%);
$light-bg-darker-20: darken($mat-light-background, 20%);
$light-bg-darker-30: darken($mat-light-background, 30%);
$light-bg-lighter-5: lighten($mat-light-background, 5%);
$dark-bg-tooltip: lighten($mat-dark-background, 20%);
$dark-bg-alpha-4: rgba($mat-dark-background, 0.04);
$dark-bg-alpha-12: rgba($mat-dark-background, 0.12);

$mat-light-theme-background: (
  background: $mat-light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-bg-lighter-5: lighten($mat-dark-background, 5%);
$dark-bg-lighter-10: lighten($mat-dark-background, 10%);
$dark-bg-lighter-20: lighten($mat-dark-background, 20%);
$dark-bg-lighter-30: lighten($mat-dark-background, 30%);
$light-bg-alpha-4: rgba($mat-light-background, 0.04);
$light-bg-alpha-12: rgba($mat-light-background, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $mat-dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core();

// Theme Config

body {
  --primary-color: $mat-color-primary;
  --primary-lighter-color: $mat-color-primary-light;
  --primary-darker-color: $mat-color-primary-dark;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
  // Notch fix
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

$mat-primary: (
  main: $color-primary,
  lighter: $mat-color-primary-light,
  darker: $mat-color-primary-dark,
  200: $color-primary,
  // For slide toggle,
  contrast: (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: $color-mat-accent;
  --accent-lighter-color: $color-mat-accent-light;
  --accent-darker-color: $color-mat-accent-dark;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}

$mat-accent: (
  main: $color-mat-accent,
  lighter: $color-mat-accent-light,
  darker: $color-mat-accent-dark,
  200: $color-mat-accent,
  // For slide toggle,
  contrast: (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: $mat-warn-color;
  --warn-lighter-color: $mat-warn-color-light;
  --warn-darker-color: $mat-warn-color-dark;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
  main: $mat-warn-color,
  lighter: $mat-warn-color-light,
  darker: $mat-warn-color,
  200: $mat-warn-color,
  // For slide toggle,
  contrast: (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$theme: mat-light-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(
        $font-family: '"Manrope", sans-serif;',
      ),
    density: -1,
  )
);

$altTheme: mat-dark-theme(
  (
    color: (
      primary: $theme-primary,
      accent: $theme-accent,
      warn: $theme-warn,
    ),
    typography: mat.define-typography-config(),
    density: -1,
  )
);

// Theme Init
@include angular-material-theme($theme);
