@import 'src/theme/whitelabel/default/_theme.scss';

p {
  color: $color-text-light-black;
  margin: 0;
}

a {
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  text-decoration: underline;
  letter-spacing: 0.01em;
  color: $button-color;
}

// Define font sizes
.h1,
.h2,
.h3 {
  color: $color-text-light-black;
  margin: 0;
}

.h1 {
  font-size: 40px;
  line-height: 140%;
  font-weight: 400;

  &--bold {
    font-weight: 700;
  }
}

.h2 {
  font-size: 32px;
  line-height: 140%;
  font-weight: 400;

  &--bold {
    font-weight: 700;
  }
}

.h3 {
  font-size: 26px;
  line-height: 36px;
  font-weight: 400;

  &--bold {
    font-weight: 700;
  }
}

.h4 {
  font-size: 24px;
  line-height: 27px;
  font-weight: 400;

  &--bold {
    font-weight: 700;
  }
}

.paragraph {
  &--m {
    font-size: 16px;
    line-height: 25px;
    font-weight: 400;
  }
  &--xl {
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
  }
  &--l {
    font-size: 18px;
    line-height: 29px;
    font-weight: 400;
  }
  &--s {
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
  }
  &--xs {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }
  &--bold {
    font-weight: 700;
  }
  // default values
  @extend .paragraph--m;
}

.caption {
  font-size: 12px;
  line-height: 24px;
  font-weight: 600;

  &--small {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
  }

  &--big {
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;
  }
}

.link {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  font-weight: 700;
  cursor: pointer;

  &--bold {
    font-weight: 700;
  }
}

.button-text {
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
  color: $color-b-w-0;
  margin: 0 !important;
}
