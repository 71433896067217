/**
 * Breakpoints Mobile First
 */

$screen-sm: 576px;
$screen-md: 767px;
$screen-lg: 992px;
$screen-xl: 1200px;

/**
 * Breakpoint Mixins
 */

// Small devices
@mixin --sm {
  @media (min-width: #{$screen-sm}) {
    @content;
  }
}

// Medium devices
@mixin --md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

// Large devices
@mixin --lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}

// Extra large devices
@mixin --xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}

// Extra large devices
@mixin --xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

// Custom devices
@mixin --custom-min($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}

@mixin --custom-max($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}
