/**
 * Bootstrap settings
 */

// Specify your own gutters for every breakpoint. I use media-breakpoint-up so to avoid a default width it's better to always include "xs", and then you can do whatever you want
// Default Gutter
@each $grid-breakpoint, $grid-gutter-width in $grid-gutter-default-widths {
  @include media-breakpoint-up($grid-breakpoint) {
    $grid-gutter-half-width: $grid-gutter-width / 2;
    .container {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
    .row:not(.no-gutters, .row--gutter-2) {
      margin-right: -$grid-gutter-half-width;
      margin-left: -$grid-gutter-half-width;
    }
    .row:not(.no-gutters) > .col,
    .row:not(.no-gutters) > [class*='col-'] {
      padding-right: $grid-gutter-half-width;
      padding-left: $grid-gutter-half-width;
    }
  }
}
