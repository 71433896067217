/* You can add global styles to this file, and also import other style files */
@import 'theme/bootstrap/bootstrap-custom-grid';
@import 'theme/styles/font-google';
@import 'vars';
@import 'typo';
// Add material colors for white label
@import 'theme';

html {
  height: 100%;
  transition: 0.3s padding;
}

body {
  height: 100%;
  margin: 0;
  font-family: $base-font !important;
  transition: 0.3s border-radius;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background-color: $color-b-w-10 !important;
  color: $button-color-darker !important;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 13px !important;
  padding-bottom: 12px !important;
}
.mat-mdc-form-field-infix {
  min-height: 48px !important;
}

.mat-mdc-select-arrow-wrapper {
  display: none !important;
}

.app-root-inner {
  transition: 0.3s border-radius;

  @media screen and (max-width: 767px) {
    height: 100vh;
    overflow-y: scroll;
  }

  .mat-toolbar.header-bar .mat-toolbar-row {
    flex-wrap: nowrap;
    justify-content: space-between;
    z-index: 100;
  }
}

.app-version {
  font-size: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 15px;
  cursor: default;
  background: #eeeeee;

  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    right: -10px;

    a {
      background: white;
    }
  }
}

::-webkit-scrollbar {
  width: 4px;
  height: 10px;
  background-color: $color-b-w-10;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(111, 135, 153, 0.4);
  border-radius: 50px;
}

textarea:focus,
textarea:focus-visible,
input:focus,
input:focus-visible,
.mat-mdc-text-field-wrapper.mdc-text-field--focused {
  outline: none;
}

.mat-tooltip {
  white-space: pre-line !important;
}

.container-fluid {
  padding-block: 32px;
  max-width: $layout-max-width;

  &--bg-gray {
    background: $color-b-w-50;
  }

  &--nopadding {
    padding: 0;
    padding-block: 0;
  }

  &--full-height {
    height: 100vh;
  }

  &--full-inside-height {
    height: calc(100vh - 115px);
    overflow-y: scroll;
    overflow-x: hidden;

    .student-report-container {
      height: unset !important;
    }

    @media screen and (max-width: 767px) {
      height: calc(100vh - 165px);
    }
  }

  &--full-width {
    width: 100%;
  }
}

app-student-results .container-fluid--full-inside-height {
  @media screen and (max-width: 767px) {
    height: calc(100vh - 195px);
  }
}

.full-width {
  width: 100%;
}

.login-container,
.register-container {
  padding-block: 0;
  background-color: #fff;
}

.mat-form-field {
  width: 100%;
}

.mat-icon {
  &.mat-slate {
    color: $color-slate-700;
  }

  &.mat-green {
    color: $color-green-600;
  }

  &.mat-red {
    color: $color-red-500;
  }

  &.mat-disable {
    color: $color-slate-400;
  }
  &.mat-gold {
    color: #ffd700;
  }
  &.mat-gold {
    color: #ffd700;
  }
}

.mat-icon-button {
  &.mat-filled {
    background: $color-green-600;
    color: $color-b-w-0;
    max-width: 27px;
    max-height: 27px;
    width: 100%;
    display: flex;
    justify-content: center;

    &:hover {
      background-color: $color-green-700;
    }

    &:active {
      background-color: $color-green-800;
    }

    mat-icon.mat-icon {
      width: 18px !important;
      height: 19px !important;
      position: relative;
      top: -8px;
      color: $color-b-w-0;
    }
  }

  &.mat-light-filled {
    background: $color-b-w-0;
    color: $color-slate-600;
    position: absolute;
    right: 24px;
    top: 24px;

    &:hover {
      background-color: $color-green-600;
      color: $color-b-w-0;
    }

    &:active {
      background-color: $color-green-600;
      color: $color-b-w-0;
    }
  }

  &.mat-outlined {
    background: $color-b-w-0;
    width: 100%;

    .mat-icon {
      min-width: 24px !important;
      min-height: 24px !important;
      border-radius: 50%;
      color: $color-green-600;
    }

    &:hover {
      .mat-icon {
        background-color: $color-green-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-green-600;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-outlined-student {
    background: $color-b-w-0;
    color: #5b7385;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      border-radius: 50%;
      color: $color-orange-600;
    }

    &:hover {
      .mat-icon {
        background-color: $color-orange-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-orange-600;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-filled-student {
    background: $color-orange-600;
    color: $color-b-w-0;
    position: absolute;
    right: 24px;
    top: 24px;

    &:hover {
      background-color: $color-yellow-700;
    }

    &:active {
      background-color: $color-yellow-700;
    }
  }

  &.mat-light-filled-student {
    background: $color-b-w-0;
    color: $color-slate-600;
    position: absolute;
    right: 24px;
    top: 24px;

    &:hover {
      background-color: $color-orange-600;
    }

    &:active {
      background-color: $color-orange-600;
    }
  }

  &.mat-outlined-student {
    background: $color-b-w-0;
    color: #5b7385;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      border-radius: 50%;
      color: $color-orange-600;
    }

    &:hover {
      .mat-icon {
        background-color: $color-orange-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-orange-600;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-done {
    background: $color-b-w-0;
    color: $color-slate-600;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      background-color: $color-green-500;
      border-radius: 50%;
      color: $color-b-w-0;
    }

    &:hover {
      .mat-icon {
        background-color: $color-green-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-green-600;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-restart {
    background: $color-b-w-0;
    color: $color-slate-600;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      border-radius: 50%;
      color: $color-b-w-0;
      background-color: $color-blue-500;
    }

    &:hover {
      .mat-icon {
        background-color: $color-blue-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-blue-600;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-pause {
    background: $color-b-w-0;
    color: $color-slate-600;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      border-radius: 50%;
      color: $color-b-w-0;
      background-color: $color-blue-400;
    }

    &:hover {
      .mat-icon {
        background-color: $color-blue-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-blue-600;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-resume {
    background: $color-b-w-0;
    color: $color-slate-600;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      border-radius: 50%;
      color: $color-b-w-0;
      background-color: $color-orange-600;
    }

    &:hover {
      .mat-icon {
        background-color: $color-orange-700;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-orange-700;
        color: $color-b-w-0;
      }
    }
  }

  &.mat-cancel {
    background: $color-b-w-0;
    color: $color-slate-600;
    width: 100%;

    .mat-icon {
      min-width: 46px !important;
      min-height: 46px !important;
      border-radius: 50%;
      color: $color-b-w-0;
      background-color: $color-slate-400;
    }

    &:hover {
      .mat-icon {
        background-color: $color-slate-600;
        color: $color-b-w-0;
      }
    }

    &:active {
      .mat-icon {
        background-color: $color-slate-600;
        color: $color-b-w-0;
      }
    }
  }
}

.mdc-button.mdc-button--unelevated {
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  border: none;
  padding-inline: 2px;
  border-radius: 10px;
  height: 49px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0.25em;
  letter-spacing: normal !important;
  font-family: 'Manrope', sans-serif !important;

  .mat-icon {
    width: 24px !important;
    height: 24px !important;
  }

  &.mat-primary {
    background-color: $button-color;
    color: $color-b-w-0;
    width: 100%;
    border-radius: 10px;

    &:hover {
      background-color: $button-color-darker;
    }

    &:active {
      background-color: $button-color-darker;
    }

    .mat-icon {
      margin-right: 8px;
    }
  }

  &.mat-primary-inversion {
    background-color: $color-b-w-0;
    color: $color-b-w-800;
    width: 100%;
    border-radius: 10px;

    &:hover {
      background-color: $color-slate-50;
    }

    &:active {
      background-color: $color-slate-50;
    }
  }

  &.mat-accent {
    background-color: $color-secondary-blue;
    border-radius: 10px;

    &:hover {
      background-color: $color-secondary-blue-dark;
    }
  }

  &.mat-unset {
    background-color: unset;
    color: $color-primary;
    border-radius: 10px;

    .mat-icon {
      margin-right: 12px;
      position: relative;
    }
  }

  &.mat-gradient {
    background: linear-gradient(
      93.4deg,
      #fb9cb3 -162.5%,
      #faaf05 -126.7%,
      #45a5e9 8.91%,
      #295e83 174.37%
    );
    border-radius: 10px;
    color: $color-b-w-0;
    width: 100%;
    font-size: 18px;
    line-height: 25px;
    min-width: 268px;
    border-radius: 10px;

    &:disabled {
      color: $color-b-w-0 !important;
    }
  }

  &.mat-secondary {
    background-color: $color-b-w-0;
    color: $button-color;
    border: 1px solid $button-color;
    border-radius: 10px;
    width: 100%;

    .mat-icon {
      margin-right: 14px;
      height: 100% !important;
      display: flex;
    }

    &:hover {
      background-color: $button-color;
      color: $color-b-w-0;
      border: 1px solid $button-color;
    }

    &:active {
      background-color: $button-color;
      border: 1px solid $button-color;
      color: $color-b-w-0;
    }

    &:disabled {
      color: $color-slate-500 !important;
      border: 1px solid $color-slate-500;
      background-color: $color-b-w-0;
    }
  }

  &.mat-tertiary {
    background-color: transparent;
    color: $color-text-black;
    border-radius: 10px;

    &:hover {
      color: $button-color-darker;
    }

    &:active {
      color: $button-color;
    }

    &:disabled {
      color: $color-slate-500;
    }

    .mat-icon {
      margin-right: 8px;
      color: $button-color;
    }
  }

  &.mat-warning {
    background-color: $color-red-500;
    color: $color-b-w-0;
    border-radius: 10px;

    &:hover {
      background-color: $color-red-300;
    }

    &:active {
      background-color: $color-red-300;
    }

    &:disabled {
      background-color: $color-slate-500;
    }

    .mat-icon {
      margin-right: 8px;
      position: relative;
    }
  }

  &.mat-help {
    background-color: $color-blue-600;
    color: $color-b-w-0;
    border-radius: 28px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    padding: 14px 15px;

    &:hover {
      background-color: $color-blue-500;
    }

    &:active {
      background-color: $color-blue-500;
    }

    &:disabled {
      background-color: $color-slate-500;
    }

    .mat-icon {
      margin-right: 8px;
      position: relative;
    }
  }

  &:disabled {
    border-radius: 10px;
    cursor: not-allowed !important;
    color: $color-b-w-0;
    background-color: $color-slate-550;

    &:hover {
      background-color: $color-system-inactive;
    }

    .mat-icon {
      // //background-color: $color-b-w-0;
      color: $color-slate-400;
    }
  }

  .loading-spinner {
    margin-top: 13px !important;
    margin-bottom: 17px !important;
  }

  &--full {
    width: 100%;
  }

  &--aside {
    margin-right: 16px !important;
  }

  &.mat-primary-student {
    background: $color-gradient-05;
    color: $color-b-w-0;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    border-radius: 10px;

    &:hover {
      background: $color-slate-700;
    }

    &:active {
      background-color: $color-slate-700;
    }

    &:disabled {
      background: $color-slate-500 !important;
      color: $color-b-w-0 !important;
    }
  }

  &.mat-secondary-student {
    background-color: $color-b-w-0;
    color: $color-slate-700;
    border: 1px solid $color-slate-700;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    border-radius: 10px;

    &:hover {
      background-color: $color-slate-700;
      color: $color-b-w-0;
      border: 1px solid $color-slate-700;
    }

    &:active {
      background-color: $color-slate-700;
      color: $color-b-w-0;
      border: 1px solid $color-slate-700;
    }

    &:disabled {
      background-color: $color-b-w-0;
      border: 1px solid $color-slate-500;
      color: $color-slate-500;
    }
  }

  &.mat-tertiary-student {
    background-color: $color-slate-700;
    color: $color-b-w-0;
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    border-radius: 10px;

    &:hover {
      background-color: $background-white;
      color: $color-slate-700;
    }

    &:active {
      background-color: $background-white;
      color: $color-slate-700;
    }

    &:disabled {
      background-color: $color-slate-500;
      color: $color-b-w-0;
    }
  }
}

.mat-mdc-tab .mdc-tab__content {
  letter-spacing: normal;
}

.mdc-button.mdc-button--unelevated.mat-unset {
  color: #232323;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.cdk-overlay-pane {
  max-width: 1308px !important;
}

.loading-container-full {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

//OVERRIDES MATERIAL V15
.mat-mdc-checkbox {
  &.mat-accent {
    --mdc-checkbox-selected-icon-color: #018d63;
    --mdc-checkbox-selected-focus-icon-color: #018d63;
    --mdc-checkbox-selected-hover-icon-color: #018d63;
    --mdc-checkbox-selected-pressed-icon-color: #018d63;

    .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
      background-color: $button-color !important;
    }
  }

  .mdc-form-field .mdc-checkbox .mdc-checkbox__background {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 4px;
    border: 2px solid #8faabd;
    background-color: $button-color;

    .mdc-checkbox__checkmark {
      color: white !important;
    }
  }
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background {
    border-color: $button-color !important;
    background-color: $button-color !important;
  }
}

.remember-forgot-password {
  display: flex;
  align-content: flex-start;
  margin-bottom: 40px;
  justify-content: space-between;
  margin-top: 4px;

  p {
    padding-top: 0.25em;
  }

  .links-wrap {
    padding-top: 0.6em;
  }
}

mat-toolbar-row {
  min-height: 72px;
  height: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  height: unset !important;
  gap: 24px;
  padding-inline: 1.5em !important;

  @media screen and (max-width: 767px) {
    position: sticky;
    top: 0;
    background: white;
    box-shadow: 0 0 10px lightgray;
  }

  .toolbar-spacer {
    flex: 1 1 auto;
  }

  .center-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 0;
      left: 0;
      background: white;
      z-index: 100;
      padding-block: 0.75em;
      padding-left: 1em;
      width: 100%;
      justify-content: space-evenly;
      box-shadow: 0px 0px 10px lightgray;
    }

    button {
      display: flex;
      flex-direction: column;
    }

    .mat-mdc-unelevated-button .mdc-button__label {
      margin-top: 5px;
    }

    .mdc-button.mdc-button--unelevated.mat-unset {
      color: $color-slate-600 !important;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 19px;
      height: 100%;
      margin-inline: 1em;
      margin-top: 10px;

      @media screen and (max-width: 1200px) {
        padding: 0;
        margin-left: -10px;
      }

      @media screen and (max-width: 844px) {
        font-size: 12px;
      }
    }

    .mdc-button.mdc-button--unelevated.mat-unset.active-button {
      color: $color-orange-600 !important;
      border-bottom: 1.5px solid #ff6600;
      border-radius: 0;
    }
  }

  .left-wrap {
    display: flex;
    align-items: center;
    margin: 0px 24px;
    margin-right: 0;
    padding: 20px 0;

    /* .logo{
            margin-left:1em;
        } */

    @media screen and (max-width: 767px) {
      margin-right: 0px;
      margin-left: 0;

      img.logo {
        height: 40px;
      }
    }

    .mat-icon-button {
      display: flex;
      justify-content: center;
      align-self: center;
      margin-right: 15px;
    }
  }

  mat-toolbar-row .right-wrap {
    display: flex;
    padding: 20px;
    justify-content: flex-end;
  }

  .logout {
    width: 32px;
    height: 32px;
    color: $color-text-gray !important;
    margin-left: 20px;
    position: relative;
    top: 3px;

    mat-icon.mat-icon {
      width: 32px !important;
      height: 32px !important;
      position: relative;
      top: -10px;
      z-index: 9;
    }
  }

  .right-wrap {
    display: flex;
    margin-right: 24px;
    align-items: center;

    @media screen and (max-width: 854px) {
      .inline_text {
        gap: 0;
      }
    }

    @media screen and (max-width: 767px) {
      margin-right: -20px;
      flex-wrap: nowrap;
    }

    .task-btn {
      padding-inline: 1.25em;
    }

    .mdc-button.mdc-button--unelevated.mat-unset {
      @media screen and (max-width: 767px) {
        margin-right: 0.5em;
      }
    }
  }

  span.logo-wrap img {
    height: 55px;
    position: relative;
    width: auto;
    display: block;
  }

  .mat-button-base:not(.mat-icon-button) {
    padding: 0 14px;
    margin-left: 5px;
  }

  .icon-with-text mat-icon {
    padding: 0 0px 0 10px;
    margin-left: -10px;
  }

  .icon-with-text mat-icon {
    margin-right: 15px;
  }
}

body {
  background-color: #eee;
}

.dashboard_header_child {
  display: flex;
  margin-bottom: 50px;

  .dashboard_header_child_item {
    background-color: $color-b-w-0;
    margin: 0px 10px 0 0;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.task_container {
  display: flex;
}

.task {
  flex: 1;
  background-color: $color-b-w-0;
  padding: 16px;
  margin-top: 20px;
  box-shadow: 0px 4px 21px rgb(54 62 90 / 7%);
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    background: $color-b-w-0;
    box-shadow: 0px 4px 6px rgb(54 62 90 / 40%);
  }

  .task_header {
    display: flex;
  }

  .task_reading_info {
    display: flex;
    justify-content: space-between;
  }

  .task_reading_info_left,
  .task_footer_info {
    display: flex;
  }
}

.info_left {
  display: flex;
}

.teacher-content-section {
  max-width: 1440px;
  width: 100%;
  /* &.user-teacher {
    padding-top: 62px;
  } */
}

.teacher-content-container {
  width: 100%;
  margin: 0 auto;
  padding: 1em;

  .mat-mdc-table {
    border-radius: 12px;
  }

  @media screen and (min-width: 768px) and (max-width: 1200px) {
    width: calc(100% - 90px);
    margin-left: 90px;
    margin-bottom: 30px;

    &.no-margin {
      margin-left: 0;
      margin: 0;
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 0.5em;
    margin-left: unset;
  }
}

.show-in-larger-screen {
  @media screen and (min-width: 1199px) {
    display: block;
  }
}

section.teacher_sidebar_section {
  display: flex;
  height: 100%;
  width: 100%;

  .overlay-mobile-menu {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 11;
      width: 100%;
      height: 150%;
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(12px);
      z-index: 10;
      animation: fadeIn 0.45s;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .container-fluid {
    & > .row {
      min-height: 100%;
    }

    position: relative;

    @media screen and (max-width: 1199px) {
      .small-sidebar {
        width: 110px;

        .mat-icon {
          margin: auto;
        }
      }

      .large-sidebar {
        width: 220px;
      }
    }

    & > .row {
      height: 100%;
      //width: 100%;

      .col-md-3 {
        padding-right: 0 !important;

        @media screen and (max-width: 767px) {
          padding-right: 16px !important;
        }
      }

      .col-md-9 {
        padding-left: 0 !important;
      }
    }
  }
}

.teacher_bottom_menu_admin {
  //display:none;

  //@media screen and (max-width:767px){
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100vh;

  height: 76px;
  background-color: white;
  //}
}

.dashboard_header_teacher_item {
  background-color: $color-b-w-0;
  //margin: 0px 10px 0 0;
  border-radius: 12px;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  text-align: center;
}

.task_options {
  cursor: pointer;
  background-color: $background-white;
  padding: 16px;
  margin: 10px;
  box-shadow: 0px 4px 21px rgba(54, 62, 90, 0.07);
  border-radius: 12px;

  &:hover {
    background: $background-white;
    box-shadow: 0px 4px 6px rgb(54 62 90 / 40%);
    //box-shadow: 0px 15px 32px -6px rgba(24, 39, 75, 0.12), 0px 25px 45px -4px rgba(24, 39, 75, 0.05);

    .mat-unelevated-button.mat-primary {
      background-color: #02a172;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .button-text {
      color: $color-primary;
    }
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &_info {
    margin-bottom: 25px;
  }
}

.teacher_task {
  cursor: pointer;
  background-color: $background-white;
  padding: 16px;
  box-shadow: 0px 4px 21px rgba(54, 62, 90, 0.07);
  border-radius: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .view_button {
    margin-top: auto;

    .mat-unelevated-button.mat-primary {
      background-color: #13794f !important;
      border-radius: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 160%;
    }
  }

  &:hover {
    background: $background-white;
    box-shadow: 0px 4px 6px rgb(54 62 90 / 40%);
    //box-shadow: 0px 15px 32px -6px rgba(24, 39, 75, 0.12), 0px 25px 45px -4px rgba(24, 39, 75, 0.05);

    .mat-unelevated-button.mat-primary {
      background-color: #02a172;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;

    .button-text {
      color: $color-primary;
    }

    .avatar_img--small {
      width: 24px;
      height: 24px;
    }
  }

  &_footer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &_info {
    margin-bottom: 25px;
  }

  &--finished-latelly {
    border: 2px solid #03cc90;
    background: linear-gradient(
      5deg,
      rgb(255, 255, 255) 90%,
      rgb(5 204 144) 100%
    );

    &:hover {
      background: linear-gradient(
        5deg,
        rgb(255, 255, 255) 90%,
        rgb(5 204 144) 100%
      );
    }
  }
}

.teacher_task_row {
  width: 100%;
  margin-left: -8px;

  .col-md-6.teacher_task_col {
    padding: 8px !important;
  }
}

.teacher_tasks {
  &_pie_wrap {
    display: flex;
    justify-content: flex-end;
    width: 33.3%;
    flex-direction: column;

    & > div {
      width: 130px;
      display: flex;
    }
  }
}

.class_detail_info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  margin-inline: 1em;

  @media screen and (max-width: 767px) {
    margin-inline: 0.5em;
  }

  .mat-unset {
    color: $color-b-w-800;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
    padding-inline: 1em;

    mat-icon {
      color: $button-color !important;
    }
  }

  &-title {
    display: flex;
    gap: 1em;

    @media screen and (max-width: 1160px) {
      /*  display: block;
      margin-right: 1em;
      margin-top: 1em; */

      .student_group_name {
        text-align: left;
      }

      .avatar_img {
        margin-left: 0;
        margin-right: 10px;
        height: auto;
        width: 30px;
      }
    }
  }
  .inline_text.class_detail_title {
    @media screen and (max-width: 929px) {
      display: block;
    }
  }
  .inline_text {
    @media screen and (max-width: 767px) {
      gap: 0;
      margin-bottom: 0.5em;
    }
  }
  .teacher_tabs_header_buttons .mat-button-toggle-group {
    height: auto;
    /* @media screen and (max-width: 920px) {
      flex-direction: column;
    } */
  }
  &-buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    @media screen and (max-width: 565px) {
      flex-direction: column;
      padding-inline: 0;
    }
  }
}
.class_detail_download_btn,
.class_detail_profile_btn {
  &.mdc-button.mdc-button--unelevated.mat-unset {
    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: unset;
      padding-inline: 1em 0;

      .mat-icon {
        width: 35px !important;
        height: 35px !important;
      }
    }
  }
}
.class_detail_download_btn.mdc-button.mdc-button--unelevated.mat-unset {
  @media screen and (max-width: 767px) {
    margin-left: 1em;
  }
}

.toggle-tabs {
  .mat-mdc-tab-header {
    margin-top: 1em;
  }
  .mat-mdc-tab-list {
    border-bottom: 1px solid #d4d6d8;
  }

  .mat-mdc-tab-header-pagination-controls-enabled
    .mat-mdc-tab-header-pagination {
    @media screen and (min-width: 1199px) {
      display: none;
    }
  }

  .mdc-tab__content .mdc-tab__text-label {
    font-weight: 600;
    color: #5f798c;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active
    .mdc-tab__text-label {
    color: #3f3f3f;
  }

  .mdc-tab-indicator {
    justify-content: flex-start;
  }

  .mat-mdc-tab:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline,
  .mat-mdc-tab-link:not(.mat-mdc-tab-disabled)
    .mdc-tab-indicator__content--underline {
    border-color: var(
      --mdc-tab-indicator-active-indicator-color,
      $button-color
    );
    width: 30%;
    border-width: 5px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}

.class_detail_table {
  background: $color-b-w-0;
  padding: 20px;
  margin-top: 16px;
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }

  .mat-mdc-button-persistent-ripple.mdc-icon-button__ripple,
  .mat-mdc-focus-indicator {
    display: none !important;
  }
}

.mat-mdc-form-field.position_right {
  text-align: right;
}

.mat-mdc-form-field-wrapper {
  background-color: #fafafa;
}

.mdc-line-ripple {
  //OVERRIDES MATERIAL V15 PRESETS
  display: none !important;
}

.licenses {
  margin-bottom: 1em;
  margin-right: -30px;
  width: 20%;

  @media screen and (max-width: 767px) {
    margin-right: 0;
  }
}

.student_info {
  display: flex;
  flex-wrap: wrap;

  .student_info_item {
    background-color: $color-b-w-0;
    margin: 0px 5px 10px 5px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 46%;
  }

  .medals-wrap {
    display: flex;
    position: relative;
    left: 8px;
    margin-bottom: 12px;

    .bronze-medal {
      background: #d79d68;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .bronze-medal-border {
        border: 1px solid #bf7b3a;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: #bf7b3a;
      }
    }

    .silver-medal {
      background: #f0f0f0;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: -8px;

      .silver-medal-border {
        border: 1px solid #d1d1d1;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: #d1d1d1;
      }
    }

    .gold-medal {
      background: #fcda9d;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: -16px;

      .gold-medal-border {
        border: 1px solid #faba48;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: #faba48;
      }
    }
  }
}

.student_chart {
  background: $color-b-w-0;
  padding: 16px;
  height: 100%;
  border-radius: 12px;
  //position: relative;
  min-height: 388px;

  @media screen and (min-width: 1200px) {
    width: 100%;
  }

  .ngx-charts-outer {
    height: unset !important;
    // overflow-x: scroll; // hiding for LLTS-571
    width: 100%;
  }

  /* .chart-container {
        max-width: 1000px;
        height: 200px;
    } */
}

.ngx-charts-outer {
  display: flex;
  flex-direction: column-reverse;
}

.pie-grid-item {
  text.label.percent-label {
    font-size: 20px !important;
    display: flex;
    //transform: translateY(12px);
    dominant-baseline: mathematical;
  }

  text.label {
    display: none;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
.mat-progress-bar-fill::after {
  background: rgb(240, 160, 2);
  background: linear-gradient(
    90deg,
    rgba(240, 160, 2, 1) 20%,
    rgba(9, 121, 89, 1) 66%,
    rgba(50, 255, 0, 1) 100%
  );
}

.register_backButton {
  position: absolute;
  left: 0;
  top: 0;
}

.login_wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 100vh;
  z-index: 999;

  @media screen and (max-width: 767px) {
    width: 92vw;
  }

  .login_img {
    margin-bottom: 80px;
  }

  .login_footer {
    text-align: center;
    margin-top: 31px;
  }

  form {
    max-width: 460px !important;
    width: 100%;

    div.forgot-password {
      display: flex;
      flex-direction: column;
    }
  }
  .instructionVideo_wrap {
    padding-top: 1.5em;
  }

  .h2 {
    color: $color-b-w-900;
  }

  mat-label {
    color: $color-b-w-900;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
}

app-forgot-password-stepper .login_wrap {
  min-height: 90vh;
}

body.route--forgot-password {
  overflow-x: hidden;
}

//OVERRIDES MATERIAL V15 PRESETS
.mdc-notched-outline__leading {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.mdc-notched-outline__trailing {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent !important;
}

.mat-mdc-select-value {
  font-family: 'Manrope', sans-serif;
}

.login-input-wrapper {
  display: flex;
  flex-direction: column;
}

.mat-mdc-text-field-wrapper {
  &.mdc-text-field--invalid {
    background-color: #fff1f0;
  }

  mat-icon.mat-icon {
    color: $color-text-gray;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--focused mat-label {
  color: $button-color-bright !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--invalid mat-label {
  color: red !important;
}

form {
  max-width: 364px;
  width: 100%;
  margin-top: 36px;
}

.mat-icon {
  width: 24px !important;
  height: 24px !important;
}

.login_left {
  padding-right: 0 !important;
  background-color: $background-white;
}

.login_right {
  padding-left: 0 !important;
}

.task_detail_header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    .h3.h3--bold {
      font-size: 20px;
      line-height: inherit;
    }
  }
}

.backButton {
  border: 1px solid $button-color !important;
  background-color: transparent !important;
  box-shadow: none !important;
  height: 40px !important;
  width: 40px !important;

  mat-icon.mat-icon {
    color: $button-color !important;
  }
  &.toc mat-icon.mat-icon {
    padding: 0;
  }
}

.circle_button {
  border: 1px solid $button-color !important;
  position: relative;
  z-index: 9;

  mat-icon.mat-icon {
    color: $button-color !important;
    position: relative;
    top: -2px;
  }

  & + .circle_button {
    margin-left: 15px;
  }
}

img {
  width: 100%;
  height: auto;
}

.mat-toolbar-multiple-rows {
  max-width: $layout-max-width;
  margin: 0 auto;
  background-color: $background-white;
  // border-bottom: 1px solid $color-system-stroke-gray;
}

.gender-headline {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;

  img {
    width: 25px;
    margin-left: 10px;
    height: 25px;
  }
}

.gender-index {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

.register_step {
  background-color: $color-primary;

  .step_header_wrap p {
    color: $color-b-w-0;
  }

  header.step_header {
    margin-top: 100px;
    margin-left: 44px;
    position: sticky;
    top: 30px;
  }

  .step_header_wrap {
    margin-bottom: 37px;
    position: relative;
  }

  .mat-icon {
    color: $color-primary;
    width: 43px !important;
    height: 43px !important;
  }
}

.register_content {
  background-color: $light-background;
  height: 100%;

  .mat-mdc-form-field-flex mat-icon.mat-icon {
    position: absolute;
    right: 0;
    width: 19.5px !important;
    height: 19.5px !important;
    color: $color-secondary-blue;
  }

  .img_wrap {
    max-width: 193px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 89px;
    margin-top: 50px;
  }

  .form_header {
    margin-bottom: 36px;
    text-align: center;
  }

  .register_footer {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 35.5px;

    mat-icon {
      color: $color-primary;
      margin-right: 11px;
      width: 18px !important;
      height: 18px !important;
      position: relative;
      top: 4px;
    }
  }
}

.register_container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.register_progress_one,
.register_progress_two,
.register_progress_three {
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-left: 12px;
}

.register_progress_state {
  width: 94px;
  height: 8px;
  background-color: #d1d9df;
  border-radius: 70px;
  margin-right: 9px;
}

.register_progress_state.active {
  background: $color-primary;
}

.step_img {
  position: absolute;
  bottom: -5px;
  left: 48px;
}

.step_divider {
  background: $color-b-w-0;
  height: 86%;
  width: 2px;
  position: absolute;
  left: 20px;
  top: 47px;
}

.class_step_divider {
  background: $color-primary;
  height: 86%;
  width: 2px;
  position: absolute;
  left: 20px;
  top: 47px;
}

.step_header_content {
  min-height: 78px;
  height: 100%;
  cursor: pointer;
}

.verification_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .head_verification_img {
    width: 183px;
    margin-bottom: 40px;
  }

  .verification_header {
    margin-bottom: 20px;
  }

  .verification_button {
    min-width: 240px;
    max-width: 240px;
    width: 100%;
    margin-top: 36px;
    margin-bottom: 36px;
    border: none;
  }

  .footer_verification_img {
    width: 169px;
  }
}

.task-delete_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .task-delete_header {
    margin-bottom: 1em;
    margin-top: 2em;
  }

  .task-delete_button.mdc-button.mdc-button--unelevated.mat-primary {
    width: 240px;
    margin-bottom: 1em;
    border: none;
  }

  .footer_task-delete_img {
    width: 169px;
  }
}

//DIALOG STANDARD ANGULAR MATERIAL 15
.mat-mdc-dialog-container {
  padding: 0;
  overflow: hidden;

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 12px;
    overflow-y: auto;
    overflow: visible;
  }
}

.group-delete_container {
  display: flex;
  justify-content: center;
}

.group-delete_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 2em;

  .extra-padding-top {
    padding-top: 2em;
  }

  .group-delete_button {
    max-width: 240px;
    width: 100%;
    margin-block: 2em;
    border: none;
  }

  .footer_group-delete_img {
    width: 169px;
    height: auto;
  }
}

.task-delete-overlay-panel {
  .mat-mdc-dialog-container {
    max-width: 606px !important;
    box-shadow:
      0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  }
}

.student-delete_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .button_wrap {
    display: flex;
    gap: 16px;
    margin-top: 40px;
    width: 100%;
  }

  .student-delete_header {
    margin-bottom: 20px;
  }

  .student-delete_button {
    width: 100%;
    border: none;
  }

  .footer_student-delete_img {
    width: 169px;
  }
}

.delete-overlay-panel {
  .mat-mdc-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      @media screen and (min-width: 1200px) {
        padding: 4em !important;
      }
    }
  }
}

.teacher-delete_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .button_wrap {
    display: flex;
    gap: 16px;
    margin-top: 40px;
    width: 100%;
  }

  .teacher-delete_header {
    margin-bottom: 20px;
  }

  .teacher-delete_button {
    width: 100%;
    border: none;
  }

  .footer_teacher-delete_img {
    width: 169px;
  }
}

.verification-overlay {
  background: $color-primary;
}

.verification-overlay-panel {
  .mat-mdc-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgb(24 39 75 / 12%),
      0px 18px 88px -4px rgb(24 39 75 / 14%);
    border-radius: 12px;
    max-width: 786px;
    margin: 0 auto;
    width: 100%;
    padding: 84px 105px 50px 105px;
    position: relative;
  }
}

.add-teacher-success-panel {
  max-width: 608px !important;
  .mat-mdc-dialog-container {
    border-radius: 12px;
  }
}

.record-overlay-panel {
  max-width: 608px !important;

  .mat-mdc-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgb(24 39 75 / 12%),
      0px 18px 88px -4px rgb(24 39 75 / 14%);
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: #fff;
  }

  .record_overlay_wrap {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;

    .record_button {
      color: #8faabd;

      /* mat-icon.mat-icon {
        margin-bottom: 10px;
      } */
    }
  }

  h3 {
    margin-bottom: 16px;
  }

  .record_overlay_buttons {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .mat-mdc-unelevated-button {
      flex-basis: 48%;
    }
  }
}

.student_edit_overlay_buttons {
  margin-top: 26px;
  display: flex;
  flex-direction: row;

  .mat-secondary {
    margin-right: 8px;
  }

  .mat-primary {
    margin-left: 8px;
  }

  .mat-secondary,
  .mat-primary {
    width: 100%;
  }
}

.home_container {
  // background-color: $color-primary;
  // background-image: url("./assets/home-bg.svg");
  //padding-bottom: 150px;

  .home_content {
    max-width: 781px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 32px;

    h1,
    p {
      color: $color-b-w-0;
      text-align: center;
    }

    h1 {
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }

  .home_buttons {
    margin-top: 36px;
    margin-bottom: 45px;
  }
}

.static_container {
  background-image: url('./assets/static_background.svg');
  background-color: $background-white;
  min-height: calc(100% - 150px);

  .static_header {
    margin-bottom: 32px;
  }

  .static_content {
    padding: 53px 10% 53px 10%;

    @media screen and (min-width: $layout-max-width) {
      padding: 53px 294px 53px 294px;
    }
  }
}

.static_nav {
  display: flex;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;

  li {
    position: relative;
    margin-right: 40px;

    a {
      color: $color-text-light-black;
      text-decoration: none;
      line-height: 54px;

      &.active-link {
        color: $color-primary-dark-green;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: calc(50% - 4px);
          width: 8px;
          height: 8px;
          background: $color-primary-dark-green;
          border-radius: 50%;
        }
      }

      &:hover {
        color: $mat-color-primary;
      }
    }
  }
}

[hidden] {
  display: none !important;
}

.mat-icon.icon_circle {
  color: $color-primary;
  border-radius: 50px;
  background: #e6f8f2;
  padding: 11px;
  width: 55px !important;
  height: 44px !important;
  margin-bottom: 12px;
}

.icon_circle.check {
  width: 44px !important;
}

.mat-icon.icon_circle_warn {
  color: $color-warn;
  border-radius: 50px;
  background: #f230301a;
  padding: 11px;
  width: 44px !important;
  height: 44px !important;
  margin-bottom: 12px;
}

.class_summary {
  &--row-above {
    margin-top: 32px;
  }

  .group-height {
    display: flex;
    flex-direction: column;
  }
}

.teacher_tasks_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .button-text {
    color: $color-primary;
  }
}

.class_summary_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.position_absolute {
    position: absolute;
    top: 0;
    right: 1em;
  }

  .button-text {
    color: $color-primary;
  }
}

.student_tasks {
  &_instruction_image_wrap {
    margin-left: -20px;
    margin-right: -20px;
  }
}

.inline_text {
  display: flex;
  align-items: center;
  gap: 1em;

  &--student {
    gap: 0;
  }
}

.button-with-icon {
  display: flex !important;
  align-items: center;
  justify-content: space-around;
  gap: 0;

  .mat-icon {
    margin-right: 0;
  }
}

.set_inline {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button-text {
    color: $color-primary;
  }
}

.empty_tasks_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  width: 100%;

  img {
    max-width: 160px;
    width: 100%;
    margin-bottom: 23px;
  }

  .paragraph {
    color: $color-text-gray;
    width: 100%;
    text-align: center;
  }
}

.distance_left {
  margin-left: 8px;
}

.equal_height_col {
  display: flex;
  flex-direction: column;
}

.student_username {
  text-transform: capitalize;
}

.task_wrap {
  margin-bottom: 20px;
  flex-direction: revert;
  flex-wrap: wrap;
  gap: 16px;

  .row {
    margin-right: unset !important;
    margin-left: unset !important;
  }

  .task_options {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .view_button {
      margin-top: auto;

      .mat-unelevated-button.mat-primary {
        background-color: #13794f !important;
        border-radius: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 160%;
      }
    }
  }

  .task_option_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .expired_text {
    background-color: $background-card;
    color: #6f8799;
    padding: 0 5px;
  }

  .paragraph.paragraph--s {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    min-width: 120px;

    &--bold {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.award_earned {
  filter: unset !important;
}

.class_wrap {
  margin-bottom: 20px;

  .mat-icon-button {
    margin-left: 12px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
}

.task {
  .pie_chart_wrap {
    height: 90px;

    svg.ngx-charts {
      position: relative;
      right: 30px;
    }

    .chartValue {
      position: relative;
      top: -61px;
      display: flex;
      align-items: center;
      left: 3px;
      width: 34px;
      justify-content: center;
    }
  }
}

.view_button {
  button {
    width: 100%;
  }
}

mat-ink-bar.mat-ink-bar {
  width: 40px !important;
  height: 4px;
}

.task_detail_info {
  background-color: $background-white;
  border-radius: 12px;
  padding: 20px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  width: 100%;

  .mat-icon,
  .button-text {
    color: $color-primary;
  }
}

.task_detail_info_content {
  display: flex;
  margin-top: 16.5px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  .content_wrap {
    flex: 50%;

    .inline_text {
      align-items: flex-start;

      @media screen and (max-width: 767px) {
        padding-top: 1em;
      }
    }
  }

  .paragraph {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    min-width: 120px;

    &--bold {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.task_detail_table {
  background: $color-b-w-0;
  padding: 16px;
  border-radius: 12px;
}

.mat-mdc-table {
  width: 100%;

  .caption {
    color: $color-b-w-800;
  }

  .table_next_icon {
    color: #13794f;
  }
}

.mat-mdc-table .mdc-data-table__header-row {
  background-color: #f6f8fb;
  font-size: 14px;
  box-shadow: none;

  .mdc-data-table__header-cell {
    color: rgba(0, 0, 0, 0.6);
  }
}

.mat-column-username {
  text-transform: capitalize;
}

.task_incomplete_icon {
  color: #d1d9df;
}

.mat-column-align {
  &--center {
    text-align: center !important;
  }

  &--left {
    text-align: left !important;

    .mat-sort-header-container {
      justify-content: flex-start;
    }
  }

  &--right {
    text-align: right !important;
    padding-right: 1em !important;
  }
}

.mat-sort-header-container {
  justify-content: center;
}

th.mat-header-cell.mat-column-tendency {
  max-width: 176px;
}

th.mat-header-cell:last-of-type,
td.mat-mdc-cell:last-of-type,
td.mat-footer-cell:last-of-type {
  padding-right: 0;
}

.report_text {
  background: $color-b-w-0;
  padding: 32px 50px 32px 40px;
  border-right: 1px solid #d1d9df;
  position: relative;

  @media screen and (max-width: 1080px) {
    padding-inline-start: 1.5em;
    padding-inline-end: 0;
  }

  .class_detail_info {
    flex-wrap: nowrap;

    @media screen and (max-width: 1080px) {
      margin-left: 0.5em;
      flex-wrap: wrap;
    }
  }
}

.report_info {
  background-color: $background-card;
  padding: 32px;
  position: relative;

  @media screen and (max-width: 1200px) {
    padding: 1em;
    padding-right: 2em;
  }
  @media screen and (max-width: 767px) {
    padding: 2em;
  }

  .paragraph.paragraph--l--bold {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 1200px) {
    padding: 1em 2em 1em 1em;
  }
}

.book-reader-pagination {
  font-size: 20px;
  line-height: 27px;

  & > div {
    display: flex;
    align-items: center;
  }

  .book-reader-pagination-currentpage {
    // background: $color-b-w-0;
    // border: 1px solid #018D63;
    // border-radius: 30px;
    // width: 53px;
    height: 40px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.report_row_top {
  max-height: 112px;
}

.report_error_button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.report_card_tendency {
  background-color: $color-b-w-0;
  padding: 12px 24px;
  border-radius: 12px;
}

.report_card {
  margin-top: 12px;
  background-color: $color-b-w-0;
  padding: 16px 24px;
  border-radius: 12px;

  @media screen and (max-width: 1200px) {
    padding: 0.75em;
  }

  .ngx-charts {
    position: relative;
    top: -18px;
    left: -20px;
  }

  .chartValue {
    display: flex;
    position: absolute;
    top: 34px;
    left: 33px;
    width: 65px;
    justify-content: center;

    .paragraph.paragraph--l {
      font-size: 25px;
      line-height: 32px;
      font-weight: 700;
    }
  }
}

.report_header {
  margin: 16px 0 24px 0;
}

.workload {
  background-color: #ffdbe3;
  padding: 1px 0 8px 0;
  border-bottom: 2px solid $color-warn;
  margin: 0 0 0 0;
  width: 100%;
}

.replacement {
  background-color: #ecdff9;
  padding: 1px 0 8px 0;
  border-bottom: 2px solid #8654b9;
  margin: 13px 0 0 0;
  width: 100%;
}

.addition {
  background-color: #d7f7f0;
  padding: 1px 0 8px 0;
  border-bottom: 2px solid $color-green-600;
  margin: 13px 0 0 0;
  width: 100%;
}

.long_breaks {
  background-color: #d8e3ff;
  padding: 1px 0 8px 0;
  border-bottom: 2px solid $color-blue-600;
  margin: 13px 0 0 0;
  width: 100%;
  margin-top: 16px;
}

.line_bar_text {
  margin-top: 16px;
}

.report_audio_footer {
  // position: absolute; // without book-reader scroll
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
}

// audio
audio::-webkit-media-controls-panel {
  background: $color-b-w-10;
  border-radius: 12px;
}

audio::-webkit-media-controls-play-button {
  // background: url("./assets/play.svg") 50% 50% no-repeat;
}

.audio_wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $color-b-w-0;
  padding: 16px 24px;
  border-radius: 12px;
  width: 100%;

  .audio_file {
    max-width: 100%;
  }
}

.status-bar-container {
  height: 5px;
  margin: 17px 0px 0px 0;
  background: grey;
  position: relative;
  /* relative here */
}

.status-bar-indicator {
  height: 100%;
  border-radius: 25px;
  /* this will do the magic */
  -webkit-mask: linear-gradient($color-b-w-0 0 0);
  mask: linear-gradient($color-b-w-0 0 0);
  transition: 2s width;
  background: linear-gradient(
    to right,
    $color-primary-red,
    $color-primary-red 15%,
    $color-primary-yellow 15%,
    $color-primary-yellow,
    $color-primary-yellow 30%,
    $color-green-500 30%,
    $color-green-500,
    $color-green-500 70%,
    $color-primary-yellow 70%,
    $color-primary-yellow,
    $color-primary-yellow 85%,
    $color-primary-red 85%
  );
}

.statusbar-selector {
  left: calc(50% - 6.5px);
  position: relative;
  width: 13px;
  height: 3px;
  background: $color-b-w-900;
  top: -4px;
  transform: rotate(90deg);
}

.report_backButton {
  .backButton {
    position: relative;
    top: 0px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;

    @media screen and (max-width: 929px) {
      margin-right: 0;
    }
  }
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
}

.class_detail_button {
  margin-right: 10px !important;
}

.class_chart {
  width: 100%;
  background: $color-b-w-0;
  height: 100%;
  padding: 20px;
  min-height: 375px;
  border-radius: 12px;

  a {
    color: $color-b-w-900 !important;
  }

  ngx-charts-line-chart ngx-charts-chart .ngx-charts-outer {
    width: 100% !important;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  g.line-series path.line {
    stroke: $button-color;
  }
}

.class_header_item {
  background-color: $color-b-w-0;
  border-radius: 12px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
  flex-direction: row;

  .h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    margin-bottom: 12px;

    span {
      font-weight: 700;
      font-size: 20px;
      line-height: 100%;
      color: #8faabd;
    }
  }
}

.icon_position {
  top: 0 !important;
}

.student_info .col-md-6 {
  padding: 0px 6px 12px 6px !important;
}

.task_herder {
  margin-bottom: 18.5px;
}

.class_header_item {
  img {
    max-width: 132px;
    width: 100%;
  }
}

.padding_right {
  padding-right: 0px !important;
}

.padding_left {
  padding-left: 0px !important;
}

.avatar_img {
  width: 42px;
  margin-right: 14px;
  margin-left: 10px;

  &--small {
    width: 31px;
    height: 31px;
    margin-right: 15px;
  }
}

.classCreate_step,
.taskCreate_step {
  background-color: $background-card;
  border-right: 1px solid #d1d9df;

  .paragraph.paragraph--l--bold {
    color: $color-text-light-black !important;
  }

  .paragraph {
    color: $color-text-gray !important;
  }

  .step_header_wrap p {
    color: $color-primary;
  }

  header.step_header {
    margin-left: 44px;
    margin-bottom: 100px;
    background-color: $color-b-w-10;
    z-index: 9;
  }

  .step_header_wrap {
    margin-bottom: 37px;
    position: relative;
  }

  .mat-icon {
    color: $color-primary;
    width: 43px !important;
    height: 43px !important;
  }
}

.create_class_img {
  max-width: 256px;
  width: 100%;
  margin: 0 auto;
  margin-top: 140px;
  margin-bottom: 70px;
}

.create_class_content {
  background-color: $light-background;
  padding-left: 0 !important;

  .img_wrap {
    max-width: 193px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 89px;
    margin-top: 50px;
  }

  .form_header {
    margin-bottom: 36px;
    text-align: center;
  }
}

.create_task_content {
  padding-left: 16px !important;
}

.create_task_container {
  text-align: center;
}

//set left column sticky
.taskCreat_step_wrap-left {
  height: 100%;

  .mat-icon-button {
    position: sticky;
  }

  .create_class_img {
    margin-top: 0px !important;
    position: sticky;
    top: 10%;
  }

  .step_header {
    position: sticky;
    top: 43%;
    margin-bottom: 0 !important;
  }
}

.classCreate_step_wrap-left {
  height: 100%;

  .mat-icon-button {
    position: sticky;
  }

  .create_class_img {
    margin-top: 0px !important;
    position: sticky;
    top: 10%;
  }

  .step_header {
    position: sticky;
    top: 43%;
    margin-bottom: 0 !important;
  }
}

.create_class_container {
  margin-top: 50px;

  .class_create_step_wrap {
    .create_class_footer {
      background: $color-b-w-0;
      border-top: 0.5px solid $color-b-w-300;
      box-shadow:
        0px -8px 10px -6px rgb(24 39 75 / 2%),
        0px -18px 30px -4px rgb(24 39 75 / 2%);
      border-radius: 0px 0px 20px 0px;
      position: sticky;
      width: 108%;
      left: 0;
      bottom: 0;
      padding: 12px 34px 24px 0px;
    }

    .third_step {
      margin: 0 32px;
      min-height: calc(96vh - 280px);
      height: 100%;
    }
  }

  .print_note {
    text-align: center;
    padding: 0 50px 30px 50px;
    font-style: italic;
  }

  .paragraph.paragraph--l {
    color: #6f8799;
  }

  form {
    max-width: unset !important;
    width: 100%;
    margin-top: 0px;
  }

  .row.first_step,
  .row.second_step {
    max-width: 451px;
    width: 100%;
    margin: 0 auto;
  }

  .first_step {
    .col-4 {
      padding-right: 8px !important;
      padding-left: 8px !important;
    }
  }

  .mat-unelevated-button.mat-primary {
    max-width: 207px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
  }

  .create_class_form_header {
    margin: 0 auto;
    margin-bottom: 55px;
    max-width: 572px;
    width: 100%;
    text-align: center;

    h2 {
      margin-bottom: 24px;
    }

    .paragraph.paragraph--l {
      color: $color-text-gray !important;
    }
  }
}

.male_item {
  background: #eeeeee;
  border-radius: 4px;
  padding: 15px 0px 15px 19px;

  .mat-icon {
    color: #98a3b4;
    margin-right: 13px;
  }
}

.female_item {
  background: #e3ffe3;
  border-radius: 4px;
  padding: 15px 0px 15px 19px;

  .mat-icon {
    color: #00c90a;
    margin-right: 13px;
  }
}

.divers_item {
  background: #fffaea;
  border-radius: 4px;
  padding: 15px 0px 15px 19px;

  .mat-icon {
    color: $color-secondary-lighter-orange;
    margin-right: 13px;
  }
}

//Layout style
.thirdFormStep_header {
  // margin-top: 40px
  text-align: center;
}

.gender_header {
  display: flex;
  justify-content: space-between;
  max-width: 450px;
  margin: 0 auto;
  margin-top: 20.5px;
  margin-bottom: 35.25px;

  .mat-icon {
    margin-right: 12.25px;
  }
}

.male-icon {
  color: #7fb2ff !important;
}

.female-icon {
  color: #fb9cb3 !important;
}

.divers-icon {
  color: #fcab54 !important;
}

.create_class_button {
  max-width: 336px !important;
  width: 100%;
}

.input_icon_button {
  .mat-icon {
    color: $color-text-light-black !important;
    top: 0;
  }
}

//Numer input
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.close_class {
  position: relative;
  top: 32px;
  left: 30px !important;
  color: $color-primary-dark-green;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;

  mat-icon.mat-icon {
    width: 37px !important;
    margin-right: 16px;
  }
}

.start-task-overlay-panel .mat-mdc-dialog-container {
  min-height: 96vh;
}

.student_edit-overlay-panel,
.report-error-overlay-panel,
.record-rtc-result-overlay-panel,
.start-task-overlay-panel,
.books-overlay-panel,
.create-class-overlay-panel,
.student-report-overlay-panel,
.forgotpassword-overlay-panel,
.session-upload-overlay-panel {
  overflow: hidden;
  border-radius: 12px;
  box-shadow:
    0px 11px 15px -7px rgb(0 0 0 / 20%),
    0px 24px 38px 3px rgb(0 0 0 / 14%),
    0px 9px 46px 8px rgb(0 0 0 / 12%);
}

.student_edit-overlay-panel .mat-mdc-dialog-container,
.report-error-overlay-panel .mat-mdc-dialog-container,
.record-rtc-result-overlay-panel .mat-mdc-dialog-container,
.start-task-overlay-panel .mat-mdc-dialog-container,
.forgotpassword-overlay-panel .mat-mdc-dialog-container,
.create-class-overlay-panel .mat-mdc-dialog-container,
.student-report-overlay-panel .mat-mdc-dialog-container,
.session-upload-overlay-panel .mat-dialog-container {
  padding: 0;
  max-height: 96vh;
  overflow-x: hidden;
}

.student_edit-overlay-panel .mat-mdc-dialog-container,
.report-error-overlay-panel .mat-mdc-dialog-container {
  position: relative;
}

.student_edit-overlay-panel {
  max-width: 518px !important;
  right: 0;
  position: absolute !important;
  height: 100vh;
  background: #fff;
  border-radius: 0;
  overflow: visible;

  .mat-mdc-dialog-container {
    overflow: visible;

    .circle.light {
      position: absolute;
      left: -20px;
      top: 24px;
      float: right;
      background: #fff;
    }
  }
}

.student_add_overlay_container {
  padding: 40px;

  .student_add_overlay_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  form {
    width: 100%;
    max-width: unset;
    margin-top: 40px;
  }

  img {
    max-width: 120px;
    margin-bottom: 24px;
  }

  .mat-form-field-disabled {
    input {
      color: $color-b-w-800 !important;
    }
  }
}

.studentadd-overlay-panel {
  max-width: 518px !important;
  right: 0;
  position: absolute !important;
  height: 100vh;
  background: #fff;
  border-radius: 0;
  overflow: visible;

  .mat-mdc-dialog-container {
    padding: 0;
    max-height: 96vh;
    overflow: visible;
    position: relative;

    .circle.light {
      position: absolute;
      left: -20px;
      top: 24px;
      float: right;
      background: #fff;
    }
  }
}

.report-error_overlay_container {
  background: $color-b-w-0;
  padding: 32px;

  .report-error_overlay_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .h3 {
    margin-bottom: 26px;
  }
}

.task-repeat-overlay-panel .mat-mdc-dialog-container {
  position: relative;
  max-width: 741px;
  margin: 0 auto;
  width: 100%;
  max-height: 96vh;
  padding: 0;
  height: 100%;
}

.record-rtc-result-overlay-panel .mat-mdc-dialog-container {
  position: relative;
  max-width: 837px;
  margin: 0 auto;
  width: 100%;
}

.forgotpassword-overlay-panel .mat-mdc-dialog-container {
  max-width: 692px;
  width: 100%;
  margin: 0 auto;
}

.pdf-overlay-panel .mat-mdc-dialog-container {
  padding: 0;
  max-width: 741px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  border-radius: 20px;
}

.task-repeat_overlay_container {
  background: $background-card;
  padding: 40px 85px;

  .forgotpassword_overlay_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    max-width: 256px;
    width: 100%;
    display: flex;
    margin: 0 auto;
  }

  .h2 {
    margin-bottom: 24px;
    margin-top: 40px;
    text-align: center !important;
  }

  .paragraph.paragraph--l {
    margin-bottom: 70px;
    text-align: center;
  }

  form {
    max-width: 287px;
    width: 100%;
    margin: 0 auto;
  }

  .mat-button-base.mat-primary {
    margin-top: 20px;
    max-width: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    margin: 0 auto;
  }
}

.forgotpassword_overlay_container {
  background: $background-card;
  padding: 60px;

  .forgotpassword_overlay_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    max-width: 180px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 32px;
  }

  .h1 {
    margin-bottom: 24px;
    text-align: center !important;
  }

  .paragraph.paragraph--l {
    margin-bottom: 40px;
    text-align: center;
  }

  .mat-unelevated-button.mat-gradient {
    min-width: 240px !important;
    max-width: 240px !important;
    width: 100% !important;
  }
}

.pointer {
  cursor: pointer;
}

.pdf_overlay_container {
  background: $background-card;
  padding: 60px;

  &.no-bg {
    background-color: unset;
  }

  h2 {
    margin-bottom: 16px;
  }

  .pdf_overlay_img {
    max-width: 156px;
    width: 100%;
  }

  .pdf_overlay_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pdf_overlay_divider {
    margin: 0 10px;
  }

  .pdf_overlay_button {
    margin-top: 40px;
    max-width: 300px;
  }
}

/* all close-buttons follow this patter below and they have to be places inside a position: relative component. */
.mdc-fab--mini.mat-mdc-mini-fab.close-button {
  position: absolute;
  background: $color-b-w-0;
  color: #5f798c;
  width: 40px;
  height: 40px;
  top: 20px;
  z-index: 50;

  .mat-icon {
    padding: 0;
  }

  &--right {
    right: 20px;
  }

  &--left {
    left: -20px;
    @media screen and (max-width: 767px) {
      left: unset;
      right: 30px;
      background-color: #f6f8fb;
    }
  }
}

.back-btn {
  height: 49px !important;
  color: $color-slate-600 !important;
  font-weight: 700;
  font-family: inherit !important;
}
//full-page-panel layout
.close-button-with-text.mdc-button.mdc-button--unelevated {
  color: $color-slate-600;
  font-family: 'Manrope', 'sans-serif';
  max-width: 150px;
  flex-basis: 15%;
}

.full-page-panel {
  .header-wrapper {
    display: flex;
    //justify-content: space-between;
    margin: 1em;
    position: relative;
  }

  .chip_wrapper {
    flex-basis: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    margin-right: 10%;
  }
  .chip_outline {
    border: 1px solid $color-slate-200;
    border-radius: 56px;
    padding: 8px;

    .add-student-chip {
      background: $button-color-lighter;
      color: $button-color;
      border-radius: 24px;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
    }
  }

  .chip_content {
    grid-column: 1;
    grid-row: 1;
    max-width: 200px;
    width: 100%;
    font-size: 16px;
    /* margin-top: 7px;
    margin-left: 7px; */
    height: max-content;
    font-weight: 500;
    border-radius: 24px;
    padding: 0.5em 1em;
    text-align: center;
  }

  .buttons-wrapper {
    position: absolute;
    right: 0;
    width: 330px;
    display: flex;
    gap: 0.25em;
    justify-content: end;
    .mdc-button.mdc-button--unelevated {
      max-width: 160px;
      &.mat-unset {
        color: $color-slate-600;
      }
    }
  }
}

.row .circle.light {
  right: 35px;
  position: sticky;
}

.overlay_container {
  padding: 0;
  background-color: transparent;
}

.create_class_footer {
  background: $color-b-w-0;
  border-top: 0.5px solid $color-b-w-300;
  box-shadow:
    0px -8px 10px -6px rgb(24 39 75 / 2%),
    0px -18px 30px -4px rgb(24 39 75 / 2%);
  border-radius: 0px 0px 20px 0px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 12px 34px 24px 0px;
}

.task_create_headline {
  margin-bottom: 16px;

  h1 {
    margin-bottom: 24px;
  }
}

.task_selector.active {
  border: 2px solid #03cc90;
}

.task_selector {
  margin-top: 24px;
  background: $color-b-w-10;
  border-radius: 12px;
  max-width: 590px;
  width: 100%;
  padding: 32px;
  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border: 2px solid #03cc90;
  }

  img {
    max-width: 100px;
    width: 100%;
  }

  .task_selector_wrap {
    display: flex;
    align-items: flex-start;
    text-align: left;
  }

  .task_selector_content {
    margin-left: 32px;

    h3 {
      margin-bottom: 16px;
    }
  }
}

.task_divider {
  margin-top: 10px;

  & + .task_selector {
    margin-top: 10px;
  }
}

.taskCreate_step {
  .class_step_divider:last-child {
    display: none;
  }
}

.timeStep {
  img {
    width: 100px;
    height: auto;
    margin-bottom: 40px;
  }

  h3,
  h2 {
    margin-bottom: 24px;
  }

  .paragraph.paragraph--l {
    color: #6f8799;
  }
}

.accordion_header {
  h2 {
    margin-bottom: 24px;
  }

  .paragraph.paragraph--l {
    color: #6f8799;
  }
}

.timeStepForm {
  margin-top: 70px;
  display: flex;
  justify-content: center;

  .not-full-width {
    max-width: 300px !important;
  }

  mat-form-field.mat-form-field {
    margin: 24px 0 20px 0;
    max-width: 287px;
    width: 100%;
    text-align: center;
  }

  mat-datepicker-toggle {
    button.mat-mdc-icon-button.mat-mdc-button-base {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }
}

mat-expansion-panel.mat-expansion-panel {
  background: unset;
  box-shadow: none !important;
  border-bottom: 1px solid #d1d9df;

  .mat-content {
    overflow: visible;
  }

  .mdc-list-text {
    text-align: left;
  }

  mat-pseudo-checkbox.mat-pseudo-checkbox {
    border-radius: 61.25px;
    width: 20px;
    height: 20px;
  }

  .mat-pseudo-checkbox-checked::after {
    top: 4px;
    left: 3px;
  }
}

.mat-expansion-panel-header {
  padding: 0 12px !important;
  height: auto;
  min-height: 48px;

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
  }
}

.books-overlay-panel {
  height: 100%;
}

.book_overlay {
  max-width: 1200px;
  height: 100%;
  margin: auto;
}

.loading-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    margin-block: 2em;
    height: 80vh;
    width: auto;
  }

  .mat-progress-bar {
    width: 70%;
    margin: 30px auto;
  }
}

.book_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img.book_cover {
    position: relative;
    width: 190px;
    height: 277px;
    display: flex;
    background-color: #eee;
    margin-bottom: 12px;
    border-radius: 9px;
    object-fit: cover;
    z-index: 1;
  }

  .book {
    width: 210px;
    margin: 0 1em 50px 1em;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
    border-radius: 12px;
    border: 2px solid transparent;

    &:hover,
    &.active {
      border: 2px solid $color-blue-600;
      background-color: $color-blue-50;
      p.paragraph.paragraph--bold {
        color: $color-blue-600;
      }
    }
    p {
      white-space: nowrap;
      max-width: 191px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .book_button {
    position: absolute;
    top: 40%;
    left: 12%;
    display: none;
    z-index: 2;

    button {
      padding-inline: 1em;
    }
  }
}

.blur_bg {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.6);
  display: none;
}

.book:hover .book_button,
.book:active .book_button,
.book:active .blur_bg,
.book:hover .blur_bg {
  display: block;
}

.close_book {
  top: 0;
  left: 0;
  margin-right: 24px !important;
}

.book_list_header {
  .close_book {
    position: absolute;
    left: 10px;
    top: 4px;
  }
}

.book_list_content {
  overflow-y: scroll;
  max-height: calc(100vh - 300px);
  overflow-x: hidden;
  //padding-top: 3em;
  position: relative;

  @media screen and (max-width: 767px) {
    overflow: hidden;
    height: unset;
  }

  /* h2 {
    margin-left: 32px;
  } */

  mat-mdc-paginator {
    background-color: transparent;
  }
}

.book_list_content_loading {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book_preview_header {
  margin-top: 50px;
  margin-bottom: 32px;
}

.book_preview-inner-container {
  padding: 60px 113px 30px 113px;
}

.book_preview_wrap {
  .paragraph.paragraph--l {
    color: #6f8799;
  }

  .book_preview_title {
    margin-top: 40px;
  }
}

.book_preview_buttons {
  display: flex;
  gap: 16px;

  .mat-unelevated-button.mat-secondary {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.close_preview {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  color: #018d63;
  font-size: 20px;
  line-height: 27px;
  font-weight: 700;

  mat-icon.mat-icon {
    width: 37px !important;
    margin-right: 16px;
  }
}

.preview_overlay {
  background-color: $color-b-w-0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .row {
    height: calc(100vh - 110px);
    padding: 40px 44px 0 44px;
    overflow-y: auto;

    @media screen and (max-width: 767px) {
      margin-bottom: 60px;
    }
  }

  .task_chip {
    width: fit-content;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  .book_preview_footer {
    padding: 0 60px;

    @media screen and (max-width: 767px) {
      padding: 1em 2em;
    }

    button.mat-primary-student {
      max-width: 380px;
      width: 100%;
    }
  }

  .description {
    color: $color-slate-600;
    padding-bottom: 24px;
  }
}

.bookview_overlay {
  /* padding: 0; */
  min-height: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bookview_overlay_col {
  background-color: $color-b-w-0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.bookview_header {
  position: sticky;
  top: 0;
  z-index: 2;
  background: $color-b-w-0;
  padding: 41px 34px;
  border-bottom: 0.5px solid #d8dddc;
  box-shadow:
    0px 8px 10px -6px rgba(24, 39, 75, 0.02),
    0px 18px 30px -4px rgba(24, 39, 75, 0.02);

  .bookview_header-bookinfo {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
  }
}

.epub_left_content {
  // position: sticky;
  // margin-top: 45px;
  // margin-left: 32px;
  // top: calc(100% - 290px);
  position: fixed;
  margin-left: 32px;
  bottom: 200px;
  width: 18%;
  max-width: 185px;

  @media screen and (min-width: 1200px) {
    max-width: 265px;
  }

  img {
    width: 139px;
    margin-bottom: 18px;
    vertical-align: unset;
  }

  p {
    color: #6f8799;
  }
}

.primary_text {
  color: $color-primary-dark-green;
}

.bookview_footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  padding: 27px 32px;
  border-top: 0.5px solid #d8dddc;
  background: $color-b-w-0;
  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  margin-top: auto;

  .book_reader_settings {
    display: flex;
    justify-content: space-between;
    margin-right: 7px;

    button.mdc-button.mdc-button--unelevated.mat-secondary {
      padding-inline: 3em;
    }
  }

  .mat-flat-button.mat-gradient {
    width: auto;
  }
  .mat-unelevated-button.mat-primary {
    color: white;
    background: #13794f;
    border-radius: 10px;
    padding: 12px 16px;
    max-width: 380px;
    width: 100%;
    height: fit-content;

    @media screen and (max-width: 767px) {
      max-width: 100% !important;
    }
  }

  .mat-unelevated-button.mat-unset {
    color: #13794f;
    border-radius: 10px;
    padding: 12px 16px;
    border: 1px solid #13794f;
    max-width: 380px;
    width: 100%;
    height: fit-content;

    &.content-btn {
      color: #232323;
      font-size: 20px;
      font-weight: 400;
      padding-inline: 0.5em !important;
    }

    &.active {
      background-color: $color-green-50 !important;
      color: $color-green-600;
      border-color: $color-green-50;
    }

    @media screen and (max-width: 767px) {
      max-width: 100% !important;
    }
  }

  .bookview_footer_buttons {
    display: flex;
    margin-top: 26px;
    gap: 20px;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }
}

.right_botton {
  position: absolute !important;
  bottom: 24px;
  right: 126px;
}

app-task-create,
app-book-list {
  position: relative;
}

app-task-create {
  .step_container {
    background-color: #fafafa;
  }
}

.custom-accordion {
  .mat-expansion-indicator::after {
    transform: rotate(315deg);
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mat-expansion-panel-header-description {
    margin-right: 0 !important;
  }
  .mat-expansion-panel-content {
    background-color: transparent !important;
  }
  .mat-expansion-panel-body {
    padding: 0 0 16px 0 !important;
  }
}

.accordion_wrap {
  overflow: auto;
  max-height: calc(100vh - 310px);
  padding: 20px;

  .mat-expansion-indicator::after {
    border-width: 0 2px 2px 0;
    color: #000;
  }
  mat-expansion-panel.mat-expansion-panel {
    border: 1px solid $color-slate-350 !important;
  }
  .mat-expansion-panel {
    margin-bottom: 30px !important;
    border-radius: 12px !important;
  }
  mat-expansion-panel.mat-expansion-panel
    mat-pseudo-checkbox.mat-pseudo-checkbox {
    border-color: #232323;

    :last-of-type {
      border: none;
    }
  }
  .mat-expansion-panel-header-title {
    gap: 1em;

    .round-checkbox .mat-mdc-checkbox .mdc-checkbox {
      margin-bottom: 0.5em;
    }
  }

  .mat-mdc-list-item.mat-mdc-list-option.mdc-list-item.mdc-list-item--disabled {
    --mdc-list-list-item-disabled-state-layer-color: $color-yellow-200;
    background-color: $color-yellow-200;
  }

  .list-item-element {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .inline_text {
      min-width: 200px;
    }
  }

  .mat-expansion-panel-header.mat-expanded {
    height: 94px;
  }

  .license-amount {
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      font-weight: 700;
      color: $color-green-600;
    }

    span.warning {
      font-weight: 700;
      color: $color-red-500;
    }
  }

  mat-expansion-panel-header {
    padding-block: 16px !important;
  }

  .tag-not-updated {
    color: #ff6600;
    background-color: #fff3db;
    border-radius: 20px;
    padding: 0.5em 1em;
    align-self: center;
  }

  .tag-completed {
    color: $color-green-600;
    background-color: $color-green-100;
    border: 1px solid $color-green-300;
    border-radius: 20px;
    padding: 4px 8px;
    align-self: center;
    display: flex;
    gap: 6px;
    align-items: center;
  }

  mat-expansion-panel-header[aria-disabled='true']
    .mat-expansion-panel-header-title {
    opacity: 0.5;
  }
  .mat-expansion-panel-header-title {
    flex-basis: unset;
  }
  &.selected-students-chips {
    padding: unset;

    .mat-expansion-panel-content {
      background-color: unset;
    }
    mat-expansion-panel.mat-expansion-panel {
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
      border-radius: 0 !important;

      &:first-of-type {
        border-top: none !important;
      }
    }
  }
}

.accordion_footer {
  width: 109%;
  position: sticky;
  height: 110px;
  left: 0;
  bottom: 0;
  background-color: $color-b-w-0;
  margin-left: -15px;
}

.task_select_button {
  display: block !important;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.task_summary {
  background: $color-b-w-0;
  //box-shadow: 0px 4px 21px 0px rgba(54, 62, 90, 0.07);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  width: auto;
  text-align: left;
  border: 1px solid $color-slate-200;

  .task_summary_header {
    margin-bottom: 20px;

    .title {
      margin-bottom: 1em;
      color: $color-slate-500;
    }
  }

  .task_summary-book {
    display: flex;
  }

  .task_summary-bookTitle {
    max-width: 260px;
  }

  .paragraph--bold {
    font-weight: 700 !important;
  }

  .paragraph.paragraph--s {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    //min-width: 120px;
  }
  &.students-list {
    padding-bottom: 0;
  }
}

.task_summary_wrap {
  margin-top: 40px;
  height: 100%;
  @media screen and (max-width: 767px) {
    margin-bottom: 60px;
  }
}

.snackbar_inline_text {
  display: flex;
  align-items: center;
  gap: 1em;

  p {
    padding-left: 0;
    padding-right: 1em;
    max-width: 420px;
    font-size: 14px;
  }
}

.snack_icon {
  color: rgb(255 255 255 / 70%) !important;
  width: 24px !important;
  margin-right: 0 !important;
}

.snack_icon_close {
  color: $color-b-w-0 !important;
  width: 12px !important;
  cursor: pointer;
}

.mat-mdc-snack-bar-container {
  box-shadow: 0px 4px 21px 0px rgba(54, 62, 90, 0.07);
  border-radius: 8px !important;
  padding: 12px;
  min-width: 400px !important;
  width: 100%;

  &.snack-error {
    --mdc-snackbar-container-color: #fa8989;
    --mdc-snackbar-supporting-text-color: $color-text-light-black;
  }

  &.snack-success {
    --mdc-snackbar-container-color: white;
    --mdc-snackbar-supporting-text-color: $color-success-1000;

    .snack_icon {
      color: $color-green-600 !important;
    }
  }
}

app-dashboard-diagnostics {
  .container-fluid--full-height {
    height: 90vh;
  }
}

app-dashboard-student {
  position: relative;

  .container-fluid--full-height {
    height: calc(100vh - 115px);
    overflow-y: scroll;

    @media screen and (max-width: 767px) {
      overflow-y: scroll;
      height: calc(100vh - 180px);
    }
  }
}

.student_sidebar {
  background: $color-b-w-0;
  border-radius: 12px;
  padding: 26px 32px;
}

.record-rtc_result_card {
  .medals-wrap {
    display: flex;
    position: relative;
    left: 16px;

    .bronze-medal {
      background: #d79d68;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .bronze-medal-border {
        border: 1px solid #bf7b3a;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: #bf7b3a;
      }
    }

    .silver-medal {
      background: #f0f0f0;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: -8px;

      .silver-medal-border {
        border: 1px solid #d1d1d1;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: #d1d1d1;
      }
    }

    .gold-medal {
      background: #fcda9d;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: -16px;

      .gold-medal-border {
        border: 1px solid #faba48;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      p {
        color: #faba48;
      }
    }
  }
}

.student_task_wrap--action,
.student_self {
  .student_task {
    &_inner {
      &:hover {
        box-shadow: 0px 4px 6px rgba(54, 62, 90, 0.4);
      }
    }
  }
}

.student_task {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $color-b-w-0;
    background-image: url('./assets/reading_task_bg.png');
    background-repeat: no-repeat;
    background-position: center right;
    box-shadow: 0px 4px 21px rgba(54, 62, 90, 0.07);
    border-radius: 12px;
    padding: 30px;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
  }

  .student_task_header {
    .paragraph {
      color: $color-text-black;
    }

    .done_description {
      width: 100%;
    }

    .teacher_header {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      @media screen and (max-width: 767px) {
        justify-content: flex-start;
        border-bottom: solid #e1eeea 2px;
        padding-bottom: 1em;
      }
    }

    @media screen and (max-width: 767px) {
      &.set_inline {
        align-items: flex-start;
        gap: 1em;
        flex-direction: column;
      }
    }
  }

  .teacher_icon {
    text-transform: uppercase;
    background-color: $color-secondary-blue;
    color: $color-b-w-0;
    border-radius: 50%;
    padding: 10px;
    margin-right: 10px;
  }

  .student_task_info {
    margin: 10px 0 10px 0;
    display: flex;

    mat-icon.mat-icon {
      margin-right: 11px;
    }

    .student_task_text {
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      color: $color-text-light-black;

      strong {
        text-decoration: underline;
      }
    }
  }

  .student_readingpoints_info {
    margin: 25px 0 21px 0;
  }

  .student_task_footer {
    .mat-icon {
      width: 14px !important;
      height: 15px !important;
    }

    .inline_text {
      gap: 8px;
    }

    .student_task_footer-item {
      margin-top: 13px;
    }
  }

  .task_ended {
    background: #eef5fc;
    border-radius: 4px;
    padding: 0px 5px;
    color: #6f8799;
  }
}

.student_chip_wrap {
  display: flex;

  .student_chip {
    margin-left: 20px;
  }
}
.hidden-mobile {
  @media screen and (max-width: 767px) {
    display: none !important;
  }
}
.hidden-tablet {
  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.student_chip {
  border-radius: 24px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  mat-icon.mat-icon {
    margin-right: 7px;
    margin-top: 5px;
  }

  &__expired {
    background: $color-b-w-50;
    display: flex;

    p {
      color: $color-slate-600 !important;
    }

    .mat-icon {
      color: $color-slate-500 !important;
    }
  }

  &__minimum {
    background: $color-blue-50;
    display: flex;
    gap: 6px;

    p {
      color: $color-slate-600 !important;
    }

    .minimum {
      color: $color-green-600;
      background-color: $color-green-300;
      padding: 2px 8px;
      font-size: 13px;
      font-weight: 700;
      border-radius: 11px;
      display: flex;
      align-items: center;
    }

    app-student-progress-bar {
      width: 64px;
    }

    .progress-bar-container {
      margin: 0 !important;
    }
  }

  &__deadline {
    background: $color-green-50 !important;
    display: flex;

    strong {
      font-weight: 600;
    }

    p {
      color: $color-green-600 !important;
    }

    .mat-icon {
      color: #13794f;
    }

    &--reached {
      background: #fde0e0;

      p {
        color: #f23030;
      }

      .mat-icon {
        color: #f23030;
      }
    }
  }

  &__readingpoints_info {
    background: $color-blue-50;
    display: flex;

    mat-icon.mat-icon {
      margin-bottom: 5px;
      width: 15px !important;
      height: 17px !important;
      color: $color-orange-600;
    }
  }

  &__teacher {
    background: $color-blue-50;
    border: 1px solid $color-b-w-0;
    display: flex;
    width: 100%;
    min-width: 133px;
    max-width: max-content;

    .mat-icon {
      width: 20px !important;
      height: 20px !important;
    }

    p {
      color: $color-b-w-900;
    }

    .teacher_icon {
      background-color: #fcab54;
      border-radius: 50%;
      font-size: 10px;
      line-height: 14px;
      color: $color-b-w-0;
      margin-right: 7px;
      height: 18px;
      width: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.no_bg {
    @media screen and (max-width: 767px) {
      background: transparent;
      border: none;
      margin-left: -8px;
    }
  }
}

.teacher_nav {
  .teacher_icon {
    text-transform: uppercase;
    background-color: $color-slate-700;
    color: $color-b-w-0;
    border-radius: 50%;
    padding: 2px 12px;
    margin-right: 10px;
  }

  .task-btn {
    width: unset;
    line-height: unset;

    @media screen and (max-width: 767px) {
      display: none !important;
    }
  }
}

.read_controls_wrap {
  background-color: $background-card;
  min-height: 96vh;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-left: 48px;

  .read_controls {
    margin-bottom: 50px;
    width: 100%;

    .loading-screen.ng-star-inserted {
      position: absolute;
      top: 50%;
      width: 100%;
      left: 0;

      .paragraph.paragraph--l {
        max-width: 382px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-top: 40px;
      }
    }
  }

  .raeding_break {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      max-width: 308px;
      width: 100%;
    }
  }
}

.circle.record {
  background: #8faabd;
  color: $color-b-w-0;
  width: 60px !important;
  height: 60px !important;
  padding: 13px;
  border: 1px solid #8faabd;
  border-radius: 30px;
}

.circle.blue {
  padding: 11px;
  width: 48px !important;
  height: 48px !important;
  color: $color-secondary-blue;
  border: 0.75px solid $color-secondary-blue;
  border-radius: 30px;
  margin-right: 16px;
}

.student_task_finished {
  .student_task_info {
    background: $color-b-w-10;
    border-radius: 9px;
    padding: 17px;
  }

  .student_task_inner {
    background-image: unset;

    .set_inline {
      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1em;
      }
    }
  }
}

.student_task_wrap_expired {
  .student_task {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 44px;

    .set_inline {
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }
  }

  .student_task_inner {
    height: auto;
  }

  .mat-unelevated-button.mat-primary.mat-button-disabled,
  .mat-button.mat-primary:disabled {
    opacity: 1;
    min-width: 290px;
    width: 100%;
  }
}
// .img_bg {
//     background-image: url("./assets/read_overlay.svg");
//     background-size: contain;
//     background-repeat: no-repeat;
//     background-color: $background-card;
// }

.book_info_wrap {
  width: 100%;
  height: 100%;
  background-image: url('./assets/read_overlay.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: $color-b-w-10;

  .book_info {
    .book_image_wrap {
      position: relative;
    }

    &--countdown {
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        top: 0;
        width: 100%;
        left: 0;
        background: rgba(13, 55, 118, 0.25);
        backdrop-filter: blur(3px);
        z-index: 1;
      }

      .countDown {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 1;

        .counter {
          font-size: 130px;
          font-weight: 700;
          color: $color-b-w-0;
        }
      }
    }
  }
}

.read_controls_info {
  margin-top: 24px;
}

.record-navbar {
  width: 100%;
  margin-top: 60px;
}

.record_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #8faabd;
}

.record_buttons_wrap {
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-left: 28px;

  .record_button {
    &.mat-fab {
      box-shadow: none;
    }

    .mat-icon {
      margin-bottom: 10px !important;
    }
  }
}

.img_bg {
  padding: 0;
}

.icon_transparent {
  color: transparent !important;
}

.record_headline .mat-icon {
  color: $color-blue-400;
  margin-right: 18.5px;
}

.bookreader_record {
  padding: 50px 40px 100px 50px;
}

.start-task-overlay-panel {
  .bookreader_record {
    padding: 50px 15px 50px 50px;
    height: calc(96vh - 100px);
  }
}

.task_reader_content_wrap {
  height: 100%;
  background-color: $color-b-w-0;

  &--reading {
    height: calc(100% - 95px);
  }

  .book_image_wrap {
    img {
      max-width: 431px;
      width: 100%;
    }
  }

  .book_info {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h3 {
      margin-top: 21px;
    }
  }
}

.navbar-wrap {
  position: relative;
  left: -32px;
}

.time_wrap {
  margin-bottom: 80px;
  text-align: center;

  .paragraph.paragraph--l {
    margin-bottom: 8px;
  }

  span {
    font-size: 48px;
    font-weight: 700;
    line-height: 65.57px;
    letter-spacing: 1%;
  }
}

.finish .circle.record {
  background: $color-primary;
  border: 1px solid $color-primary;
}

.pause .circle.record {
  background: $color-blue-400;
  border: 1px solid $color-blue-400;
}

.refresh .circle.record {
  background: $color-blue-400;
  border: 1px solid $color-blue-400;
}

.play .circle.record {
  background: #fcab54;
  border: 1px solid #fcab54;
}

.cancel .circle.record {
  background: #f23030;
  border: 1px solid #f23030;
}

.book_reader_row_height {
  flex: 1;
  overflow: hidden;
  max-height: 100%;
}

.book-reader-navbar {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;

  button#book-prev {
    display: flex;
    border: 1px solid;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
  }

  button#book-next {
    display: flex;
    border: 1px solid;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }

  app-loading-spinner {
    position: absolute;
    display: block;
    margin-left: 33px;
    transform: rotate(270deg);
    opacity: 0.5;

    .loading-spinner {
      margin-top: 5px;

      &:before,
      &:after {
        width: 12px;
        height: 12px;
      }

      &:before {
        background: $color-primary-dark-green;
        border: 2px solid $color-primary-dark-green;
      }

      &:after {
        border: 2px solid $color-primary-dark-green;
      }
    }
  }
}

.bookview_header .book_view_close {
  margin-right: 16px;
}

.timer {
  font-size: 48px;
  line-height: 65.57px;
  letter-spacing: 1%;
  font-weight: 700;
}

.record-rtc_result_container {
  background: $background-card;
  padding: 60px 32px 32px 32px;

  .record-rtc_result_overlay_wrap {
    display: flex;
    flex-direction: column;

    .mdc-button.mdc-button--unelevated.mat-primary-student {
      margin: 0 auto;
    }
  }

  .mat-unelevated-button.mat-gradient {
    min-width: 240px !important;
    max-width: 240px !important;
    width: 100% !important;
    margin: 0 auto;
  }
}

.book_view_close {
  .paragraph.paragraph--l {
    margin-left: 12px;
  }
}

.record-rtc_result_card {
  background-color: $color-b-w-0;
  padding: 24px;
  border-radius: 12px;
  height: 100%;

  .record-rtc_result_info {
    margin: 5px 0;

    .mat-icon {
      margin-right: 10px;
    }
  }

  .result_card_header {
    margin-bottom: 6px;
  }

  .rating_wrap {
    margin-top: 23px;

    .mat-icon {
      color: $color-b-w-200;
    }

    .mat-icon.active {
      color: $color-secondary-blue;
    }
  }

  .result_medal_wrap {
    text-align: center;
    margin-bottom: 32px;

    img {
      max-width: 133px;
      width: 100%;
      margin-bottom: 16px;
    }
  }

  .result_all_medal_wrap {
    border-top: 1px solid #e4e4e9;
    padding-top: 12px;

    img {
      max-width: 102px;
      width: 100%;
    }
  }
}

.record-rtc_result_headline {
  margin-bottom: 26px;
}

.record-rtc_result {
  gap: 16px;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  margin-bottom: 24px;
}

.teacher_task_footer_info {
  margin-bottom: 22px;

  .set_inline {
    margin-bottom: 12px;
  }

  .mat-icon {
    margin-right: 8px;
  }

  .icon-warn {
    color: $color-primary-red;
  }
}

omit {
  display: inline-block !important;
}

.report-error-buttons {
  display: flex;
  justify-content: center;

  .mat-button-base.mat-unset {
    padding: 0 !important;
    min-width: 20px !important;
  }

  .report_done {
    color: $color-primary-dark-green;
    margin-right: 15px;
    cursor: pointer;
  }

  .report_close {
    color: $color-primary-red;
    cursor: pointer;
  }
}

th.mat-header-cell.cdk-header-cell.cdk-column-button.mat-column-button.ng-star-inserted {
  max-width: 160px;
}

.report-error_wrap {
  .mat-form-field {
    padding-top: 18px;
  }

  .mat-column-comment {
    width: 250px;

    .mat-mdc-text-field-wrapper {
      padding: 10px 10px;
    }

    .mat-mdc-form-field {
      padding-top: 5px;

      .mat-mdc-form-field-infix {
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }

    .mat-mdc-form-field {
      padding-bottom: 5px;
    }
  }

  .mat-column-button {
    .mat-unelevated-button {
      font-size: 14px;
      color: red;
      font-weight: 600;
      line-height: 19px;
    }
  }

  .mat-row.highlight-row .mat-column-button .mat-unelevated-button {
    color: #6f8799;
  }
}

.table_footer {
  margin-top: 30px;
}

.icon_unset {
  color: $color-b-w-800;
}

.add_class {
  padding: 0 !important;
  position: relative;
  top: 3px;
}

.username-with-avatar {
  display: flex;
  align-items: center;
  text-transform: capitalize;
  @media screen and (max-width: 979px) {
    padding-left: 1em;
  }

  img {
    max-width: 32px;
    height: auto;
    margin-right: 12px;
    width: unset;
  }
  h3 {
    font-size: 22px;
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;

    h3 {
      font-size: 22px;
      word-wrap: normal;
    }
  }
}

.nav_help {
  padding: 10px 16px !important;
  border-radius: 28px !important;
  background-color: $color-blue-400 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 27px !important;

  mat-icon.mat-icon {
    width: 14px !important;
    height: 15px !important;
    margin-right: 8px;
    border: 2px solid $color-b-w-50;
    border-radius: 50px;
    top: -2px;
    position: relative;
  }
}

.nav_notification_teacher {
  padding: 0 !important;
  min-width: unset !important;
  background: transparent !important;
  border-radius: 35px;

  mat-icon.mat-icon {
    width: 37px !important;
    height: 37px !important;
    color: #59d7af;
  }
}

.mat-expansion-panel-header-title {
  .paragraph.paragraph--l {
    color: $color-text-light-black;
  }
}

mat-expansion-panel-header {
  border: 0px !important;
}
.mat-expansion-indicator::after {
  margin-right: 30px;
}
.mat-expansion-indicator::after {
  margin-left: 30px;
}

.mat-expansion-panel-content {
  background-color: $background-card;
}

// .create_task_container {
//     .accordion_footer {
//         position: relative !important;
//     }
// }

.student_chart_selector,
.class_detail_result_dropdown,
.group_result_chart {
  z-index: 9;
  position: relative;

  .mat-mdc-text-field-wrapper.mdc-text-field {
    background-color: $color-b-w-0;
    max-width: 200px;
  }

  .mat-mdc-select-value-text {
    width: auto;
    color: $color-b-w-900;
    text-decoration: underline;
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    letter-spacing: 1%;
  }

  .mat-mdc-select-trigger {
    gap: 1em;
    width: unset;
  }

  .mat-mdc-select-arrow {
    color: $color-primary;
  }
}
.transparent-input {
  background-color: transparent;
}

.class_detail_result_dropdown {
  max-width: 300px !important;
  margin-left: 10px;
  top: 4px;
}

.line_chart_result_wrap {
  display: flex;
  overflow: hidden;
  flex: 1 1 auto;
  width: 100%;

  &--diagnostic {
    height: 70px;
  }

  &--training {
    height: 150px;
  }
  g.line-series path.line {
    stroke: $button-color;
  }
}

.static_nav li a.active-link {
  font-weight: 700;
}

.forgotpassword-overlay-panel {
  max-width: 692px !important;
}

.record-rtc-result-overlay-panel {
  max-width: 837px !important;
}

.row.bookview_header_row {
  display: flex;
  align-items: center;
}

// Studend overview CHART
ul.legend-labels.horizontal-legend {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  width: 80%;
  white-space: break-spaces;
  background: transparent;
  position: relative;
  left: 27px;
}

/* .student_chart_selector {
    max-width: 130px;
} */

// dashed lines

g.line-chart > g:last-of-type > g > g:nth-child(5) g.line-series path {
  stroke: #03cc90;
  stroke-dasharray: 6 10;
}

g.line-chart > g:last-of-type > g > g:nth-child(6) g.line-series path {
  stroke: $color-blue-400;
  stroke-dasharray: 6 10;
}

g.line-chart > g:last-of-type > g > g:nth-child(7) g.line-series path {
  stroke: #fecf2f;
  stroke-dasharray: 6 10;
}

g.line-chart > g:last-of-type > g > g:nth-child(8) g.line-series path {
  stroke: #fb9cb3;
  stroke-dasharray: 6 10;
}

span.legend-label-text {
  color: $color-b-w-800;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

//Checkbox line chart
.legend-label--checkbox.label_icon_white.legend-label--active
  .legend-label-color:after {
  font-family: 'Material Icons';
  content: 'done';
  color: $color-b-w-0;
}

.legend-label--checkbox.legend-label--active .legend-label-color:after {
  font-family: 'Material Icons';
  content: 'done';
}

// done icon color (dashed)
.legend-label--checkbox.primary.legend-label--active .legend-label-color:after {
  font-family: 'Material Icons';
  content: 'done';
  color: $color-primary;
}

.legend-label--checkbox.secondary.legend-label--active
  .legend-label-color:after {
  font-family: 'Material Icons';
  content: 'done';
  color: $color-secondary-blue;
}

.legend-label--checkbox.main_yellow.legend-label--active
  .legend-label-color:after {
  font-family: 'Material Icons';
  content: 'done';
  color: $color-primary-yellow;
}

.legend-label--checkbox.main_pink.legend-label--active
  .legend-label-color:after {
  font-family: 'Material Icons';
  content: 'done';
  color: $color-primary-pink;
}

// dashed checkbox
li.legend-label.primary.checkbox_dashed.legend-label--checkbox
  .legend-label-color {
  border: 1px dashed $color-primary;
  background: transparent !important;
}

li.legend-label.secondary.checkbox_dashed.legend-label--checkbox
  .legend-label-color {
  border: 1px dashed $color-secondary-blue;
  background: transparent !important;
}

li.legend-label.main_yellow.checkbox_dashed.legend-label--checkbox
  .legend-label-color {
  border: 1px dashed $color-primary-yellow;
  background: transparent !important;
}

li.legend-label.main_pink.checkbox_dashed.legend-label--checkbox
  .legend-label-color {
  border: 1px dashed $color-primary-pink;
  background: transparent !important;
}

app-class-stepper .step_container {
  background-color: #fafafa;
}

.result_number.reading_points,
.result_number.words_read {
  width: max-content !important;
  height: max-content !important;
  border-radius: 30px !important;
  min-width: 45px;
  min-height: 45px;
  padding: 0 10px;
}

.record_headline-title {
  max-width: 750px;
  width: 100%;
}

.icon-border {
  border: 1.5px solid #03cc90;
  border-radius: 50px;
  padding-left: 2px;
  width: 28px !important;
  height: 28px !important;
}

.book_preview_footer {
  width: calc(100% + 32px);
  position: sticky;
  height: 110px;
  left: 0;
  bottom: 0;
  background-color: $color-b-w-0;
  display: flex;
  gap: 20px;
  border-top: 1px solid #d1d9df;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  .mdc-button.mdc-button--unelevated.mat-primary,
  .mdc-button.mdc-button--unelevated.mat-secondary {
    max-width: 300px;
  }
}

.book_preview-inner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.book_preview_content {
  margin-top: 40px;
}

.stepper-icon {
  cursor: pointer;
}

.last_week-tooltip,
.pdfPrintLayout-tooltip,
.group-tooltip {
  white-space: pre-line;
  font-size: 17px;
  min-width: 25vw;
  width: 100%;
  padding: 15px;
  color: #03cc90 !important;
  background: $color-b-w-0;
  box-shadow: 0px 4px 21px rgb(54 62 90 / 7%);
  border: 1px solid #ccc;
}

.logout-tooltip .mdc-tooltip__surface,
.font-tooltip .mdc-tooltip__surface,
.toc-tooltip .mdc-tooltip__surface {
  white-space: pre-line;
  font-size: 17px !important;
  width: 100%;
  padding: 15px;
  color: #03cc90;
  background-color: $color-b-w-0 !important;
  box-shadow: 0px 4px 21px rgb(54 62 90 / 7%);
  border: 1px solid #ccc;
  text-align: center;
}

.toc-chapters {
  overflow-y: auto;
  height: calc(100% - 250px);
}

mat-icon.mat-icon.icon-border.group-question {
  height: 40px !important;
  top: 0 !important;
  width: 40px !important;
  padding: 0 !important;
  cursor: pointer;
}

.create-task-tooltip-wrap {
  width: 164px !important;
  height: 65px !important;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.create-task-tooltip {
  font-size: 17px;
  position: relative;
  background: #03cc90;
  padding: 10px;
}

.diagnose-tooltip {
  font-size: 18px;
  background: $color-green-600;
  padding: 10px;
  white-space: pre-line;
}

.email-tooltip,
.salutation-tooltip {
  font-size: 18px;
  //background: #03cc90;
  padding: 10px;
  white-space: pre-line;
}

.register-icon {
  cursor: pointer;
}

.register-icon {
  position: absolute;
  right: 0;
  top: 12px;
}
.example-full-width .register-icon {
  top: unset;
}

.school-mail {
  margin: 10px 0 20px 0;
}

.icon-warn {
  color: $color-primary-red;
}

.chart-container {
  //height: 100%;
  height: 200px;
  width: auto;

  @media screen and (min-width: 1240px) {
    max-width: 1000px;
    height: 200px;
  }
}

.pdfPage {
  max-width: 260px;
  margin-right: 15px;
  top: 10px;

  .mat-mdc-form-field-infix {
    padding-bottom: 5px !important;
  }
}

.notes_description {
  max-width: 360px;
  width: 100%;
}

.bookview_footer_left {
  display: flex;
  flex-direction: row;

  .book_reader_settings {
    position: relative;
    left: 60px;
  }
}

.pdfPrintLayout {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.group-overview-header {
  text-align: left;
  padding: 5px 0px;
}

.grade-select {
  margin-top: 30px;
}

.book-list-select {
  margin-top: 30px;
}

.book-list-select-multiple {
  margin-top: 30px;
  // width: 377px;
  // min-width: unset !important;
  // max-width: unset !important;
  // position: relative;
  // left: 19px;
}

audio::-webkit-media-controls-volume-control-container,
audio::-webkit-media-controls-toggle-closed-captions-button,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-volume-slider-container,
audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

.book_list_empty {
  min-height: 400px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.group-value_tooltip {
  display: flex;
  align-items: center;
  margin-left: 33px;

  .mat-icon-button {
    margin-left: 10px;
  }

  mat-icon.mat-icon.icon-border.group-question {
    color: #03cc90;
    height: 25px !important;
    width: 25px !important;
  }
}

.agreement_box {
  margin-bottom: 15px;

  .mdc-checkbox {
    white-space: unset !important;
    margin-bottom: 10px !important;
  }
  .mat-mdc-checkbox-touch-target {
    margin-top: 3px;
  }
  .mat-mdc-checkbox label {
    text-align: left;
    margin-left: 10px;
  }
}

p.paragraph.text_error {
  color: $color-warn;
}

.delete_task {
  color: $mat-warn-color !important;

  .mat-icon {
    top: 2px !important;
  }
}

.delete_group {
  color: $mat-warn-color !important;
}

footer {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  position: relative;

  .footer_wrap {
    max-width: $layout-max-width;
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: 767px) {
      z-index: 100;
      margin-left: -20px;
    }
  }

  .footer-nav {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;

    li {
      padding: 10px;

      a {
        text-decoration: none;
        font-size: 14px;
        line-height: 19px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.route--home,
.route--family {
  footer {
    background: #1a1a1a !important;

    .footer-nav {
      margin-block-start: initial;
      margin-block-end: initial;

      li {
        padding: 26px 16px;

        a {
          color: $color-b-w-0;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
  .is-app {
    .mat-toolbar,
    footer {
      display: none;
    }
  }
}

.button-info-wrap {
  display: flex;
  flex-direction: column;

  .loading-info {
    margin-top: 10px;

    .paragraph {
      text-align: center;
      color: $color-warn;
    }
  }
}

.hidden_result_button mat-icon.mat-icon {
  margin-top: 8px;
}

// Ipad

.touch_detected {
  .dashboard_result_student.dashboard_result_student--medals {
    .set_inline {
      flex-direction: column;
    }

    @media screen and (min-width: 1024px) {
      .paragraph.paragraph--l {
        font-size: 18px;
      }
    }

    .medals-wrap {
      left: 0;
      margin-bottom: 10px;
    }
  }

  .record_buttons_wrap {
    gap: 10px;
  }
}

.home_header {
  background-image: url('./assets/header_total_1920px_pattern.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: -50% !important;
  margin-right: -50% !important;
  padding-left: 50%;
  padding-right: 50%;
  background-position: center;
  padding-top: 130px;

  @media screen and (min-width: 1240px) {
    background-size: contain;
  }

  & > div {
    max-width: $layout-max-width;
    margin: 0 auto;
  }
}

section.home_register_section.dashboard_register_section {
  position: absolute;
  width: 100%;
  background-color: $color-b-w-0;
  margin: 0 auto;
  font-family: 'POLOGSV-BUCH' !important;
  // Prevent responsiveness for home
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999;

  .row {
    max-width: $layout-max-width;
    margin: 0 auto;
    align-items: center;
  }
}

section.section-laletu-helpdesk {
  width: 100%;
  background-color: #fff;
  margin: 0 auto;
  font-family: 'POLOGSV-BUCH' !important;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 999;
  max-width: 1170px;
  margin: 138px auto 0;
  align-items: center;

  .headline_box {
    margin-left: 95px;
    margin-bottom: 45px;
  }
}

.register-section {
  display: none;
  font-family: 'POLOGSV-BUCH' !important;

  .next_home_icon {
    transition: 0.3s all;
  }

  &--active {
    display: block;
    opacity: 0.97;

    .next_home_icon {
      transform: rotate(90deg);
    }
  }
  .mat-icon {
    margin-top: 4px;
  }
}

.home_container {
  padding-top: 0;
  overflow: hidden;

  .home_header_content {
    width: 100%;
    padding-left: 100px;

    .headline,
    .subheadline,
    .paragraph {
      color: $color-b-w-0;
    }
  }

  .headline {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    text-transform: uppercase;
    margin-top: 0;
  }

  .subheadline {
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
  }

  .paragraph {
    font-size: 20px;
    line-height: 27.5px;
  }

  .home_video_wrap {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    .video-container {
      width: 100%;
      max-width: 980px;
      margin: 0 auto;
      padding: 30px;
      background: rgba(178, 178, 178, 0.1);

      video {
        width: 100%;
        height: auto;
        max-width: 920px;
      }
    }
  }

  .speech_bubble_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-end;
  }

  .speech_bubble {
    display: flex;
    flex: 0 0 calc(50% - 30px);

    &:nth-child(4) {
      margin-right: -45px;
    }

    &:nth-child(6) {
      margin-right: -85px;
    }

    &--container {
      margin: 30px 15px;
      position: relative;
      background-color: $color-b-w-0;
      padding: 30px 20px;
      font-size: 20px;
      border-radius: 1rem;
      border: 2px solid #066089;
      max-width: 280px;
      display: flex;
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: -11px;
        left: 30px;
        border: 0.75rem solid transparent;
        border-top: none;
        transform: rotate(180.1deg);
        border-bottom-color: $color-b-w-0;
        filter: drop-shadow(0 -3px 0px #066089);
      }
    }
  }

  .speech_bubble_bg {
    width: 100%;
    height: 255px;
    background: #116089;
    position: absolute;
    transform: rotate(350deg);
    left: 0;
    top: 210px;

    p {
      position: absolute;
      left: 33%;
      color: $color-b-w-0;
      bottom: 30px;
      width: 315px;
      font-size: 14px;
      line-height: 19.25px;
    }
  }

  .speech_bubble_bear {
    position: absolute;
    display: block;
    top: -311px;
    z-index: 1;
    max-width: 325px;
    left: 0;
    margin-left: calc(50% + 550px);
    transform: rotate(10deg);
  }

  .speech_bubble_wrap {
    margin-top: -20px;

    &:before {
      content: '';
      background: $color-b-w-0;
      width: 94%;
      height: 82%;
      display: block;
      position: absolute;
      transform: rotate(350deg);
      z-index: 0;
      bottom: 0;
      left: 15%;
    }
  }

  .speech_bubble:nth-child(2),
  .speech_bubble:nth-child(4),
  .speech_bubble:nth-child(6) {
    position: relative;
    top: -35px;
  }

  .headline_box {
    color: #ff6600;
    background-color: $color-b-w-0;
    border-bottom: 3px solid #eee;
    border-right: 3px solid #eee;
    padding: 8px 16px 8px 8px;
    margin: 0 0 2px 0;
    width: fit-content;
    font-size: 60px;
    line-height: 60px;
    font-family: 'POLOGSV-BUCH';
    font-weight: 400;
  }

  .section_gray_header {
    position: relative;
    bottom: 115px;
    left: 95px;
  }

  .section_gray_bg {
    background-color: rgb(170 176 177 / 10%);
    margin: 190px auto 260px auto;
    max-width: 1140px;
    font-family: 'POLOGSV-BUCH' !important;

    .section_headline {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 12px;
      font-weight: 400;
      color: #066089;
    }

    .section_text {
      font-size: 20px;
      line-height: 27.5px;
      color: #707070;
      font-weight: 400;
    }

    .row {
      padding: 48px 96px;
    }

    .section_divider {
      height: 2px;
      background-color: #e3e5e5;
      width: 100%;
    }

    .center_content {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .align_content {
      align-items: center;
    }

    .illu-feedback {
      width: 410px;
    }

    .illu-strength {
      width: 280px;
    }

    .illu-vailidity {
      width: 310px;
    }

    .illu-security {
      width: 280px;
    }

    .illu-success {
      width: 380px;
    }
  }

  .section_content {
    max-width: 980px;
    margin: 0 auto;

    p {
      font-size: 20px;
      line-height: 27.5px;
      color: #707070;
    }

    .section_content_header {
      margin-bottom: 50px;
    }
  }

  .home_register_section {
    max-width: 1140px;
    margin: 0 auto;
    font-family: 'POLOGSV-BUCH' !important;

    .row {
      align-items: center;
    }

    .home_buttons {
      margin-bottom: 0;
    }
  }

  .section_blue_bg {
    background-image: url('./assets/Rechteck_blue.svg');
    background-repeat: repeat-x;
    background-size: contain;
    background-position: top;
    margin-left: -50% !important;
    margin-right: -50% !important;
    padding-left: 50%;
    padding-right: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    background-color: $color-b-w-0;
    padding-bottom: 200px;
    &_wrap {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
    }

    .section_blue_header {
      position: relative;
      margin-top: 75px;
      margin-inline: auto;
    }

    .number_container {
      display: flex;
      justify-content: center;
      max-width: 980px;
      margin: 70px auto;
      height: 100%;
    }

    .number_wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex: 1;

      p {
        color: $color-b-w-0;
        font-size: 20px;
        line-height: 27.5px;
        width: 150%;
      }

      img {
        max-width: 64px;
        width: 100%;
      }
    }

    .number_bottom {
      position: relative;
      justify-content: flex-end;
      margin-bottom: 40%;

      img {
        margin-top: 11px;
      }
    }

    .number_top {
      position: relative;
      justify-content: flex-start;
      margin-top: calc(26% + 50px);

      img {
        margin-bottom: 11px;
      }
    }

    img.blue_section_img {
      max-width: 503px;
      width: 100%;
      position: absolute;
      top: -183px;
      left: 0;
      margin-left: calc(50% + 30px);
    }
  }

  .section_gray_img {
    background-image: url('./assets/Rechteck_grey.svg');
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -50% !important;
    margin-right: -50% !important;
    padding-left: 50%;
    padding-right: 50%;
    background-color: $color-b-w-0;
    padding-top: 128px;
    padding-bottom: 100px;
    font-family: 'POLOGSV-BUCH' !important;

    .row {
      max-width: 1020px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 48px;
    }

    .section_gray_img_headline {
      margin-bottom: 80px;
    }

    .section_headline {
      font-size: 28px;
      line-height: 35px;
      margin-bottom: 12px;
      font-weight: 400;
      color: #066089;
      margin-top: 0;
    }

    .section_text {
      font-size: 20px;
      line-height: 27.5px;
      color: #707070;
    }

    img {
      max-width: 478px;
      width: 100%;
    }
  }

  .home_register_headline {
    margin-left: 75px;
    margin-bottom: 48px;
  }

  .speech_bubble_section {
    //background-image: url("./assets/Rechteck_blue_small_left.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-left: -50% !important;
    margin-right: -50% !important;
    padding-left: 50%;
    padding-right: 50%;
    background-color: $color-b-w-0;
    position: relative;
    margin-top: 170px;

    .row {
      max-width: 980px;
      margin: 0 auto;
    }

    &_wrap {
      max-width: 1440px;
      width: 100%;
      margin-inline: auto;
    }
  }
}

.home_box_teacher {
  background-color: $color-b-w-0;
  width: 100%;
  float: right;
  height: 100%;
  text-align: left;

  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    color: #ff6600;
    text-align: left;
    font-weight: 400;
  }

  .paragraph {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    color: #707070;
    text-align: left;
  }

  .home_buttons {
    display: flex;

    .mat-unelevated-button {
      max-width: 226px;
      width: 100%;
    }
  }
}

.home_box_student {
  width: 100%;
  float: left;
  text-align: left;
  display: flex;
  flex-direction: row;
  height: 280px;

  h3 {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 24px;
    color: #ff6600;
    text-align: left;
  }

  .paragraph {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    color: #707070;
    text-align: left;
  }

  .home_box_student_img {
    align-self: center;
    max-width: 306px;
    width: 100%;
    background: rgb(9, 9, 121);
    background: linear-gradient(
      90deg,
      rgba(9, 9, 121, 0) 50%,
      rgba(170, 176, 177, 0.1) 50%,
      rgba(170, 176, 177, 0.1) 100%
    );
  }

  .home_box_student_content {
    background-color: rgba(170, 176, 177, 0.1);
    padding: 32px;

    .home_buttons {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.home-primary {
  background: unset !important;
  color: #ff6600 !important;
  padding: 0 !important;
  margin-right: 16px !important;
}

.home-secondary {
  background: unset !important;
  color: #066089 !important;
  padding: 0 !important;

  .mat-icon {
    font-size: 1.5em !important;
  }
}

.home-primary_student {
  background-color: #ff6600 !important;
  border-radius: 0px !important;
  padding: 10px 12px !important;
  color: #fff !important;

  .mat-icon {
    font-size: 1.5em !important;
  }
}

.home-primary_inverted {
  color: #ff6600 !important;
  background-color: #fff !important;
  border: 1px solid #ff6600 !important;
  border-radius: 0px !important;
  width: auto;
  padding-inline: 1em;

  .full {
    width: 100%;
  }
}

.home-primary_orange {
  background-color: #ff6600;
  color: #fff;
  padding: 1em;
  border-radius: 0px !important;
}

body.route {
  &--home,
  &--family {
    background: $color-b-w-0;
    font-family: 'PoloCEF-Light' !important;
    color: #707070;

    strong {
      font-family: 'POLOGSV-BUCH' !important;
    }

    .mat-toolbar-multiple-rows {
      border-bottom: none;
      position: sticky;
      width: 100%;
      max-width: unset;
      z-index: 9;
      top: 0;

      .mat-toolbar-row {
        max-width: $layout-max-width;
        margin: 0 auto;
      }
    }

    .register-section--active {
      display: block;
      opacity: 0.97;
      position: sticky !important;
      top: 90px;
    }

    // Prevent responsiveness for home
    //min-width: 1610px;
    // .container {
    //     min-width: 1610px;
    // }
  }

  &--create-password,
  &--forgot-password,
  &--login,
  &--schulregistrierung,
  &--registrierung,
  &--non-school-register {
    mat-toolbar.mat-toolbar.mat-toolbar-multiple-rows {
      display: none;
    }
    .step_content {
      overflow-y: auto;
      max-height: 90vh;
    }
  }

  &--task-preview .header-bar {
    display: none;
  }
}
.show_header_register {
  mat-toolbar.mat-toolbar.mat-toolbar-multiple-rows {
    display: flex !important;
  }
}

.hide_header_register {
  mat-toolbar.mat-toolbar.mat-toolbar-multiple-rows {
    display: none !important;
  }
}

section.sponsor_section {
  margin-top: 138px;
  margin-bottom: 150px;

  .row:first-child {
    max-width: 1024px;
    margin: 0 auto;
  }

  .sponsor_register_headline {
    margin-bottom: 48px;
  }

  .sponsor_images {
    margin-top: 48px;
    gap: 48px;
    grid-auto-rows: minmax(100px, auto);
    align-items: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      max-height: 74px;
      width: auto;
    }

    h2 {
      text-align: center;
    }
  }
}

.diagnostic_wrap {
  background-color: #f5f8fb;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: 0 0 15px 0px;
}

.home_warning {
  font-weight: 600;
  color: #ff0000;
  font-family: 'PoloCEF-Bold';
}

.home_register_text {
  margin-bottom: 10px;
}

.teacher_menu_button {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 26px;
  color: $color-b-w-800 !important;

  .menu-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: none;
    margin-left: 1em;

    .admin-chip {
      margin-left: 1em;
    }
    .dropdown-icon {
      width: 12px !important;
      height: 24px !important;
      margin-left: 10px !important;
    }
  }

  .paragraph {
    line-height: unset !important;
  }
}

.teacher_menu {
  transition: 0.3s all;

  .mat-menu-item:last-child {
    border-top: 1px solid #d8dddc;
  }

  .mat-menu-item:hover {
    color: $color-green-700;

    mat-icon.mat-icon {
      color: $color-green-600;
    }
  }
}

.menu-open {
  mat-icon.mat-icon.dropdown-icon {
    transform: rotate(180deg);
  }
}

.license_chip {
  border: 1px solid #03cc90;
  border-radius: 22px;
  padding: 5px 10px;
  display: flex;
  margin-right: 32px;
  align-items: center;

  .license_icon_left {
    width: 19px !important;
    height: 21px !important;
    color: #03cc90;
    margin-right: 9px !important;
    position: relative;
    top: -2px;
  }

  .license_icon_right {
    width: 19px !important;
    height: 21px !important;
    color: #8faabd;
    margin-left: 13px !important;
    position: relative;
    top: -2px;
  }

  p {
    font-size: 14px;
    line-height: 19px;
    font-weight: 700;
    color: #13794f;
  }
}

.collapsed-width {
  @media screen and (max-width: 1200px) {
    width: 90px !important;
    position: absolute;
    padding-inline: 0;
  }
}

.visible-tablet-or-smaller {
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.visible-only-desktop {
  @media screen and (max-width: 1199px) {
    display: none !important;
  }
}

.visible-tablet-or-larger {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.visible-mobile {
  padding-top: 2em;

  @media screen and (min-width: 768px) {
    display: none;
  }
}

.teacher_sidebar {
  background-color: $color-b-w-0;
  padding: 32px 24px;
  display: flex;
  flex-direction: column;
  width: 260px;
  box-shadow: rgba(33, 35, 38, 0.1) 10px 0px 10px -2px;
  transition: 0.2s ease width;
  z-index: 100;
  height: calc(100vh - 100px);
  overflow-y: auto;

  &.collapsed-width {
    padding-left: 0.5em !important;
    margin-right: 0.5em;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    width: 360px;
  }

  @media screen and (max-width: 1199px) {
    position: absolute;
    z-index: 10;

    &.margin-bottom {
      height: calc(85vh);
    }
  }

  .mat-mdc-unelevated-button:not(:disabled) {
    padding-left: 1.25em;
  }

  &--flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .teacher_sidebar_primary {
    background-color: #13794f;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    padding: 12px 17px !important;
    margin-bottom: 32px;
    display: flex;

    mat-icon {
      width: 22px !important;
      height: 22px !important;
      margin-right: 13px !important;
    }
  }

  .teacher_sidebar_unset {
    color: #281a27;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5em;

    &.top-button .btn-text {
      padding-left: 1em;
    }

    .mat-icon {
      margin: 0;

      @media screen and (min-width: 1200px) {
        width: 20px !important;
        height: 20px !important;
        margin-right: 4px !important;
      }
    }

    &-flex.top-button,
    &.top-button {
      @media screen and (max-width: 1199px) {
        padding: 2em 1.4em !important;
        margin-bottom: 0.75em;
        justify-content: flex-start;
        max-width: 205px;
        width: 100%;
      }
    }
  }

  .wrap-bottom {
    display: flex;
    justify-content: flex-start;

    .mat-icon {
      margin-right: 0.5em;
      margin-left: -10px;
    }

    @media screen and (max-height: 751px) {
      background-color: white;
    }

    @media screen and (max-width: 1199px) {
      justify-content: space-around;

      &-flex {
        margin: 1em;
        width: 100%;

        button {
          margin-top: 2em;

          mat-icon {
            margin: 1em unset;
          }
        }
      }

      &-block {
        display: block;
        margin-bottom: -2em;
        background-color: white;
      }
    }
  }

  @media screen and (max-width: 564px) {
    width: 100%;
  }
}

.active-sidebar_button.mat-mdc-unelevated-button:not(:disabled) {
  background-color: $button-color-lighter;
  color: $button-color-darker;
  width: 100%;
  justify-content: flex-start;
}

.teacher-mobile-menu {
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    position: sticky;
    bottom: 0;
    background-color: white;
    z-index: 11;
    height: 100px;
    padding-block: 1em;
    padding-inline: 0.5em;
    box-shadow: rgba(33, 35, 38, 0.3) 0px 0px 10px 0px;
  }
}

.create-task-btn-wrapper {
  position: absolute;
  bottom: 115px;
  right: 15px;
  z-index: 11;

  button.mdc-fab.mat-mdc-fab.mat-accent {
    width: 65px;
    height: 65px;
  }
  .round-buttons {
    position: absolute;
    bottom: 70px;
    right: 6px;
    z-index: -1;

    .round-button-wrapper {
      margin-bottom: 0.75em;

      button.animate-task,
      button.animate-diagnose {
        width: 48px;
        height: 48px;
      }

      button.animate-diagnose {
        animation: slideIn 0.4s;
        transition-timing-function: ease-in-out;
        background: white;
      }

      p.animate-diagnose {
        animation: fadeIn 0.4s;
      }

      button.animate-task {
        animation: slideIn 0.4s;
        transition-timing-function: ease-in-out;
        background: white;
      }

      p.animate-task {
        animation: fadeIn 0.2s;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      @keyframes slideIn {
        0% {
          bottom: -70px;
        }

        100% {
          bottom: 0;
        }
      }
    }
  }
}

.round-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 260px;
  gap: 1em;

  p {
    font-weight: 800;
    animation: fadeIn 1s;
  }

  .mat-fab {
    color: white;
    background-color: #13794f;
    height: 60px;
    width: 60px;
  }
}

.mat-menu-content .mat-unelevated-button.mat-unset {
  color: #232323;
  font-weight: 400;
}

.teacher-mobile-menu-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .mdc-button.mdc-button--unelevated.mat-unset {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #232323;
    font-weight: 400;
    height: 70px;
    padding-inline: 1em;

    mat-icon.mat-icon {
      margin-right: 0 !important;
    }
  }

  .active-sidebar_button {
    background-color: #e6f8f2;
    color: #0e583a !important;
  }
}
.dropdown-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  padding-right: 0.5em;

  .mdc-button.mdc-button--unelevated.mat-unset {
    padding-inline: 1em;
  }
}

.handle-sidebar-button {
  &.mat-mdc-unelevated-button:not(:disabled) {
    position: absolute;
    bottom: 0;
    right: 0;
    background: $color-slate-50;
    border-radius: 0;
    border-top-left-radius: 2.5rem;
    color: $color-slate-500;
    width: 24px;
    padding-right: 5px;
    height: 56px !important;
  }
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}

.inverted-arrow {
  transform: rotate(-180deg);
}

.sidebar_menu {
  width: 100%;
  min-width: 220px !important;

  .mat-menu-item:hover {
    color: #db1919;

    mat-icon.mat-icon {
      color: #db1919;
    }
  }
}

.trial_box {
  padding: 12px;
  background: linear-gradient(
    89.79deg,
    #03cc90 -104.26%,
    $color-blue-400 15.84%,
    #fb9cb3 81.81%,
    #faaf05 149.99%
  );
  box-shadow: 0px 2.55673px 13.4228px rgba(54, 62, 90, 0.07);
  border-radius: 7.63043px;
  margin-bottom: 32px;

  .h2 {
    color: #f4f4f4;
    font-weight: 800;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 8px;
  }

  .paragraph {
    color: #f4f4f4;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 16px;
  }

  .trial_box_button {
    background-color: #f4f4f4;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $color-blue-600;
    padding: 10px 15px !important;
    border-radius: 10px;
    width: 100%;
  }
}

.icon_student {
  width: 16px !important;
  height: 16px !important;
  color: $color-b-w-900 !important;
}

.icon_plus {
  width: 24px !important;
  height: 24px !important;
  color: #13794f !important;
  position: relative;
  bottom: 3px;
}

.group_result_chart.position_left {
  h3 {
    margin-bottom: 20px;
  }
}

.class_chart_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

// chart cutom toggle
.chart_toggle {
  display: flex;
  margin-bottom: 15px;

  .paragraph {
    margin-left: 8px;
  }

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 48px;
    height: 24px;
    background: transparent;
    border: 1px solid #8faabd;
    display: block;
    border-radius: 24px;
    position: relative;
  }

  label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 5px;
    max-width: 16px;
    max-height: 16px;
    width: 100%;
    height: 100%;
    background: #8faabd;
    border-radius: 90px;
    transition: 0.5s;
  }

  input:checked + label {
    background: $button-color;
    border: 1px solid $button-color;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
    background: $color-b-w-0;
    transition: 0.5s;
  }

  label:active:after {
    width: 130px;
  }
}

.chart_legend {
  display: flex;
  justify-content: center;

  .chart_legend_diagnostic {
    margin-right: 24px;
  }

  .chart_legend_diagnostic:before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 22px;
    display: inline-block;
    margin-right: 8px;
    background-color: #03cc90;
  }

  .chart_legend_training:before {
    content: '';
    width: 16px;
    height: 2px;
    display: inline-block;
    margin-right: 8px;
    background-color: #03cc90;
    position: relative;
    bottom: 3px;
  }
}

.chart_container {
  display: flex;
  max-width: 1000px;
  height: 200px;
  width: 100%;

  // .line-chart.chart {
  //     display: none;
  // }

  .line-chart.chart.line_chart_active {
    display: block;
  }

  .ngx-charts-outer {
    display: block;
  }

  ul.legend-labels.horizontal-legend {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row-reverse;
  }

  span.legend-label-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: $color-b-w-800;
  }

  li.legend-label:first-child {
    span.legend-label-color {
      width: 16px;
      height: 2px;
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  li.legend-label:nth-child(2) {
    span.legend-label-color {
      width: 10px;
      height: 10px;
      border-radius: 22px;
    }

    span {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
}

.student_note {
  background: #f6f8fbcc;
  height: 100%;
  border-radius: 0 12px 12px 0;
  max-height: 388px;
  overflow: auto;

  .student_note_header {
    display: flex;
    align-items: center;
    padding: 20px;

    span {
      color: #8faabd;
      margin-left: 4px;
      margin-right: 8px;
    }
  }

  .student_note_content_wrap {
    height: calc(100% - 80px);
    padding: 16px 20px;

    .student_note_content_empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      mat-icon {
        width: 45px !important;
        height: 45px !important;
        color: $color-blue-600 !important;
        margin-bottom: 12px !important;
      }

      .paragraph.paragraph--s {
        color: #5b7385;
      }
    }

    .student_note_content {
      background-color: $color-b-w-0;
      padding: 12px;
      margin-bottom: 8px;
      border-radius: 12px;

      .student_note_content_footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .paragraph.paragraph--s {
          color: #5b7385;
        }
      }

      .student_note_button {
        padding: 10px 0 10px 10px !important;

        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        .mat-button-wrapper {
          display: flex !important;
          flex-direction: column !important;
        }

        .note_dot {
          height: 4px;
          width: 4px;
          background-color: #8faabd;
          margin-bottom: 2.2px;
          border-radius: 50%;
        }
      }
    }
  }
}

.student_info_row {
  padding: 0 32px 0 0 !important;
  margin-bottom: 16px;
}

.task_chips_wrap {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;

  mat-icon {
    color: $button-color;
    width: 24px !important;
    height: 24px !important;
  }

  .task_chip:first-child {
    padding-left: 0;
  }

  &--blue.training_task_details {
    gap: 0.75em;
  }
}

.teacher_task_info {
  .inline_text {
    align-items: flex-start;
  }

  .task_completed_date {
    color: #5b7385;
  }

  .paragraph {
    &:first-child {
      min-width: 150px;
      margin-bottom: 5px;
    }

    &--s {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }

    &--bold {
      font-weight: 700;
      font-size: 14px;
      line-height: 19px;
    }
  }
}

.dashboard_tasks_selects_wrap {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  margin-bottom: 40px;

  .select-label {
    width: 100%;
  }
}

.teacher_tabs_header {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .teacher_tabs_header_select {
    width: 100%;
  }
}

.teacher_tabs_charts {
  margin-top: 35px;
}

.teacher_tabs_header_buttons {
  mat-button-toggle-group {
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .mat-button-toggle-checked {
    .mat-button-toggle-button {
      background: $button-color;
      border-radius: 10px;
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      padding: 7.5px 12px !important;
      color: $color-b-w-0;
    }
  }

  .mat-button-toggle-button {
    background: $color-b-w-0;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    padding: 7.5px 12px !important;
    color: $color-b-w-900;
  }

  mat-button-toggle-group {
    border: unset !important;
    gap: 8px;
    height: 42px;
    @media screen and (max-width: 929px) {
      margin-block: 1em;
    }
  }

  span.mat-button-toggle-label-content {
    line-height: 0 !important;
  }

  .mat-button-toggle {
    border: unset !important;
    background: transparent !important;
  }
}

.button_add_class {
  line-height: 20px;
  text-align: left;
  white-space: normal;

  @media screen and (max-width: 1199px) {
    font-size: 16px;
  }
}

.continuous-task-wrap {
  display: flex;

  .paragraph:first-child {
    min-width: initial;
  }

  .progress-bar-wrap {
    width: 150px;
    margin-left: 12px;

    .progress-bar-container {
      height: 3px;
      margin: 8px 0px 8px 0;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
.mat-mdc-select-panel {
  flex-basis: 100%;
  width: 100%;
  min-width: 200px !important;
  position: relative;
  top: 37px;

  .mat-option.mat-active {
    background: inherit;
  }
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background: #13794f !important;
  border-radius: 2px 2px 0px 0px !important;
}

app-diagnostic-progress {
  width: 100%;
  padding: 0 16px;
}

.teacher_tasks_info {
  min-height: 30px;
}

.student_chart_header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .mat-mdc-form-field-flex {
    padding-top: 0 !important;
  }
}

.student_group_name {
  text-transform: uppercase;
}

.student_user_name {
  text-transform: capitalize;
}

.task_table_view {
  th.mat-sort-header.mat-header-cell.cdk-header-cell.cdk-column-deadline.mat-column-deadline {
    max-width: 100px;
  }

  th.mat-sort-header.mat-header-cell.cdk-header-cell.mat-column-align--center.cdk-column-description.mat-column-description {
    max-width: 142px;
  }
}

.table_chip {
  padding: 4.5px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: fit-content;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: $color-blue-600 !important;
  border-radius: 24px;

  &--diagnostic-pre,
  &--diagnostic-mid,
  &--diagnostic-post {
    background: #e5f0ff;
    color: $color-blue-600 !important;
  }

  &--text,
  &--time,
  &--time-choice,
  &--chip_text,
  &--autonomous-placement {
    background: #d7f7f0;
    color: #13794f !important;
  }

  &--reading-loud {
    background: $color-blue-50;
    color: #5f798c !important;
  }

  &--hasicon {
    padding-right: 6px;
    text-wrap: nowrap;

    .mat-icon {
      margin-left: 4px;
    }
  }
}

.task_chip {
  padding: 3px 15px 3px 9px !important;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  height: max-content;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  //color: $color-blue-600 !important;
  border-radius: 24px;
  gap: 0.5em;

  &--diagnostic-pre,
  &--diagnostic-mid,
  &--diagnostic-post {
    background-color: $button-color-lighter;
    color: $button-color;
  }

  &--text {
    background-color: $button-color-lighter;
    color: $button-color;
    font-weight: 600;
    text-align: left;
    width: fit-content;
    height: 30px;

    &.narrow-chip {
      text-align: center !important;
    }
  }

  &--overview {
    font-weight: 600;
    text-align: left;
    width: fit-content;
    height: 30px;
    background-color: $color-slate-100;
    color: black;
    mat-icon {
      color: $color-blue-600;
    }
    &.narrow-chip {
      text-align: center !important;
    }
  }
  &--time,
  &--time-choice,
  &--chip_text {
    background: #d7f7f0;
    color: #13794f !important;
  }

  &--reading-loud {
    background: $color-blue-50;
    color: #5f798c !important;
  }

  .innerchip--auto {
    font-weight: 800;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase;
    background: $color-gradient-06;
    color: $color-b-w-0 !important;
    padding: 6px;
    border-radius: 25px;
    margin-left: 6px;
  }
}

// table
th.mat-sort-header.mat-column-type {
  min-width: 135px;
}

th.mat-sort-header.mat-column-created {
  max-width: 100px;
}

th.mat-sort-header.mat-column-deadline {
  max-width: 100px;
}

.teacher-transfer-backdrop,
.reading-level-overlay-panel_backdrop,
.paswords-warn-panel-backdrop,
.archivments-panel-backdrop,
.create-training-task-panel-backdrop,
.video-overlay-panel-backdrop,
.session-upload-overlay-panel_backdrop,
.schools-only-overlay-panel_backdrop,
.bg-group-delete-overlay {
  background-color: rgba(0, 0, 0, 0.4);
}
.full-page-panel {
  width: 100%;
  max-width: 1440px !important;
  height: 100%;

  .mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 0;
    overflow-y: auto;
  }
}

.books-overlay-panel_backdrop,
.book-select-panel-backdrop {
  opacity: 0 !important;
}

.books-overlay-panel,
.book-select-panel {
  height: 100vh;
  width: 100%;
  max-width: $layout-max-width !important;

  .book_overlay {
    padding: 0 !important;
    background: transparent;
  }

  .mat-mdc-dialog-container {
    background: transparent;
    padding: 0;
  }

  .book-list-col {
    background: $color-b-w-0;
    height: 100%;
    min-height: 100vh;
    padding: 60px 40px;
  }
  @media screen and (max-width: 767px) {
    .col-md-12 {
      padding-left: 24px !important;
    }
  }
}

.create-training-task-panel {
  height: 100vh;
  width: 100%;
  max-width: $layout-max-width !important;
  position: relative;

  .col-xl-7,
  .col-md-10,
  .col-lg-10 {
    padding-inline: 0 !important;
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
    background-color: transparent;
    border-radius: 0;
    overflow: hidden;
  }

  .mat-mdc-form-field {
    width: 99%;
  }

  .training-task_select_container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 2em;
    padding-right: 3em;
    padding-bottom: 120px;

    &.diagnostic {
      height: 80vh;
      padding-bottom: 20px;
    }

    &.with-table {
      height: calc(100vh - 140px);
      padding-bottom: 150px;
    }

    .mat-mdc-table {
      box-shadow: none;
      font-size: 12px;
    }

    &.full-height {
      height: unset;
    }

    @media screen and (max-width: 767px) {
      font-size: 0.95em;
      //height:70vh;
      overflow: auto;
      padding-right: 2em;
    }
  }

  .mat-mdc-dialog-container {
    background: transparent;
    padding: 0;
    overflow-x: hidden;
    border-radius: 0;
  }

  .training-task-stepper-col {
    background: $color-b-w-0;
    height: 100%;
    min-height: 100vh;
    padding-left: 0;
  }

  .training-task_wrap {
    position: relative;

    form {
      max-width: unset;
      margin-top: 0;
      display: flex;
      flex-direction: column;
    }
    @media screen and (max-width: 767px) {
      height: 100vh;
    }
  }
  .duration-form-field {
    .mat-mdc-text-field-wrapper {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.admin-management-headline {
  text-align: center;
  margin-block: 2em;
  padding-top: 1em;
}

.dropdown-select
  mat-pseudo-checkbox.mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal {
  display: none !important;
}

.group_headline {
  display: flex;
  margin-bottom: 24px;

  .mat-icon {
    width: 28px !important;
    height: 28px !important;
    padding: 4px;
    background: $color-b-w-100;
    border-radius: 50%;
    color: $color-slate-600;
    margin-right: 12px;
  }
}

.training-task_create_headline {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;

  @media screen and (max-width: 767px) {
    padding-top: 2em;
  }

  @media screen and (max-height: 564px) {
    padding-top: 1em;
    margin-bottom: 1em;
  }

  &.no-gap {
    gap: 1em;
  }

  .task_chip {
    width: fit-content;
    margin-bottom: 16px;
  }

  .h3 {
    margin-bottom: 24px;
  }

  .paragraph.paragraph--xl {
    color: $color-slate-600;
  }
}

.training-task_create_spacing {
  margin-top: 32px;
  text-align: center;
}

.training-task_select_container {
  .training-task_button_wrap {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 36.5px;
    flex-direction: row;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
      gap: 1em;
    }

    .paragraph.paragraph--xl {
      color: $color-b-w-800;
    }

    .h3 {
      margin-bottom: 12px;
    }

    > mat-icon {
      min-width: 60px !important;
      min-height: 60px !important;
      //color: $color-blue-400;
      margin-bottom: auto;

      @media screen and (max-width: 767px) {
        min-width: 50px !important;
        min-height: 50px !important;
      }
    }

    .training-task_selector_content {
      mat-icon {
        overflow: visible;
      }
    }
  }

  .training-task_selector_wrap {
    overflow-y: scroll;
    height: 100%;
    display: block;

    &.full-width {
      max-width: 100%;
    }

    @media screen and (max-width: 767px) {
      height: 55vh;
      padding-right: 1em;
    }

    button.training-task_selector {
      border: none;
      padding: 25px;
      background-color: $color-b-w-10;
      border-radius: 12px;
      margin-bottom: 24px;
      width: 100%;
      cursor: pointer;
      max-width: 100%;
      font-family: 'Manrope', sans-serif;

      @media screen and (max-width: 767px) {
        margin-block: 0.5em;
        padding: 1em;

        h3 {
          font-size: 1.85em;
        }
        p {
          font-size: 1.35em;
        }
      }
    }
  }

  button.training-task_selector.active,
  button.training-task_selector:hover {
    background-color: #f0f7fe;
    border: 1px solid #3e61df;

    .h3 {
      color: $color-blue-600;
    }

    .paragraph.paragraph--l {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: $color-b-w-800;
    }

    .paragraph.paragraph--xl {
      color: $color-slate-600;
    }
  }

  .training-task_selector:disabled {
    background: $color-b-w-0 !important;
    border: 2px dashed $color-slate-400;

    .h3 {
      color: $color-slate-400 !important;
    }

    .paragraph.paragraph--l,
    .paragraph.paragraph--xl {
      color: $color-slate-400 !important;
    }
  }

  .training-task_selector_chip {
    color: $color-b-w-0;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    background: linear-gradient(
      89.79deg,
      #03cc90 -104.26%,
      $color-blue-400 15.84%,
      #fb9cb3 81.81%,
      #faaf05 149.99%
    );
    border-radius: 24px;
    padding: 9px 10px;
    margin-left: 12px;
    position: relative;
    top: -5px;
  }

  .mat-unelevated-button.mat-primary {
    color: white;
    background: #13794f;
    border-radius: 10px;
    padding: 12px 16px;
  }

  .mat-unelevated-button.mat-unset {
    color: #13794f;
    border-radius: 10px;
    padding: 12px 16px;
    border: 1px solid #13794f;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .training-task_form_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 0.5em;
      max-width: 96vw;
      align-items: flex-start;

      .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 0.25em;
        font-size: 13px;
      }
    }
  }

  .paragraph.paragraph--xl {
    width: 100%;
  }
}

.training-task-form {
  max-width: unset;
}

.training-task-container {
  max-width: 758px;
  margin: auto;
  @media screen and (max-width: 840px) {
    &.first-step {
      padding-inline: 3em;
    }
  }
  @media screen and (max-width: 840px) {
    &.fifth-step {
      padding-inline: 2em;
    }
  }

  h1 {
    font-size: 32px;
  }

  .font-22 {
    font-size: 22px;
    margin-bottom: 0.5em;
  }

  p.paragraph.paragraph--xl.paragraph--bold,
  h3 {
    color: $color-b-w-900 !important;
  }
  .training-task_selector {
    &.flex-wrap {
      flex-wrap: wrap;
      display: flex;
      gap: 1em;
      margin-top: 1em;

      button.training-task_selector {
        max-width: 48%;
        border: 1px solid transparent;
      }
    }
  }
  .training-task_button_wrap {
    display: flex;
    align-items: center;
    text-align: left;
    gap: 36.5px;
    flex-direction: row;

    .paragraph.paragraph--xl {
      color: $color-b-w-800;
    }

    > mat-icon {
      min-width: 55px !important;
      min-height: 55px !important;
      margin-bottom: auto;

      &.icon-42 {
        min-width: 42px !important;
        min-height: 42px !important;
      }

      @media screen and (max-width: 767px) {
        min-width: 50px !important;
        min-height: 50px !important;
      }
    }

    .training-task_selector_content {
      font-family: 'Manrope', sans-serif;

      mat-icon {
        overflow: visible;
      }
    }
    @media screen and (max-width: 767px) {
      align-items: flex-start;
      gap: 1em;
    }
    &.small-btn {
      display: block;
      position: relative;
    }
    .admin-chip {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.25em;
    }
  }

  button.training-task_selector {
    border: none;
    padding-block: 1.5em;
    background-color: $color-b-w-10;
    border-radius: 16px;
    margin-bottom: 24px;
    width: 98%;
    cursor: pointer;
    max-width: 100%;
    padding-inline: 2em;

    &.small-btn {
      min-height: 180px;
      height: 100%;
      margin-bottom: 0;
      &.active,
      &:hover,
      &:focus {
        fill: $color-blue-600 !important;
        color: $color-blue-600 !important;
      }

      &.automode-btn {
        @media screen and (max-width: 840px) {
          width: 98%;
          max-width: unset;
        }
      }
    }

    .training-task_selector_content p.paragraph.paragraph--xl.paragraph--bold {
      margin-bottom: 0.5em;
      font-size: 18px;
    }

    &.active,
    &:hover,
    &:focus {
      background-color: #f0f7fe;
      border: 1px solid #3e61df !important;
      .training-task_selector_content
        p.paragraph.paragraph--xl.paragraph--bold {
        color: $color-blue-600 !important;
        font-family: 'Manrope', sans-serif;
      }
    }
    &:disabled {
      &.active,
      &:hover,
      &:focus {
        background-color: $color-b-w-10;
        border: unset;
        .training-task_selector_content
          p.paragraph.paragraph--xl.paragraph--bold {
          color: $color-b-w-800 !important;
          font-family: 'Manrope', sans-serif;
        }
      }
      .mat-icon {
        opacity: 0.2;
      }
    }

    .paragraph.paragraph--l {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: $color-b-w-800;
    }

    .paragraph.paragraph--xl {
      color: $color-slate-600;
    }
    @media screen and (max-width: 767px) {
      margin-block: 0.5em;
      padding: 1em;

      h3 {
        font-size: 1.85em;
      }
      p {
        font-size: 1.35em;
      }
    }
  }

  /*   .training-task_selector:disabled {
    background: $color-b-w-0 !important;
    border: 2px dashed $color-slate-400;

    .h3 {
      color: $color-slate-400 !important;
    }

    .paragraph.paragraph--l,
    .paragraph.paragraph--xl {
      color: $color-slate-400 !important;
    }
  }

  .training-task_selector_chip {
    color: $color-b-w-0;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    background: linear-gradient(
      89.79deg,
      #03cc90 -104.26%,
      $color-blue-400 15.84%,
      #fb9cb3 81.81%,
      #faaf05 149.99%
    );
    border-radius: 24px;
    padding: 9px 10px;
    margin-left: 12px;
    position: relative;
    top: -5px;
  }

  .mat-unelevated-button.mat-primary {
    color: white;
    background: #13794f;
    border-radius: 10px;
    padding: 12px 16px;
  }

  .mat-unelevated-button.mat-unset {
    color: #13794f;
    border-radius: 10px;
    padding: 12px 16px;
    border: 1px solid #13794f;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0;
  }

  .training-task_form_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: 0.5em;
      max-width: 96vw;
      align-items: flex-start;

      .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        padding: 0.25em;
        font-size: 13px;
      }
    }
  }

  .paragraph.paragraph--xl {
    width: 100%;
  } */
}
mat-button-toggle-group {
  width: 100%;
  height: 60px;
  border-radius: 4px 0 0 4px !important;
  border: 0.5px solid #8faabd;
}

mat-button-toggle {
  width: 100%;
  background-color: $color-b-w-0 !important;
  color: $color-b-w-900 !important;
  display: flex;
}

.mat-button-toggle-checked {
  background: $color-blue-100 !important;
  color: $color-blue-600 !important;
  border: 0.5px solid $color-blue-100;
}

.line_height {
  font-size: 13px;

  &__small {
    line-height: 115% !important;

    span {
      line-height: 115% !important;
    }
  }

  &__normal {
    line-height: 160% !important;

    span {
      line-height: 160% !important;
    }
  }

  &__big {
    line-height: 200% !important;

    span {
      line-height: 200% !important;
    }
  }
}

.letter_spacing {
  font-size: 13px;

  span {
    line-height: 18px !important;
  }

  &__small {
    letter-spacing: 0px;

    span {
      letter-spacing: 0px;
    }
  }

  &__normal {
    letter-spacing: 1.5px;

    span {
      letter-spacing: 1.5px;
    }
  }

  &__big {
    letter-spacing: 2px;

    span {
      letter-spacing: 2px;
    }
  }
}

.fontSize {
  &__small {
    font-size: 19px !important;

    span {
      font-size: 19px !important;
    }
  }

  &__normal {
    font-size: 21px !important;

    span {
      font-size: 21px !important;
    }
  }

  &__big {
    font-size: 24px !important;

    span {
      font-size: 24px !important;
    }
  }
}

.fontFamily {
  &__abeezee {
    font-family: 'ABeeZee';
  }

  &__poppins {
    font-family: 'Poppins';
  }

  &.mat-button-toggle {
    min-width: 90px;
  }
}

.hyphenation_mode span {
  line-height: 22px !important;
}

.hyphenation_mode_wrap {
  height: 25px;
  // width: 25px;
  display: flex;
  gap: 12px;

  .hyphenation_mode {
    height: 25px;
    width: 25px;
    border-radius: 50%;

    &__blue {
      background-color: $color-blue-600;
    }

    &__red {
      background-color: $color-red-500;
    }

    &__teal {
      background-color: $color-teal-600;
    }

    &__orange {
      background-color: $color-orange-650;
    }

    &__black {
      background-color: $color-b-w-1000;
    }

    &__gray {
      background-color: $color-slate-550;
    }
  }
}

span.word {
  display: inline-block;
}

.hyphenation_preview {
  .span.hyphen {
    word-break: break-all;
  }

  span.word {
    display: inline-block;
  }

  &__option1 {
    span.hyphen1 {
      color: $color-red-500;
    }

    span.hyphen2 {
      color: $color-blue-600;
    }
  }

  &__option2 {
    span.hyphen1 {
      color: $color-orange-650;
    }

    span.hyphen2 {
      color: $color-teal-600;
    }
  }

  &__option3 {
    span.hyphen1 {
      color: $color-b-w-1000;
    }

    span.hyphen2 {
      color: $color-slate-550;
    }
  }
}

.training-task_select_button {
  display: flex;
  gap: 2em;
  position: absolute;
  bottom: 1em;
  padding: 2em 3em;
  padding-right: 4em;
  border-top: 1px solid #d1d9df;
  background: $color-b-w-0;
  margin-top: auto;
  width: 100%;
  max-width: 1400px;

  @media screen and (max-width: 767px) {
    gap: 1em;
    position: fixed;
    padding: 1.5em 2em;
    padding-right: 3em;
  }
}

.circle.light.task_close {
  position: sticky;
  z-index: 9;
  left: calc(50% - 285px);

  @media screen and (min-width: 769px) {
    left: calc(50% - 372px);
  }

  @media screen and (min-width: 1200px) {
    left: calc(50% - 140px);
  }

  position: absolute;

  &[disabled] {
    background-color: white !important;
    opacity: 1 !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
}

.reader_setting_preview {
  background: $color-blue-100;
  border-radius: 10px;
  padding: 24px;
  margin-top: 48px;
  margin-bottom: 30px;
  word-break: break-word;

  @media screen and (max-width: 767px) {
    max-width: 96vw;
  }

  .paragraph.paragraph--s {
    text-transform: uppercase;
    color: #5f798c;
    margin-bottom: 12px;
  }
}

.last_step_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  height: 100vh;
  justify-content: center;
  padding-inline: 3em;

  .mdc-button.mdc-button--unelevated.mat-primary {
    max-width: 300px;
  }

  .mat-icon {
    min-width: 99px !important;
    min-height: 99px !important;
    color: $color-green-600 !important;
  }
}

.book_preview_cover {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $color-blue-50;
  padding: 40px 0 40px 0;
  border: 1px solid #d1d9df;
  border-radius: 12px;

  img {
    max-width: 200px;
  }

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;
    margin-bottom: 8px;
    color: $color-b-w-800;
    max-width: 410px;
    text-align: center;
  }

  .paragraph.paragraph--l {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #5f798c;
    margin-bottom: 28px;
  }
}

.book_preview_warm_box {
  padding: 16px 20px;
  background: #fffaea;
  border-radius: 12px;
  margin-bottom: 32px;
  display: flex;
  gap: 19px;

  mat-icon {
    min-width: 27.5px !important;
    min-height: 27.5px !important;
    color: $color-orange-600;
  }
}

.book_preview_title {
  margin-bottom: 24px;

  .h2,
  .paragraph {
    color: $color-b-w-800;
  }
}

.hidden_row_detail {
  margin-top: 32px;
}

.mat-mdc-text-field-wrapper {
  border-radius: 12px !important;
  background-color: #fafafa;
}

.book_list_select {
  .mat-mdc-form-field-infix {
    display: flex;
    align-items: center;
  }

  &--age,
  &--difficulty {
    .mat-select {
      width: 48%;
    }
  }

  &--grade {
    .mat-select {
      width: 80%;
    }
  }

  &--categories {
    .mat-select {
      width: 75%;
    }
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    padding: 0 !important;
  }

  mat-label {
    margin-right: 8px;
  }
}

.preview_diagnostic_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 66px;
  padding-bottom: 32px;
  background-color: $color-blue-100;
  border: 1px solid #d1d9df;
  border-radius: 12px;
  margin-bottom: 50px;

  img {
    max-width: 316px;
    margin-bottom: 28px;
  }

  .mat-unelevated-button.mat-unset {
    margin-top: 34px;
    max-width: 229px;
  }
}

.diagnostic_hint_box {
  display: flex;
  align-items: center;
  background-color: $color-blue-50;
  padding: 26px;
  border-radius: 12px;
  margin-bottom: 24px;

  mat-icon.mat-icon {
    min-width: 30px !important;
    min-height: 30px !important;
    margin-right: 26px;
    color: $color-blue-600 !important;
  }
}

.task-license-header {
  border-bottom: 1px solid #d1d9df;
  display: flex;
  justify-content: space-between;
  padding: 31px;
  position: relative;
  left: -16px;
  width: calc(100% + 16px);

  .task-license-available {
    display: flex;
    align-items: center;

    .mat-icon {
      min-width: 19px !important;
      min-height: 19px !important;
      margin-right: 6px;
      margin-left: 13px;
      color: #03cc90 !important;
    }

    .paragraph.paragraph--l.left {
      color: $color-b-w-800;
    }

    .paragraph.paragraph--l.right {
      color: #116089;
    }
  }

  .task-license_time {
    .paragraph {
      color: #116089;
    }
  }
}

.diagnostic_view_header {
  text-align: left;
  display: flex;
}

app-select-all-option {
  border-bottom: 0.5px solid #d8dddc;
}

.task-diagnostic_container {
  padding: 0 16px;
  margin-bottom: 24px;

  .task-diagnostic_inner {
    box-shadow: 0px 4px 21px rgba(54, 62, 90, 0.07);
    border-radius: 18px;
    min-height: 178px;
    padding: 30px;
    background-color: $color-b-w-0;
    background-position: center right;
    background-repeat: no-repeat;

    &--pre {
      background-image: url('./assets/diagnostic_pre_bg.png');
    }

    &--mid {
      background-image: url('./assets/diagnostic_mid_bg.png');
    }

    &--post {
      background-image: url('./assets/diagnostic_post_bg.png');
    }

    &:hover {
      box-shadow: 0px 4px 6px rgba(54, 62, 90, 0.4);
    }
  }

  .task-diagnostic_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 44px;

    .task-diagnostic_header_left {
      display: flex;
      gap: 12px;
    }

    .diagnostic_chip {
      padding: 5px 10px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: $color-blue-100;

      p {
        font-size: 16px;
        line-height: 25.6px;
      }

      mat-icon.mat-icon {
        margin-right: 7px;
      }

      &__type {
        background: $color-blue-100;
        display: flex;

        p {
          color: $color-blue-600;
        }
      }

      &__deadline {
        background: $color-green-50;
        display: flex;

        strong {
          font-weight: 600;
        }

        p {
          color: $color-green-600;
        }

        .mat-icon {
          color: $color-green-600;
        }
      }

      &__teacher {
        background: $color-blue-50;
        border: 1px solid $color-b-w-0;
        display: flex;

        .mat-icon {
          width: 20px !important;
          height: 20px !important;
        }

        p {
          color: $color-b-w-900;
        }

        .teacher_shortcut {
          background-color: #fcab54;
          border-radius: 50%;
          font-size: 10px;
          line-height: 14px;
          color: $color-b-w-0;
          margin-right: 7px;
          height: 18px;
          width: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  .task-diagnostic_footer {
    display: flex;
    justify-content: space-between;

    .diagnostic_footer_content {
      display: flex;
      align-items: center;

      strong {
        text-decoration: underline;
      }

      mat-icon.mat-icon {
        margin-right: 18px;
      }

      h2 {
        color: $color-b-w-0;
        margin: 0;
      }
    }

    .view_button {
      min-width: 292px;
    }
  }
}

.student_task_info_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    margin-top: 1em;
  }
  strong {
    text-decoration: underline;
  }

  @media screen and (max-width: 767px) {
    .student_task_info .paragraph--l {
      margin-left: 0.25em;
      margin-top: -15px;
    }
  }
}

.student_task.active_tab {
  .view_button {
    min-width: 292px;
  }
}

.smiley_results {
  margin-block: 1em;
  display: flex;
  gap: 8px;

  .smiley_results_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    border-radius: 100px;
    gap: 6px;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }

    &__smile {
      background-color: $color-green-50;
    }

    &__mad {
      background-color: $color-yellow-100;
    }

    &__sad {
      background-color: $color-red-50;
    }
  }

  mat-icon.mat-icon {
    width: 24px !important;
    height: 24px !important;
    min-width: 28px;

    /* @media screen and (max-width:767px) {
             margin-top: 5px;
        } */
  }
}

.student_task_expired {
  .student_chip {
    background-color: $color-blue-100;
    border-radius: 24px;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &__deadline {
      background: $color-green-50;
      display: flex;
      color: $color-green-600;

      .mat-icon {
        color: #5b7385;
      }
    }

    &__teacher {
      background: $color-blue-50;
      display: flex;

      .mat-icon {
        width: 20px !important;
        height: 20px !important;
      }

      p {
        color: $color-b-w-900;
      }

      .teacher_icon {
        background-color: #8faabd;
        border-radius: 50%;
        font-size: 12px;
        line-height: 14px;
        color: $color-b-w-0;
        margin-right: 7px;
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &_footer {
    @media screen and (max-width: 767px) {
      .paragraph--l {
        margin-left: -30px;
      }

      .student_task_info {
        margin: 0;
      }
    }
  }
}

.orange_icon_tip {
  color: $color-gradient-05 !important;
  width: 36px !important;
  height: 36px !important;
}

.tree_container {
  background-color: $color-b-w-0;
  border-radius: 10px;
  padding: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .tree_img {
    max-width: 317px;
  }

  .forest-wrap {
    display: flex;
  }

  .tree_wrap {
    margin-top: 34px;
    height: 100%;

    .young-tree-wrap {
      display: flex;

      .young-tree {
        max-width: 64px;
        width: 100%;
      }
    }
  }

  .tree_counter {
    max-width: 100px;
    max-height: 100px;
    width: 100%;
    height: 100%;
    background: #03cc90;
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 12px;

    .tree_count {
      font-weight: 700;
      font-size: 32px;
      line-height: 100%;
      color: $color-b-w-0;
    }

    p {
      font-weight: 600;
      font-size: 12px;
      line-height: 140%;
      color: $color-b-w-0;
    }
  }

  .tree_description {
    display: flex;
    background-color: #ecf6f0;
    border-radius: 8px;
    margin-top: 22px;
    padding: 10px 16px;

    .tree_description_img {
      max-width: 27px;
    }

    .tree_description_img.little {
      max-width: 47px;
    }

    .tree_description_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 16px;

      span {
        color: #ed702d;
      }
    }
  }

  .tree_progress {
    max-width: 64px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    app-student-progress-bar {
      width: 64px;
    }
  }
}

.reading_results,
.student_awards_wrap,
.diagnostic_result_wrap {
  background-color: $color-b-w-0;
  border-radius: 10px;
  padding: 2em;

  @media screen and (max-width: 767px) {
    padding: 1em;
  }
}

.student_awards_wrap,
.diagnostic_result_wrap {
  @media screen and (max-width: 767px) {
    margin-bottom: 50px;
  }
}

.reading_results {
  .dashboard_result_row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    gap: 1em;
    margin-top: 1em;
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .dashboard_result {
    display: flex;
    width: 100%;
    gap: 1em;
  }

  .dashboard_result_student {
    padding: 20px;
    border-radius: 12px;
    min-height: 150px;
    flex-basis: 50%;
    display: flex;
    flex-direction: column;

    .result_number {
      width: 45px;
      height: 45px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-radius: 50%;
      margin-bottom: 12px;
      background-color: $color-b-w-0;
    }

    .paragraph {
      &--s {
        margin-top: auto;
      }
    }

    .medals-wrap {
      .paragraph {
        &--s {
          margin-top: unset;
        }
      }
    }

    .paragraph.paragraph--l {
      color: $color-b-w-900;
      word-break: break-word;
      font-size: 14px;
      line-height: 19px;
    }

    .result-img {
      max-width: 77px;
      width: 100%;
    }

    .set_inline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .result_number {
      color: $color-slate-700;
    }

    &--performed-tasks {
      background-color: $color-green-200;

      // .result_number {
      //     color: $color-green-500;
      // }
    }

    &--reading-words {
      background-color: $color-blue-100;
      // .result_number {
      //     color: $color-blue-400;
      // }
    }

    &--reading-points {
      background-color: $color-pink-200;
      // .result_number {
      //     color: $color-pink-400;
      // }
    }

    &--medals {
      background-color: $color-yellow-200;
      // .result_number {
      //     color: $color-orange-200;
      //     min-width: 45px;
      // }
    }

    &--speak {
      background-color: $color-orange-150;

      .result_number {
        color: $color-orange-600;
        min-width: 45px;
      }
    }

    &--listen {
      background-color: $color-purple-200;

      .result_number {
        color: $color-purple-500;
        min-width: 45px;
      }
    }

    .medals-wrap {
      display: flex;
      position: relative;
      margin-right: -40px;
      margin-bottom: 8px;

      @media screen and (max-width: 767px) {
        margin-right: -24px;
        margin-bottom: 12px;
      }

      .bronze-medal,
      .silver-medal,
      .gold-medal {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media screen and (max-width: 767px) {
          width: 28px;
          height: 28px;
        }

        .bronze-medal-border,
        .silver-medal-border,
        .gold-medal-border {
          border-radius: 50%;
          width: 38px;
          height: 38px;
          display: flex;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 767px) {
            width: 26px;
            height: 26px;
          }
        }
      }
      .bronze-medal {
        background: #d79d68;

        .bronze-medal-border {
          border: 1px solid #bf7b3a;
        }

        p {
          color: #bf7b3a;
        }
      }

      .silver-medal {
        background: #f0f0f0;
        left: -16px;
        @media screen and (max-width: 767px) {
          left: -12px;
        }

        .silver-medal-border {
          border: 1px solid #d1d1d1;
        }

        p {
          color: #d1d1d1;
        }
      }

      .gold-medal {
        background: #fcda9d;
        left: -32px;

        @media screen and (max-width: 767px) {
          left: -20px;
        }

        .gold-medal-border {
          border: 1px solid #faba48;
        }

        p {
          color: #faba48;
        }
      }
    }
  }

  .mat-icon {
    margin-left: 10px;
    color: $color-primary;
  }
}

.student_awards_wrap {
  text-align: center;

  .student_awards_flex {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: center;
  }
  .student_awards {
    padding: 9px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-width: 140px;
    flex-basis: 20%;

    @media screen and (max-width: 767px) {
      flex-basis: 45%;
    }

    img {
      margin-bottom: 12px;
      filter: grayscale(100%);
      max-width: 140px !important;
      width: 100%;
    }
  }

  .student_awards_header {
    margin-bottom: 20.5px;
  }

  app-student-progress-bar {
    margin-top: auto;
    max-width: 150px;
    width: 100%;
  }

  .caption {
    color: $color-b-w-800 !important;
    font-size: 16px;
    line-height: 160%;
    font-weight: 400;
  }
}

.diagnostic-results_row,
.tree_row {
  margin-bottom: 12px;
}

.white_target {
  color: $color-b-w-0;
}

.orange_target {
  color: rgb(243, 99, 27);
}

.student-nav-button {
  color: #ed702d !important;
  padding-bottom: 9.5px !important;
  width: 100px;
  border-radius: 0;

  mat-icon.mat-icon {
    width: 26px !important;
    height: 26px !important;
    margin-right: 0 !important;
  }
  .mdc-button__label {
    @media screen and (max-width: 840px) {
      display: none;
    }
  }
}

.task_note {
  padding: 0 !important;
  background: $color-orange-600 !important;
  border-radius: 100px;
  max-width: 40px !important;
  max-height: 40px !important;
  margin-right: 20px !important;

  mat-icon.mat-icon {
    width: 20px !important;
    height: 20px !important;
    top: -1px !important;
  }
}

.task_note.read {
  background: $color-slate-500 !important;
}

.nav_notification {
  background: transparent !important;

  &.mdc-fab.mat-mdc-fab.mat-accent {
    box-shadow: none;
  }

  mat-icon.mat-icon {
    width: 42px !important;
    height: 42px !important;
    color: #e7eff3;
  }
}

.nav_notification.active {
  mat-icon.mat-icon {
    color: #ff6600;
  }

  .mat-badge-content {
    background: $color-b-w-0;
    color: $color-b-w-800;
    border: 2px solid #ff6600;
    width: 21px;
    height: 21px;
    position: absolute;
    right: 0 !important;
    top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.student_menu_button {
  font-size: 16px !important;
  font-weight: 400;
  line-height: 26px;
  color: #116089 !important;
  display: flex;
  flex-direction: row-reverse;

  .mat-icon.dropdown-icon {
    width: 12px !important;
    height: 24px !important;
    margin-left: 16px !important;

    @media screen and (max-width: 844px) {
      margin-inline: 0 !important;
    }
  }
  p {
    text-transform: capitalize;
  }
}

.student_menu {
  transition: 0.3s all;

  .mat-mdc-menu-item {
    border-bottom: 1px solid #d8dddc;
    padding-block: 1em;
  }

  .mat-mdc-menu-content {
    padding: 0;
  }

  .mat-mdc-menu-item:last-child {
    border-bottom: none;
  }

  .mat-mdc-menu-item:hover {
    .mdc-list-item__primary-text {
      color: #db1919;
    }

    mat-icon.mat-icon {
      color: #db1919;
    }
  }
}

.diagnostic_result_task_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

html {
  &.platform-ios {
    body {
      background-color: #ffffff;
    }

    // notch devices only
    @media screen and (max-height: 926px) and (hover: none) and (pointer: coarse) {
      @supports (padding: env(safe-area-inset-top)) {
        // exclude devices with larger widths, i.e., iPads
        @media not all and (min-device-width: 768px) {
          .is-app {
            // Notch fix
            padding-top: constant(safe-area-inset-top);
            padding-top: env(safe-area-inset-top);

            mat-toolbar {
              padding-top: 10px;
            }
          }
        }
      }
    }
  }

  .is-app {
    position: relative;

    .mat-toolbar {
      position: sticky;
      top: 0;
      z-index: 2;
      // padding-top: constant(safe-area-inset-top);
      // padding-top: env(safe-area-inset-top);
    }
  }
}

.teacher_button_transparent {
  border: 1px solid !important;
  color: #13794f !important;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
  width: 100%;
}

.teacher_button_primary {
  //border: 1px solid #13794f !important;
  color: $color-b-w-0;
  background-color: $button-color;
  border-radius: 10px !important;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 160%;
}

.notification-overlay-panel {
  .mat-mdc-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  }
  form {
    max-width: unset;
    margin-top: 40px;
  }

  .notification_controller {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1em;

    @media screen and (max-width: 767px) {
      padding-top: 2em;
    }

    .username {
      text-transform: capitalize;
    }

    .mat-mdc-form-field {
      width: 100%;
    }

    .last_step_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 100%;
      left: 0;
      height: 100%;
      justify-content: center;
      margin-bottom: 1em;

      .mdc-button.mdc-button--unelevated.mat-primary {
        margin-top: 1em;
        max-width: 240px;
      }
    }
    .paragraph.paragraph--l {
      font-weight: 700;
      font-size: 20px;

      @media screen and (max-width: 767px) {
        font-weight: 400;
        line-height: unset;
      }
    }
    &_wrap {
      width: 100%;
    }
  }

  .notification_button_wrap {
    display: flex;
    gap: 16px;
    margin-top: 40px;
  }

  .inline_text {
    justify-content: center;
    margin-bottom: 40px;
    flex-wrap: wrap;
  }
}

.notification-list-panel {
  max-width: 825px !important;
  width: 100%;

  .mat-mdc-dialog-container {
    position: relative;
    background-color: $color-b-w-10;
    overflow: auto;
    height: auto;
  }

  .notification-list_container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 60px;
    .h2 {
      margin-bottom: 8px;
    }
  }

  img {
    max-width: 179px;
    margin-bottom: 40px;
  }

  .notification-list-content {
    position: relative;
    margin-top: 28px;
    width: 100%;

    .notification-list-message {
      max-width: 621px;
      width: 100%;
      margin: 0 auto;
    }

    mat-icon.semicolon_top {
      position: absolute;
      left: 0;
      top: -15px;
    }

    mat-icon.semicolon_bottom {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .view_button {
    margin-top: 40px;
    min-width: 268px;
  }
}

.accordion_list_text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .mat-icon {
    color: #13794f;
    min-width: 27px !important;
    min-height: 27px !important;
    margin-left: 12px !important;

    &.mat-gray {
      color: $color-slate-500;
    }
  }

  .reference_value_wrap {
    display: flex;
  }

  input {
    background: $color-b-w-0 !important;
    border: 1px solid #8faabd;
    min-width: 57px;
    max-width: 57px;
    border-radius: 10px;
    text-align: center;
    padding: 6px 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    margin-left: 6px;
  }
}

.reference_value_input {
  display: flex;

  .reference_icons {
    display: flex;
  }
}

.award-overlay-task-panel {
  .mat-mdc-dialog-container {
    position: relative;
    background: linear-gradient(
      89.79deg,
      $color-blue-400 15.84%,
      #fb9cb3 111.54%
    );
    box-shadow:
      0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    border-radius: 16px;
    max-width: 604px;
    margin: 0 auto;
    padding: 60px 32px;
  }

  .award-overlay_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .confetti-left {
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: flex-end;
      right: 12px;
    }

    .award-img_wrap {
      margin-bottom: 16px;
      display: flex;

      .confetti-top-left {
        width: 65px;
      }

      .confetti-bottom-left {
        width: 46px;
      }

      .confetti-top-right {
        width: 44px;
      }

      .confetti-bottom-right {
        width: 89px;
      }
    }

    .award-img {
      max-width: 140px;
      border-radius: 62%;
    }

    .h1 {
      margin-bottom: 16px;
    }

    .h1,
    p {
      color: $color-b-w-0;
    }

    .view_button {
      margin-top: 40px;
    }
  }
}

.task-completed-overlay-task-panel-backdrop,
.start-task-overlay-task-panel-backdrop .award-overlay-task-panel-backdrop,
.report-error-overlay-panel-backdrop,
.student-report-overlay-panel-backdrop {
  background: rgb(0 0 0 / 40%);
}

.task-start-overlay-task-panel {
  .mat-mdc-dialog-container {
    width: 600px;
  }
  .task-start-overlay_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 286px;
      width: 100%;
      margin-bottom: 40px;
    }

    .h1 {
      margin-bottom: 16px;
    }

    .mat-mdc-unelevated-button {
      margin-top: 2em;
      padding-inline: 1em;
    }
  }
}

.task-preview-reading-info {
  margin-top: auto;
  padding-inline: 1em;
  margin-bottom: 1em;

  @media screen and (max-width: 767px) {
    margin-top: 2em;
    margin-bottom: 8em;
  }

  .reading-info_box {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .mat-icon {
      min-width: 32px !important;
      min-height: 32px !important;
      margin-right: 12px !important;
      color: #ff6600;
    }
  }
}

.task-preview_book-info {
  padding-inline: 1em;
  .task-preview_book-description {
    .paragraph {
      color: #5f798c !important;
    }
  }
  &--title {
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .task-preview_chip-wrap {
    margin-bottom: 40px;
    display: flex;
    gap: 3px;
    margin-top: 30px;

    @media screen and (max-width: 767px) {
      margin-block: 1em;
      justify-content: center;
    }

    .task-preview_chip {
      background-color: $color-b-w-0;
      border-radius: 24px;
      padding: 6px 14px;

      .paragraph {
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        color: $color-b-w-900;
      }
    }
  }
}

.task-preview_book_hint {
  background: $color-b-w-0;
  border-radius: 12px;
  padding: 26px 30px;
  display: flex;
  width: 100%;
  margin-bottom: 33px;
  align-items: center;

  @media screen and (max-width: 767px) {
    border-radius: 12px;
    padding-inline: 2.5em;
    padding-top: 1em;
    padding-bottom: 0.5em;
    position: absolute;
    bottom: 70px;
    z-index: 99;
    box-shadow: 0px 0px 10px lightgray;
  }

  .mat-icon {
    color: #ff6600;
    margin-right: 16px !important;
    width: 35px !important;
    height: 35px !important;
  }
}

.task-preview_book {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('./assets/task-preview.png');
  background-color: $background-white;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 65px 60px 30px 60px;
  height: 100vh;
  justify-content: space-between;
  overflow-y: scroll;

  @media screen and (max-width: 767px) {
    height: unset;
    background-color: unset;
    padding: unset;
    padding-top: 4em;
  }

  img {
    height: 60vh;
    width: auto;
    margin-block: 2em;
    border: 1.5px solid black;

    @media screen and (max-width: 767px) {
      height: 40vh;
      margin-block: 1em;
    }
  }

  button.mat-button-base.mat-gradient {
    max-width: 280px;
  }
}

.task-preview-container {
  padding-top: 0;
  padding-bottom: 0;

  @media screen and (max-width: 767px) {
    overflow-y: scroll;
    height: calc(100% - 60px);
  }
}

.task-preview-buttons {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 1.5em;
  margin-top: auto;

  @media screen and (max-width: 767px) {
    position: absolute;
    background: white;
    padding: 2em;
    bottom: 0;
    z-index: 100;
  }

  .mdc-button.mdc-button--unelevated {
    width: 100%;
  }
}

.book-preview-buttons {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: flex-end;

  span {
    font-weight: 400 !important;
  }

  .buttons {
    //max-width: 296px;
    width: 100%;
    display: flex;
    gap: 16px;
  }

  .mat-mdc-unelevated-button {
    padding-inline: 2em;
  }
}

.task-preview_info {
  padding-top: 40px;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    height: unset;
    padding-top: 1em;
  }
}

.task-preview_book-info-col {
  background-color: $color-b-w-10;

  @media screen and (max-width: 767px) {
    background-color: unset;
    order: 2;
  }
}

.task-preview_book_col {
  padding-left: 0 !important;
  padding-right: 0 !important;

  @media screen and (max-width: 767px) {
    position: unset;
  }

  .close-button {
    background-color: $color-orange-600 !important;
    color: #fff !important;
  }
}

swiper-slide {
  display: flex;
  //width: 350px !important;
}

.swiper-initialized::part(button-prev),
.swiper-initialized::part(button-next) {
  background-color: #ff6600;
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  border-radius: 100%;
  min-width: 80px;
  min-height: 80px;
}

.swiper-initialized::part(button-prev) {
  background-image: url('./assets/swiper-nav-next.svg');
  transform: rotate(180deg);
}

.swiper-initialized::part(button-next) {
  background-image: url('./assets/swiper-nav-next.svg');
}

.swiper-initialized::part(button-prev)::after,
.swiper-initialized::part(button-next)::after {
  content: '';
}

.swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  background-color: red;
}

swiper-container {
  width: 100%;
}

.swiper-initialized::part(button-next):disabled {
  display: none !important;
}

.backButton_text {
  color: $color-b-w-900 !important;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  padding-left: 0 !important;

  .mat-icon {
    min-width: 40px !important;
    min-height: 40px !important;
    background-color: #ff6600;
    color: $color-b-w-0;
    border-radius: 50%;
    margin-right: 16px !important;
  }
}

.book-preview_student-info-col {
  padding: 40px 32px 40px 40px;
}

app-record-rtc {
  .book_reader_settings_toc {
    margin-bottom: 0px;
  }
}

.book-settings-menu.mat-mdc-menu-panel.mat-mdc-menu-panel {
  padding-inline: 0.5em;
  max-width: unset;
}

.bookview-student-container {
  background: $color-b-w-0;
  padding-top: 0;
  padding-bottom: 0;
  height: 100vh;

  .bookview-student-col-setting {
    border-right: 1px solid $color-b-w-200;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    @media screen and (max-width: 767px) {
      flex-direction: row;
      align-items: center;
      padding: 1em;

      &--topbar {
        width: 100%;
        background-color: white;
        padding: 1em;
        align-items: flex-start;
        height: 70px;
      }
    }

    .book_reader_settings_toc {
      .mat-icon-button.mat-button-base.mat-outlined-student,
      .readerButton {
        max-height: unset !important;
        height: 100%;
      }
    }
  }

  .book_reader-col {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    height: 100vh;
    padding-inline: 0 !important;

    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .bookview-student-col-time {
    border-left: 1px solid $color-b-w-200;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    &--topbar {
      width: 100%;
    }
  }

  .bookview-student-time-wrap,
  .book_reader_settings_toc {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .bookview-student-time-wrap {
    align-items: flex-start;

    p.paragraph {
      color: black;
    }
  }

  .book_reader_settings {
    display: flex;
    flex-direction: column;
    gap: 1em;
    align-items: center;
    margin-top: 2em;
    height: 40%;
    justify-content: space-between;
    margin-top: 1em;

    @media screen and (max-width: 767px) {
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;
      gap: unset;
    }

    button.mat-focus-indicator.readerButton.mat-unelevated-button.mat-button-base {
      height: 100%;
      max-height: unset;
    }

    .mdc-button.mdc-button--unelevated {
      flex-direction: column;
      color: #5b7385;
      font-size: 12px;
      z-index: 0;
      padding: 3em 1em;
    }

    .mat-mdc-unelevated-button > .mat-icon {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0.5em;
    }
  }

  .book-preview-buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    background: $color-blue-50;
    padding: 32px;
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 0px;
    box-shadow:
      0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      padding-inline: 1em;
    }
    .boox-preview_text {
      width: 100%;
      @media screen and (max-width: 767px) {
        text-align: center;
      }
    }
    button.mdc-button.mdc-button--unelevated {
      &.mat-primary-student,
      &.mat-secondary-student {
        @media screen and (max-width: 767px) {
          width: 100%;
        }
      }
    }
    .buttons {
      justify-content: flex-end;
    }
  }

  .bookview-student-time-wrap {
    .paragraph {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #5f798c;
    }

    .paragraph.paragraph--l {
      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      text-align: center;
      color: $color-b-w-800;
      margin-bottom: 26px;
    }

    .book-reader-pagination {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: $color-b-w-800;
      margin-bottom: 16px;
    }

    .book-reader-navbar {
      .mat-icon-button {
        color: #ed702d;
      }

      .mdc-button.mdc-button--unelevated:disabled {
        color: #ed702d !important;
      }
    }
  }

  .record_buttons_wrap {
    display: flex;
    flex-direction: column;
    margin: 0;

    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 0px;
      width: 100%;
      background: white;
      flex-direction: row;
      box-shadow: 0 0 10px lightgray;
      justify-content: space-evenly;
      padding: 1em;
      padding-top: 1em;
      padding-top: 1.5em;
    }
  }

  .countDown {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(13, 55, 118, 0.25);
    backdrop-filter: blur(3px);

    .counter {
      font-size: 130px;
      font-weight: 700;
      color: $color-b-w-0;
    }
  }
}

.readerButton {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #5b7385;

  .mat-icon {
    min-width: 28px !important;
    min-height: 28px !important;
  }
}

.readerButton.toc {
  .mat-icon {
    min-width: 46px !important;
    min-height: 46px !important;
  }
}

.record_overlay_img {
  max-width: 130px;
  width: 100%;
}

.loading-sceen-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  background: $color-b-w-0;
  background-image: url('./assets/loading-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: -16px;
  max-width: $layout-max-width !important;

  img {
    max-width: 398px;
    width: 100%;
    margin-bottom: 60px;
  }

  .paragraph.paragraph--l {
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: $color-b-w-800;
  }
}

.cdk-overlay-pane {
  .mdc-menu-surface.mat-mdc-select-panel {
    width: 100%;
    padding: 0.25em;
    max-width: unset;
    margin-left: 10px;
  }

  mat-button-toggle-group {
    width: 100%;
    height: 60px;
    border-radius: 4px 0 0 4px !important;
    border: 0.5px solid #8faabd;
  }

  mat-button-toggle {
    width: 100%;
    background-color: $color-b-w-0 !important;
    color: $color-b-w-900 !important;
    display: flex;
    min-width: 60px;
  }

  .hyphenation_mode {
    min-width: unset;
  }

  .mat-button-toggle-checked {
    background: $color-blue-100 !important;
    color: $color-blue-600 !important;
    border: 0.5px solid $color-blue-100;
  }

  // .mat-button-toggle-label-content {
  //     line-height: initial !important;
  //     font-size: 13px !important;
  // }
}

.mat-unelevated-button {
  .mat-menu-trigger {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.reader-menu-open {
  background: $color-blue-50;
  color: $color-blue-600;

  .mat-icon {
    color: $color-blue-600 !important;
  }
}

.empty-books-img {
  max-width: 185px;
  width: 100%;
  margin-bottom: 32px;
}

.teacher_sidebar_admin {
  border-top: 0.5px solid #d8dddc;

  .paragraph {
    color: $color-slate-600;
    padding: 16px 0;

    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }
}

/* SKELETON LOADER STYLE */
.admin-widget-loading-bg {
  background-color: #f6f8fb !important;
  padding: 18px !important;
}

.skeleton-widget-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  .short {
    margin-bottom: 20px;
  }
}

.short {
  width: 50px;
}

.medium {
  width: 100px;
}

.long {
  width: 150px;
}

.circle {
  border-radius: 50%;
  width: 27px;
  height: 27px;
}

.bar {
  border-radius: 6px;
  height: 20px;
}

.icon-and-bar {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 6px;
}

.skeleton-loader {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #eff1f6;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

//END OF SKELETON LOADER STYLE

.admin-license-widget {
  background: $color-b-w-0;
  padding: 20px;
  border-radius: 12px;
  margin-top: 16px;

  .admin-license-widget_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;

    button.mat-primary {
      max-width: 30%;
      width: 100%;
    }
    @media screen and (max-width: 834px) {
      .mat-mdc-unelevated-button .mdc-button__label {
        font-size: 0.85em;
      }
    }
  }

  .inline_text {
    display: flex;
    align-items: flex-start;
  }

  .class_detail_result_dropdown {
    top: -6px;
  }

  .admin-license-widget-total,
  .admin-license-widget-used,
  .admin-license-widget-unused {
    padding: 16px;
    border-radius: 12px;
    width: 100%;
  }

  .admin-license-widget-total {
    background: $color-blue-50;

    h2 {
      color: $color-b-w-900;
    }
  }

  .admin-license-widget-used {
    background: $color-b-w-10;

    h2 {
      color: $color-slate-550;
    }
  }

  .admin-license-widget-unused {
    background: $color-green-50;

    h2 {
      color: #13794f;
    }
  }
}

.table-scroll {
  @media screen and (max-width: 929px) {
    overflow-x: scroll;
    width: unset !important;
  }
}

.admin-widget-table {
  background-color: $color-b-w-0;
  padding: 20px;
  border-radius: 12px;
  margin-top: 16px;

  table {
    margin-top: 24px;
  }

  .mat-header-row {
    margin-top: 2em;
  }

  td.mat-mdc-cell:first-of-type,
  th.mat-header-cell:first-child {
    min-width: 180px;

    @media screen and (max-width: 929px) {
      padding-right: 1em;
    }
  }

  th.mat-header-cell.mat-column-amount {
    padding-right: 36px;

    @media screen and (min-width: 692px) {
      padding-right: 0;
    }
  }

  td.mat-column-amount,
  .mat-column-amount {
    min-width: 100px;

    @media screen and (max-width: 929px) {
      padding-left: 1em;
    }
  }

  td.mat-column-group {
    min-width: 100px;
  }

  td.mat-column-type_key {
    min-width: 120px;
  }

  td.mat-column-created,
  td.mat-mdc-cell:last-of-type {
    min-width: 100px;
    padding-right: 1em;
  }

  .teacher_icon {
    background: #ff6600;
    border-radius: 50%;
    padding: 4px 6px;
    margin-right: 10px !important;

    .mat-icon {
      width: 10px !important;
      height: 12px !important;
    }
  }

  th.mat-header-cell {
    background: #f6f8fb;
  }

  th.mat-header-cell:first-child {
    border-radius: 12px 0px 0px 12px;
  }

  th.mat-header-cell:last-child {
    border-radius: 0px 12px 12px 0px;
    padding-left: 2em;
  }
}

.license-action-wrap {
  display: flex;
  gap: 12px;
  /* width: 100%;
  justify-content: center; */

  /* .license-accept {
        background: #13794F;
        color: $color-b-w-0;
    } */

  .license-decline.mdc-fab.mdc-fab--mini {
    background: #db1919;
    color: $color-b-w-0;
  }

  .mat-header-cell {
    background: $color-b-w-10;
  }

  .mat-icon-button {
    width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-icon {
      width: 18px !important;
      height: 17px !important;
    }
  }
  button.mdc-button {
    padding-inline: 0.5em;
  }
}

/* .group-more {
    background: #E7EFF3;
    color: #000;
} */

.expand_more_col {
  width: 80px;
}

.cdk-overlay-pane mat-option.mat-pseudo-checkbox {
  background-color: transparent;
}

.fake-input-wrapper {
  overflow: hidden;
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.fake-input {
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
  padding-inline: 1.2em;
  cursor: not-allowed;
  height: 58px;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
  background-color: #eeeeee;
  overflow: auto;
}

app-admin-list {
  th.mat-mdc-header-cell:first-of-type,
  th.mat-mdc-header-cell.mat-column-email {
    padding-left: 0 !important;
  }

  .mat-mdc-cell:first-of-type {
    min-width: 150px;

    @media screen and (max-width: 1071px) {
      padding-right: 1em;
      box-shadow: rgba(33, 35, 38, 0.1) 10px 0px 10px -2px;
    }
  }

  td.mat-mdc-cell.mat-column-email {
    min-width: 150px;

    @media screen and (max-width: 1071px) {
      padding-left: 1em;
    }
  }

  td.mat-mdc-cell.mat-column-role {
    min-width: 100px;
  }

  td.mat-column-status {
    min-width: 60px;

    @media screen and (max-width: 929px) {
      padding-right: 1em;
    }
  }

  td.mat-mdc-cell:last-of-type {
    min-width: 120px;
    text-align: center;

    @media screen and (max-width: 1071px) {
      padding-left: 1em;
      box-shadow: rgba(33, 35, 38, 0.1) -5px 0px 10px 0px;
    }
  }
}

.dashboard-container {
  display: flex;
  max-width: $layout-max-width !important;
}

.authenticator-setup-container {
  display: flex;
  max-width: $layout-max-width !important;
  background-color: $color-b-w-0;
  justify-content: center;
  text-align: center;

  .qrcode_wrapper {
    display: flex;
    max-width: 300px;
    margin: 0 auto;
  }

  form {
    display: flex;
    flex-flow: column;
    margin: 36px auto;
  }
}

.admin-teacher-table {
  thead {
    display: none;
    height: 0 !important;
  }
}
.nested-table {
  thead {
    display: table-header-group !important;
    .mdc-data-table__header-row {
      background-color: $color-slate-250 !important;
      .mdc-data-table__header-cell {
        border-bottom-color: transparent !important;
      }
    }
  }
  .mat-mdc-cell.mat-column-username {
    @media screen and (max-width: 929px) {
      //padding-left: 2em;
    }
  }
  .mdc-data-table__header-cell {
    padding-right: 0;
  }
  .mdc-data-table__cell {
    padding-inline: 0;
  }
}
.mdc-data-table__row .mdc-data-table__cell {
  border-bottom: 1px solid $color-slate-200 !important;
}

.dashboard-license-container,
.teacher-task-list-container,
.task-detail-container {
  .nested_table_td {
    padding: 0 !important;

    .mat-cell.cdk-column-action.mat-column-action {
      width: 100px;
    }
    th {
      text-align: left !important;
      font-weight: 600;
    }
    tr {
      td {
        text-align: center !important;
      }
    }
  }

  // .hidden_nested_tr {
  //   opacity: 0;
  //   height: 0 !important;
  //   line-height: 0;
  //   display: none;
  // }

  td {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
  }
}

.trash-menu-item {
  color: $color-red-500;

  .mat-icon {
    color: $color-red-500;
  }
}

th.mat-header-cell.cdk-header-cell.cdk-column-action.mat-column-action,
mat-header-cell.cdk-column-buttons.mat-column-buttons {
  width: 100px;
  max-width: 100px;
}

.mat-cell.cdk-column-buttons.mat-column-buttons {
  max-width: 130px;
}

.hidden_nested_header .mat-mdc-table .mdc-data-table__header-row {
  display: none;
}
.diagnostic-select-wrap .diagnostic-selection-list {
  display: flex !important;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
  /* &.mdc-list{
        @media screen and (max-width:767px){
            overflow: scroll;
            height: calc(100vh - 450px);
        }
    }
 */
  mat-pseudo-checkbox.mat-pseudo-checkbox.ng-star-inserted {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .expiredDiagnostic_date.mdc-list-item.mdc-list-item--disabled {
    background-color: #fff;
    border: 1px dashed #8faabd;

    .mdc-list-item__primary-text {
      opacity: 1;
    }

    .paragraph {
      color: $color-slate-500;
    }
  }

  .diagnostic-list-option {
    background: $color-b-w-10;
    border-radius: 12px !important;
    height: auto;
    padding: 2em;

    .mdc-list-item__primary-text {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      }
    }
    @media screen and (max-width: 767px) {
      padding-inline: 0;
      padding-block: 2em;
    }
    &.selected-diagnostic
      .mdc-list-item__start.mat-mdc-list-option-checkbox-before {
      @media screen and (max-width: 767px) {
        margin-bottom: 7em;
        transition: margin-bottom 0.25s;
      }
    }
    .mdc-list-item__start.mat-mdc-list-option-checkbox-before {
      margin-bottom: 0.75em;
    }

    .mat-list-item-content {
      padding: 24px !important;
      min-height: 130px !important;
    }
  }

  .mat-form-field {
    margin-top: 8px !important;
    margin-top: 0px;
  }

  .inline_text {
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .diagnostic-list-option {
    @media screen and (max-width: 767px) {
      transition: height 0.3s;
    }

    &.selected-diagnostic {
      background: #f0f7fe;
      border: 1px solid $color-blue-600;

      &.mat-mdc-list-item.mat-mdc-list-option
        .mdc-checkbox__native-control:enabled:checked
        ~ .mdc-checkbox__background {
        background-color: #fff !important;
        border: 5px solid $button-color !important;
      }

      @media screen and (max-width: 767px) {
        height: 180px;
      }
    }
  }

  .diagnostic-list-option.selected-diagnostic-complete {
    background: #f0f7fe;
    border: 1px solid $color-blue-600;

    &::after {
      display: none;
    }
  }

  .diagnostic-list-option.selected-diagnostic.selected-diagnostic-complete {
    background: $button-color-lighter;
    border: 1px solid $button-color;

    .mat-pseudo-checkbox-checked {
      background-color: $button-color !important;

      &::after {
        top: 5px;
        left: 3px;
        color: #fff;
        width: 16px;
        height: 6px;
      }
    }
  }
}

.mat-mdc-form-field-error {
  padding-top: 0.25em;
  font-size: 0.75em;
  text-align: center;
}

.license-transfer-backdrop,
.license-add-backdrop,
.pin-code-backdrop,
.password-changed-overlay,
.change-password-backdrop,
.release-licence-backdrop,
.helpdesk-panel-backdrop,
.trial-banner-backdrop,
.book-list-filter-backdrop,
.group-delete-overlay {
  background: rgba(0, 0, 0, 0.4);
}

.pin-code-panel {
  max-width: 755px !important;
  width: 100%;
  border-radius: 12px;

  .instructionVideo_wrap {
    display: flex;
    gap: 16px;
    justify-content: center;
  }

  form {
    width: 100%;
    max-width: unset;
  }

  .container-fluid {
    padding: 0;
  }

  .pin-code_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pin-code_inputs {
    display: flex;
    gap: 16px;
    margin-bottom: 40px;
    justify-content: space-between;

    input {
      max-width: 90px;
      min-height: 90px;

      height: 90px;
      border: 1px solid $color-slate-500;
      border-radius: 10px;
      display: flex;
      align-items: center;

      font-weight: 700;
      font-size: 26px;
      line-height: 36px;
      color: $color-green-600;
      text-align: center;
    }
  }

  .pin-img {
    max-width: 181px;
    width: 100%;
    margin-bottom: 9px;
  }
}

.video-overlay-panel {
  max-width: 920px !important;
  width: 100%;

  .container-fluid {
    padding: 0;
  }

  .video-overlay-container {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    padding: 4em;
    background-color: #f6f8fb;
    border-radius: 12px;

    .close-button--right {
      right: 35px;
    }

    .video-overlay-wrap {
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      margin-top: 32px;

      video {
        width: 100%;
      }
    }
  }
}

.input-with-label {
  .input-label-wrap {
    display: flex;
    justify-content: space-between;
  }

  .paragraph {
    margin-bottom: 8px;
  }
}

.steps_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  //margin-bottom: 40px;
  //padding:2em;

  .step_item {
    border: 1px solid $color-slate-500;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    .paragraph {
      color: $color-slate-500;
    }
  }

  .step_item.active {
    border: 2px solid $color-green-600;

    .paragraph {
      color: $color-green-600;
    }
  }

  .step_item.finished {
    background-color: $color-green-600;

    &:after {
      font-family: 'Material Icons';
      content: 'done';
      color: $color-b-w-0;
    }
  }

  .step-divider {
    width: 29px;
    height: 1px;
    background: $color-slate-500;
  }

  .step-divider.active {
    background: $color-green-600;
  }

  span.mat-mdc-radio-button label {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

html {
  &.impersonator-mode {
    background-color: $color-b-w-800;
    padding: 65px;
    overflow: hidden;

    body {
      border-radius: 12px;
      overflow: hidden;
    }

    .app-root-inner {
      border-radius: 12px;
      overflow: scroll;
      height: 100%;
    }

    .impersonation-bar {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0 65px;
      height: 65px;
      z-index: -1;

      .impersonation-bar-left {
        display: flex;
        align-items: center;
      }

      .impersonation-bar-right {
        display: flex;
        align-items: center;
      }

      .paragraph {
        color: $color-b-w-0;
      }

      .mat-unelevated-button {
        font-size: 14px !important;
        line-height: 19px !important;
        padding: 9px 14px !important;
      }
    }
  }
}

.loading-step.ng-star-inserted {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 300px);
  padding: 2em;

  @media screen and (max-width: 767px) {
    padding: 3em;
  }
}

.finished-step {
  display: flex;
  flex-direction: column;
  align-items: center;

  .last-step-img-key {
    max-width: 156px;
    width: 100%;
    margin-bottom: 40px;
  }

  .pdf-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pdf_overlay_button {
      margin-top: 1em;
      max-width: 292px;
      width: 100%;
    }
  }

  .pdf-warn-box {
    background: $color-slate-700;
    border-radius: 12px;
    margin-top: auto;
    padding: 24px 32px;
    margin-top: 40px;

    .paragraph {
      color: #fff;
    }

    .paragraph--l {
      margin-bottom: 16px;
    }

    img.megaphone {
      max-width: 146px;
      width: 100%;
      position: absolute;
      right: 15px;
      top: 21px;
    }
  }
}

mat-label {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #5b7385;
  margin-bottom: 8px;
}

.student-limit-reached-popover {
  max-width: 600px !important;

  .mat-mdc-dialog-container {
    border-radius: 12px !important;

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      padding: 2em;
      background-color: #f6f8fb;
      overflow-y: auto;

      @media screen and (min-width: 1200px) {
        padding: 3em !important;
      }
      @media screen and (max-width: 767px) {
        padding: 1.5em;
        overflow-y: auto;
        width: 90%;
        max-height: 95vh;
      }
    }
  }
}

.classes-year-updated-popover {
  max-width: 600px !important;

  .mat-mdc-dialog-container {
    border-radius: 12px !important;

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      padding: 2em;
      background-color: #f6f8fb;
      overflow-y: auto;

      @media screen and (min-width: 1200px) {
        padding: 3em !important;
      }
      @media screen and (max-width: 767px) {
        padding: 1.5em;
        overflow-y: auto;
        width: 90%;
        max-height: 95vh;

        .h1 {
          font-size: 32px;
        }
        .paragraph--l {
          font-size: unset;
        }
      }
    }
  }
}

.task-delete-overlay-panel {
  justify-content: center;
}
.notification-list-panel,
.license-add-panel,
.beta-info-panel,
.notification-overlay-panel,
.delete-overlay-panel,
.microphone-check-overlay-panel,
.books-preferences-overlay-panel,
.task-start-overlay-task-panel,
.record-overlay-panel,
.task-error-overlay-task-panel,
.archivments-panel,
.task-completed-overlay-task-panel,
.password-changed-overlay-panel,
.teacher-selection-overlay-panel,
.student-delete-overlay-panel,
.change-password-panel,
.group-delete-overlay-panel,
.activate-license-overlay-panel,
.csv-import-panel,
.expert-group-overlay-panel,
.task-delete-overlay-panel,
.license-distribution-panel,
.helpdesk-panel,
.reading-level-overlay-panel,
.release-licence-panel,
.pin-code-panel,
.book-list-filter-panel,
.recording-finished-panel {
  max-width: 755px !important;

  .mat-mdc-dialog-container {
    border-radius: 12px !important;

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      padding: 2em;
      background-color: #f6f8fb;
      overflow-y: auto;
      max-height: 100vh;

      @media screen and (min-width: 1200px) {
        padding: 3em !important;
      }
      @media screen and (max-width: 767px) {
        padding: 1.5em;
        overflow-y: auto;
        width: 90%;
        max-height: 95vh;

        .h1 {
          font-size: 32px;
        }
        .paragraph--l {
          font-size: unset;
        }
      }
    }
  }

  .instructionVideo_wrap {
    display: flex;
    gap: 16px;
    margin-top: 2em;
  }

  form {
    width: 100%;
    max-width: unset;
  }

  .container-fluid {
    padding: 0;
  }

  .license-add-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em 1em 1em;

    .button_wrap {
      display: flex;
      width: 100%;
      gap: 20px;
      margin-top: 40px;
    }

    .class_header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      mat-icon.mat-icon {
        margin-right: 10px;
        background: #ebeeef;
        border-radius: 100px;
        min-width: 36px !important;
        min-height: 36px !important;
        padding: 6px;
        color: $color-slate-600;
      }
    }

    .info_headline {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      mat-icon.mat-icon {
        width: 132px !important;
        height: 132px !important;
        color: $color-green-600;
        margin-bottom: 20px;
      }

      .h3 {
        margin-bottom: 16px;
      }
    }

    .button-wrap {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .mat-unelevated-button.mat-primary {
        max-width: 202px;
      }
    }

    .license-add-apply-box {
      padding: 16px;
      border-radius: 12px;
      background-color: $color-b-w-0;
      width: 100%;
      margin-top: 40px;

      .inline_text {
        justify-content: space-between;
        gap: 0;

        @media screen and (max-width: 767px) {
          flex-direction: column;
        }
      }

      .button_wrap {
        display: flex;
        gap: 20px;
        margin-top: 24px;
      }

      &--input {
        display: flex;
        flex-direction: column;
      }

      .license-amount {
        position: absolute;
        right: 0;
        top: 19px;
        right: 16px;
        color: $color-slate-550;
      }

      .mat-mdc-text-field-wrapper {
        max-width: 188px;
        .mat-mdc-form-field-input-control {
          margin-left: -15px;
        }
      }

      .mat-select-min-line {
        font-size: 14px;
      }

      mat-label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        color: $color-b-w-900;
      }
    }
  }

  input::placeholder {
    color: $color-slate-500;
  }

  .license_input {
    position: relative;

    .mat-icon {
      position: absolute;
      top: 12px;
      right: 15px;
      color: $color-slate-500;
    }
  }

  .license-input {
    height: unset;
    border: unset;
    min-height: unset;
    display: block;
    margin-bottom: 0;
    padding-left: 0;
  }

  input {
    min-height: 5%;
    width: 100%;
    height: 50px;
    border: 1px solid $color-slate-500;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3f3f3f;
    text-align: left;
    padding: 0 24px;
  }

  .instructionVideo_wrap {
    margin-top: 2em;
  }

  mat-header-cell {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  mat-header-row.mat-header-row {
    border: none;
  }

  .mat-table {
    background: #f6f8fb;
    margin-top: 40px;
  }

  mat-cell.mat-cell {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #3f3f3f;
  }

  button.mat-button-base.mat-primary.done {
    max-width: 309px;
  }
}
.books-preferences-overlay-panel {
  max-height: 95vh;
  @media screen and (max-width: 1199px) {
    max-width: 90vw !important;
  }
}

.languages-csv-panel {
  max-width: 600px !important;
  .mat-mdc-dialog-container {
    border-radius: 12px !important;
    .mat-mdc-dialog-surface.mdc-dialog__surface {
      background-color: #f3f7f9;
    }
  }
}
.timemode-overlay {
  max-width: 1000px;
  .mat-mdc-dialog-container {
    border-radius: 12px;

    .mat-mdc-dialog-surface {
      padding: 2em;
    }
  }
}

.add_class.license {
  background-color: $color-green-600;
  border-radius: 100px;
  margin-left: 10px;
  max-width: 27px;
  max-height: 27px;
}

.request-license-inputs {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  align-items: center;
  align-content: center;
  margin: 0 auto;

  .license-amount {
    position: absolute;
    right: 0;
    top: 17px;
    color: $color-slate-500;

    span {
      font-weight: 400;
    }
  }
}
.student-delete-overlay-panel {
  max-width: 602px !important;
  box-shadow:
    0px 8px 28px -6px rgb(24 39 75 / 12%),
    0px 18px 88px -4px rgb(24 39 75 / 14%);
  border-radius: 12px;
}

.license_amount {
  display: flex;
  gap: 41px;
}

.license_tabs .admin-license-tabs-loading {
  .mat-tab-label-active,
  .mat-tab-label {
    background: #eff1f6 !important;
    font-weight: 700 !important;
    font-size: 14px;
    line-height: 19px;
    color: #eff1f6 !important;
    display: inline-block;
    position: relative;
    overflow: hidden;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }

  mat-ink-bar.mat-ink-bar {
    display: none;
  }

  mat-tab-header.mat-tab-header {
    border: none;
  }

  .mat-tab-body {
    margin-top: 20px;
  }
}

.password-changed-overlay-panel {
  max-width: 603px !important;

  .container-fluid {
    padding: 0;
  }

  .changed-password_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .mat-icon {
      min-width: 99px;
      min-height: 99px;
      margin-bottom: 36px;
    }
  }

  .mat-mdc-unelevated-button {
    max-width: 202px;
    margin-top: 40px;
  }
}

.schools-only-overlay-panel {
  max-width: 655px !important;
}

.change-password-panel,
.recording-finished-panel {
  max-width: 604px !important;
  max-height: 90vh;
}

.create_group-box.edit-group {
  margin-bottom: 16px;
  background-color: $color-red-500;
}

.create_group-box {
  display: flex;
  background-color: $color-slate-700;
  padding: 32px 40px;
  border-radius: 12px;
  width: 100%;
  margin-top: 15px;

  .h3,
  .paragraph {
    color: #fff;
  }

  .h3 {
    margin-bottom: 4px;
  }

  .edit-group-box-img {
    position: relative;
    max-width: 230px;
    width: 100%;

    img {
      position: absolute;
      top: -30px;
    }
  }

  .create_group-img-wrap {
    position: relative;
    max-width: 437px;
    width: 100%;

    .create_group-img {
      max-width: 437px;
      width: 100%;
      position: absolute;
      top: -64px;
    }

    .more-licenses-img {
      max-width: 437px;
      width: 100%;
      position: absolute;
      top: -35px;
    }
  }

  .mat-unelevated-button.mat-primary-inversion {
    width: unset !important;
    margin-top: 24px;
  }
}

.no_records {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;

  img {
    max-width: 146px;
    width: 100%;
    margin-bottom: 23px;
  }
}

.mat-footer-row {
  border: none;
}

mat-header-row.mat-header-row {
  border: none;
}
app-training-task-create {
  font-family: Manrope !important;
}

app-teacher-create-stepper {
  .training-task_create_headline {
    padding: 72px 60px 0 44px;
    text-align: center;
    margin-bottom: 48px;
  }

  .mat-unelevated-button {
    max-width: 313px;
    width: 100%;
  }

  .personal-info {
    margin-bottom: 28px;
  }

  .last_step_container {
    padding: 0 60px;
  }
}

.padding-input-container {
  padding: 0 2em;

  @media screen and (max-width: 767px) {
    overflow: auto;
    height: 65vh;
  }
}
.label-wrap {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .paragraph {
    color: $color-slate-600;
  }
}

.form_header-headline {
  margin-bottom: 20px;
}

.admin-group-select {
  span.mat-mdc-option .mdc-list-item__primary-text {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
}

.teacher-managing-group-select {
  span.mat-mdc-option .mdc-list-item__primary-text {
    align-items: center;
  }
}

.teacher_with_icon {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  .mat-icon {
    width: 34px !important;
    // min-height: 20px;
  }
  &--blue {
    gap: 0.5em;
    align-items: center;

    .mdc-fab--mini.mat-mdc-mini-fab {
      background-color: #116089;
      width: 20px;
      height: 20px;
      padding: 2px;
    }
  }

  &--gray {
    gap: 0.5em;
    align-items: center;
    justify-content: flex-start;

    .mdc-fab--mini.mat-mdc-mini-fab {
      background-color: $color-slate-350;
      width: 20px;
      height: 20px;
      padding: 2px;
    }
  }

  &.option {
    mat-icon.mat-icon {
      margin-right: 0;
      max-width: 25px !important;
      width: 100%;
    }
    .mdc-fab--mini.mat-mdc-mini-fab .mat-icon {
      padding: 5px;
    }
  }

  &.center-aligned {
    padding-top: 0.25em;
  }

  .teacher_icon {
    justify-content: center;
    align-items: center;
    margin-right: 0 !important;

    mat-icon.mat-icon {
      width: 20px !important;
      height: 18px !important;
    }
  }
}

.dashboard-license-container,
.teacher-managing-selection {
  .teacher_with_icon {
    align-items: center;
    margin-bottom: 0;
  }
}

.teacher_with_icon.empty {
  color: $color-orange-600;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 0px;
}

.button_wrap-last-step {
  display: flex;
  gap: 16px;
  max-width: 646px;
  width: 100%;
  margin-top: 40px;
}

.last_step_container.teacher {
  text-align: center;
  h3 {
    margin-bottom: 24px;
    margin-top: 40px;
    text-align: center;
  }

  .mat-icon {
    color: $color-green-600;
  }
}

.mat-form-field {
  margin-top: 5px;
}

app-teacher-create-stepper {
  .create-training-task-panel .training-task_wrap form {
    min-height: calc(100vh - 190px);
    overflow-y: scroll;
  }
}

.group-select_teachers {
  gap: 12px;
  display: flex;

  .teacher_with_icon--blue .mdc-fab--mini.mat-mdc-mini-fab .mat-icon {
    padding: 5px;
  }
}

.selected-group-container {
  display: flex;
  gap: 6px;

  .selected-group {
    border-radius: 900px;
    background-color: $button-color-lighter;
    padding: 4px 8px;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .paragraph {
    color: $color-b-w-900;
  }

  mat-icon {
    width: 12px !important;
    height: 12px !important;
    color: $color-b-w-900;

    &.selected-teacher {
      width: 22px !important;
      height: 22px !important;
    }
  }
}

.training-task_wrap.teacher {
  overflow-y: auto;
  form {
    /*   min-height: calc(100vh - 190px);
    padding-inline: 2em;
    padding-bottom: 6em; */
  }
}

.school_admin-stepper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .overlay_container {
    background-color: #fff;
    //padding: 60px;
    min-height: 100vh;
    height: 100%;
  }

  form {
    max-width: unset;
    min-height: unset !important;
  }

  .school-admin-info {
    .school-admin-info-value {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .school-admin-info-value.school {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .training-task_select_button {
    width: 100%;
    margin: auto;
  }

  .last_step_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    height: auto;
    justify-content: center;
    background: #fff;
    width: calc(100% + 48px);
    left: -24px;
    padding-bottom: 40px;
  }

  .reviewCheck-section {
    margin-top: 40px;
  }
}

.student-status-chip {
  border-radius: 24px;
  padding: 9px 24px;
  margin-top: 8px;

  &--active {
    background-color: $color-green-100;

    .paragraph {
      color: $color-green-600;
    }
  }

  &--inactive {
    background-color: $color-blue-50;

    .paragraph {
      color: $color-slate-600;
    }
  }

  &--warn {
    background-color: $color-red-500;

    .paragraph {
      color: $color-b-w-0;
    }
  }
}

.student-edit-button-wrap {
  display: flex;
  padding: 20px 0;
  border-bottom: 0.5px solid $color-slate-350;
  margin-bottom: 36px;
  max-width: 438px;
  width: 100%;
  justify-content: space-between;

  .mdc-button.mdc-button--unelevated.mat-tertiary {
    padding-inline: 0.5em;

    &:disabled {
      background-color: transparent !important;
      cursor: not-allowed !important;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 1em;
  }
}

.task-error-overlay-task-panel-backdrop,
.task-start-overlay-task-panel-backdrop {
  background: rgb(0 0 0 / 40%);
}

.student-edit-info {
  width: 100%;
  padding-inline: 3em;
  @media screen and (max-width: 767px) {
    padding-inline: 1.5em;
  }

  .headline {
    margin-bottom: 8px;
  }

  .personal-info-header {
    display: flex;
    gap: 10px;
    justify-content: center;

    .mdc-button {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      gap: 15px;
    }
  }

  .personal-info-teacher-header {
    margin-top: 36px;
  }

  .student-edit-info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 0.5px solid $color-slate-350;

    a {
      font-size: 14px;
      line-height: 19px;
      font-weight: 600;
      text-decoration: underline;
      letter-spacing: 0.01em;
      color: $button-color;
      padding-top: 3px;
    }
  }

  .license-info {
    margin-top: 36px;
  }

  .student-licenses {
    display: flex;
    gap: 8px;
  }
}
.links {
  cursor: pointer;
}
.student-info-footer {
  position: fixed;
  bottom: 0;
  // border-top: 0.5px solid $color-slate-350;
  width: 518px;
  padding: 24px 40px 32px 40px;
  background: #fff;
  display: flex;
  gap: 20px;
  right: 0;

  .inline_text {
    gap: 0;
  }

  @media screen and (max-width: 767px) {
    width: 100vw;
    padding: 2em 1.5em;
  }
}

.delete-button {
  .mat-icon {
    color: $color-red-500 !important;
  }
}

.disable-color {
  color: $color-slate-550 !important;
}

.primary-color {
  color: $color-green-600 !important;
}

.warning-color {
  color: $color-red-500 !important;
}

app-teacher-task-list {
  td.mat-cell.cdk-cell.cdk-column-groupName.mat-column-groupName {
    width: 200px;

    .mat-icon {
      width: 32px !important;
      height: 32px !important;
      margin-right: 15px !important;
    }
  }

  td.mat-cell {
    padding: 14px 0;
  }

  td.mat-column-expand {
    padding-right: 1em;
  }

  .table-inline {
    display: flex;
  }

  .teacher-tasks-wrap {
    height: 100vh;
  }
}

.align-left {
  text-align: left !important;
}

.table-container {
  border-radius: 12px;
  overflow-y: hidden;
  overflow-x: auto;

  &.mat-mdc-table .mat-mdc-cell:first-of-type {
    @media screen and (max-width: 929px) {
      box-shadow: none !important;
      padding-left: 1em;
    }

    /* mat-mdc-cell.mat-column-name {
      @media screen and (max-width: 929px) {
        padding-right: 1em;
        box-shadow: rgba(33, 35, 38, 0.1) 10px 0px 10px -2px;
      }
    } */
  }

  /*  .mat-mdc-table .mat-mdc-cell:first-of-type,
  td.mat-column-username {
    @media screen and (max-width: 929px) {
      padding-right: 1em;
      box-shadow: rgba(33, 35, 38, 0.1) 10px 0px 10px -2px;
    }
  } */
  &.teacher-list {
    .mat-mdc-table .mat-mdc-cell:first-of-type,
    td.mat-column-username {
      min-width: 180px;
    }
    .mat-mdc-table .mat-mdc-cell:last-of-type {
      @media screen and (max-width: 929px) {
        box-shadow: none;
      }
    }
    td.mat-column-group {
      max-width: 150px;
    }
  }

  /* .mat-mdc-table .mat-mdc-cell:last-of-type {
    @media screen and (max-width: 929px) {
      padding-left: 1em;
      box-shadow: rgba(33, 35, 38, 0.1) -5px 0px 10px 0px;

      &.no-shadow {
        padding: 0;
        box-shadow: none;
      }
    }
  } */
}

.license-table {
  .mat-cell.mat-column-group {
    min-width: 60px;
  }
  .mat-cell.mat-column-amount {
    min-width: 25px;

    @media screen and (max-width: 767px) {
      padding-left: 1em;
    }
  }
  .mat-cell.mat-column-type {
    min-width: 120px;
  }

  .mat-cell.mat-column-created,
  .mat-cell.mat-column-status {
    min-width: 95px;
    padding-right: 1em;
  }
  .mat-cell.mat-column-admin {
    min-width: 130px;
  }

  .mat-mdc-table .mat-column-created {
    min-width: 80px;
  }

  .mat-mdc-table .mat-column-status.wider-column {
    min-width: 120px;
  }
}

.license-table .mat-mdc-cell.mdc-data-table__cell p {
  @media screen and (max-width: 767px) {
    padding-left: 1em;
  }
}

.table-wrapper {
  max-height: 100vh;
  width: 100%;
}

.teacher-task-list-container {
  td {
    padding-block: 12px !important;
  }

  td.mat-column-username,
  td.mat-column-teacher {
    min-width: 160px;

    @media screen and (max-width: 929px) {
      /* box-shadow: rgba(33, 35, 38, 0.1) 10px 0px 10px -2px;
      padding-right: 1em; */
    }
  }

  td.mat-column-_group {
    @media screen and (max-width: 929px) {
      padding-inline: 1em;
    }
  }

  td.mat-column-type {
    min-width: 150px;
  }

  td.mat-column-created,
  td.mat-column-deadline {
    min-width: 85px;
  }

  td.mat-column-description {
    min-width: 90px;
    padding-left: 0.5em;
  }

  td.mat-column-book_name {
    min-width: 120px;
    padding-inline: 0.5em;
  }

  button.mat-focus-indicator.mat-menu-trigger.group-more {
    max-width: 27px;
    display: flex;
    max-height: 27px;
    align-items: center;
    justify-content: center;

    mat-icon.mat-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }

  td.mat-mdc-cell:first-of-type {
    padding-left: 12px;
    &.column-hidden-tablet {
      @media screen and (max-width: 929px) {
        display: none !important;
      }
    }
  }

  td.mat-column-button {
    min-width: 70px;
  }

  td.mat-mdc-cell:last-of-type {
    @media screen and (max-width: 929px) {
      /* padding-left: 1em;
      box-shadow: rgba(33, 35, 38, 0.1) -5px 0px 10px 0px; */
    }
  }

  td.mat-cell.cdk-cell.cdk-column-placeholder.mat-column-placeholder {
    //width: 66px;

    .mat-icon {
      margin-left: 10px;
    }
  }

  td.mat-cell.cdk-cell.cdk-column-description.mat-column-description {
    //width: 150px;
    text-align: center;
  }

  td.mat-cell.cdk-cell.cdk-column-expand.mat-column-expand {
    min-width: 66px;
  }
}

.task-detail-container {
  button.mat-focus-indicator.mat-menu-trigger.group-more {
    max-width: 27px;
    display: flex;
    max-height: 27px;
    align-items: center;
    justify-content: center;

    mat-icon.mat-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }

  td.mat-mdc-cell:first-of-type {
    padding-left: 12px;
  }

  td.mat-mdc-cell:last-of-type {
    padding-right: 12px;
    width: 50px;
  }

  td.mat-cell.cdk-cell.cdk-column-username.mat-column-username {
    width: 200px;
  }

  td.mat-cell.cdk-cell.cdk-column-placeholder.mat-column-placeholder {
    width: 66px;

    .mat-icon {
      margin-left: 10px;
    }
  }

  th.mat-header-cell.cdk-header-cell.cdk-column-button.mat-column-button {
    width: 50px;
  }

  td.mat-cell.cdk-cell.cdk-column-reading_combined_score.mat-column-reading_combined_score {
    width: 150px;
    text-align: center;
  }

  td.mat-cell.cdk-cell.cdk-column-reading_errors_score.mat-column-reading_errors_score {
    width: 150px;
    text-align: center;
  }

  td.mat-cell.cdk-cell.cdk-column-reading_speed_words.mat-column-reading_speed_words {
    text-align: center;
    width: 150px;
  }

  td.mat-cell.cdk-cell.mat-column-align--center.cdk-column-complete.mat-column-complete {
    width: 150px;
    text-align: center;
  }

  td.mat-cell.cdk-cell.cdk-column-expand.mat-column-expand {
    width: 66px;
  }

  td.mat-cell.cdk-cell.cdk-column-book_name.mat-column-book_name {
    div {
      width: 290px;

      span {
        padding-left: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        display: inline-block;
      }
    }
  }

  // th.mat-header-cell.cdk-header-cell.mat-column-align--center.cdk-column-completed_at.mat-column-completed_at,
  // td.mat-cell.cdk-cell.cdk-column-completed_at.mat-column-completed_at {
  //     width: 93px;
  // }
}

.active-table {
  th.mat-header-cell.cdk-header-cell.cdk-column-button.mat-column-button {
    max-width: 60px;
  }

  td.mat-cell.cdk-cell.cdk-column-created {
    max-width: 100px;
  }

  td.mat-cell.cdk-cell.cdk-column-deadline {
    max-width: 100px;
  }

  td.mat-cell.cdk-cell.cdk-column-_group {
    min-width: 85px;
  }
}

.finished-table {
  th.mat-header-cell.cdk-header-cell.cdk-column-button.mat-column-button {
    width: 60px;
  }

  th.mat-sort-header.mat-column-completed_at {
    width: 100px !important;
  }

  td.mat-cell.cdk-cell.cdk-column-completed_at.mat-column-completed_at {
    width: 100px;
  }
}

.table-buttons-wrap {
  display: flex;
  gap: 12px;
  justify-content: center;

  .mat-icon-button.mat-filled {
    width: 26px;
  }
}

.done_description {
  display: flex;
  gap: 1em;
  align-items: center;

  p + p {
    margin-top: 1em;
  }
  .mat-icon {
    color: $color-green-500;
  }
}

.star-filled {
  color: $color-orange-600;
}

.star-unfilled {
  color: $color-slate-500;
}

.student_task_chip_header {
  display: flex;
  gap: 12px;

  @media screen and (max-width: 767px) {
    margin-bottom: 1.5em;
    margin-top: 1em;

    &.hidden-mobile {
      display: none;
    }
  }
  &.hidden-tablet {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }
}

.award_img {
  position: relative;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;

  &img {
    max-width: 150px;
    width: 100%;
  }

  .award-description {
    position: absolute;
    bottom: 0;
    background: $color-orange-600;
    padding: 6px 14px;
    border-radius: 100px;
    width: max-content;
    color: #fff;
  }
}

.award_img.disabled {
  .award-description {
    background: $color-slate-550;
  }
}

.school_admin-wrap.overview {
  max-width: 900px;
}

.school_admin-wrap {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 40px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 200px;

  .form-wrapper {
    padding-inline: 2.75em;
    width: 100%;

    .mat-mdc-text-field-wrapper {
      max-width: 238px;
      width: 100%;

      mat-icon.mat-icon {
        margin-block: 12px;
        &.register-icon {
          margin-block: unset;
        }
      }
    }
    .input-flex {
      justify-content: space-between;
      gap: 1em;
      .input-flex-item {
        flex-basis: 50%;
      }
    }

    .full-width .mat-mdc-text-field-wrapper {
      max-width: 100%;
    }
  }

  &--full-height {
    margin-bottom: 250px;
  }

  .logo {
    max-width: 120px;
    width: 100%;
    margin: 0 auto;
  }

  .training-task_select_button {
    border-top: unset;
    border-top: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;

    &.step_1 {
      max-width: 516px;
      margin-left: 1em;
    }

    &.confirm-step {
      bottom: 0;
    }
  }
}

.license-info {
  background-color: $color-blue-50;
  display: flex;
  padding: 16px 20px;
  border-radius: 12px;
  gap: 16px;
  text-align: left;
  margin-top: 40px;

  .mat-icon {
    width: 32px !important;
    height: 32px !important;
    color: $color-blue-600;
  }
}

.confirm_info {
  margin-bottom: 24px;
  width: 100%;
  max-width: 580px;

  .first_row {
    margin-bottom: 20px;
  }

  .confirm_info-box {
    margin-top: 12px;
    background-color: $color-b-w-10;
    border-radius: 12px;
    padding: 20px;
    position: relative;

    .confirm_info-edit-button {
      position: absolute;
      right: 15px;
      z-index: 99;
    }
  }
}

button {
  max-width: 460px;
  width: 100%;
}

.last-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    max-width: 256px;
    width: 100%;
    margin-bottom: 40px;
  }

  .h2 {
    margin-bottom: 20px;
  }

  .paragraph {
    color: $color-slate-600;
    margin-bottom: 2em;
  }
}

.mat-mdc-form-field {
  max-width: unset !important;
  width: 100%;
}

.step-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 120px;

  .step_item {
    margin-bottom: 4px;
  }
}

.step-wrap.active {
  .step_item {
    border: 2px solid $button-color;

    .paragraph {
      color: $button-color;
    }
  }

  .paragraph {
    color: $button-color !important;
  }
}

.step-wrap.finished {
  .number {
    display: none;
  }

  .step_item {
    background-color: $button-color;

    &:after {
      font-family: 'Material Icons';
      content: 'done';
      color: $color-b-w-0;
    }
  }

  .paragraph {
    color: $button-color !important;
  }
}

.stepper_backButton.mdc-button.mdc-button--unelevated.mat-tertiary {
  color: $button-color !important;
  max-width: 150px;
  top: 2em;

  .mat-icon {
    width: 40px !important;
    height: 40px !important;
  }
}

.training-task_container {
  .step_content .training-task_wrap {
    height: 100vh;

    &.create-user {
      height: 85vh;
      overflow: auto;
      max-width: unset;
    }
  }
  .mat-unelevated-button.mat-tertiary {
    position: absolute;
  }
}

img.img-left-top {
  position: absolute;
  max-width: 241px;
  width: 100%;
  z-index: 1;
  top: 100px;
  left: 84px;
}

img.img-right-bottom {
  max-width: 303px;
  width: 100%;
  position: absolute;
  right: 60px;
  bottom: 60px;
  z-index: 1;
}

.footer-img {
  max-width: 192px;
  width: 100%;
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.login_backButton {
  z-index: 9999;
  top: 60px;
  position: absolute;
}

.password-description {
  columns: 2;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: $color-slate-600;
  margin-bottom: 20px;
  margin-top: 15px;
  position: relative;
  top: -10px;
  padding-left: 18px;
}

.change-student-password_wrap {
  .change-student-password-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      max-width: 120px;
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .change-student-password-header.pdf {
    margin-bottom: 40px;
  }

  form {
    margin-top: 3em;
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
  }

  .mat-mdc-text-field-wrapper {
    padding-left: 0;

    .mdc-fab--mini.mat-mdc-mini-fab {
      width: 40px;
      height: 40px;
      background-color: #f6f8fb;

      .mat-icon {
        padding: 0;
      }
    }
  }

  .mat-mdc-form-field-icon-suffix,
  .mat-mdc-form-field-icon-prefix {
    padding-right: 0.5em;
    padding-bottom: 0.25em;
  }

  .instructionVideo_wrap {
    margin-top: 20px;
  }

  .mdc-button.mdc-button--unelevated.mat-primary {
    margin: 0 auto;
  }
}

.student-license-info {
  border-radius: 12px;
  background-color: $color-b-w-0;
  padding: 20px;
  height: 100%;

  .student_chip {
    width: max-content;
    margin-bottom: 4px;
  }

  .license-box-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;

    .headline {
      margin-bottom: 20px;
    }

    .license-box {
      border-radius: 12px;
      background-color: $color-slate-50;
      padding: 12px;
      margin-bottom: 8px;
    }

    .license-box.active {
      background-color: $color-green-200;
    }

    .mat-unelevated-button.mat-primary {
      margin-top: auto;
    }
  }
}

/* book reader */
app-book-reader-view {
  width: 100%;
  margin-right: -4em;
}

.record-row {
  height: 100%;

  @media screen and (max-width: 767px) {
    height: 100vh;
    overflow: hidden;
  }
}

.ds-reader-wrapper {
  height: 100vh;
  position: relative;
  background-color: white;
  width: 95%;
  margin: 0 auto;
  z-index: 0;

  @media screen and (max-width: 767px) {
    height: 85vh;
  }
  .cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
    padding-right: 3em;
  }

  img {
    width: initial !important;
    height: initial !important;
    max-width: 90%;
    max-height: initial !important;
  }

  &--set {
    cursor: pointer;
  }

  // tailwind classes for reader content
  .overflow-scroll {
    overflow-y: scroll !important;
    overflow-x: hidden !important;
  }

  .relative {
    position: relative;
  }

  .indent-0 {
    text-indent: 0px;
  }

  .p-2 {
    padding: 0.5rem;
  }

  .bg-light-gray {
    --tw-bg-opacity: 1;
    background-color: rgb(234 234 234 / var(--tw-bg-opacity));
  }

  .flex-col {
    flex-direction: column;
  }

  .flex {
    display: flex;
  }

  .z-50 {
    z-index: 50;
  }

  .top-full {
    top: 100%;
  }

  .right-0 {
    right: 0;
  }

  .absolute {
    position: absolute;
  }

  .fixed {
    position: fixed;
  }

  .cdk-virtual-scroll-viewport {
    margin-bottom: 40px;
  }
}

app-book-view {
  .row {
    margin-left: 0 !important;
    margin-right: 8px;
  }
  .ds-reader-wrapper {
    height: calc(100vh - 275px);
  }
  ds-table-of-content {
    height: calc(100vh - 190px) !important;
  }
}

ds-context-click {
  & > div {
    position: fixed;
    background-color: #eee;

    ul {
      list-style-type: none;
      padding: 0.5rem 0;

      li {
        padding: 0.5rem 1rem;
      }
    }
  }
}

.license-assign-wrap {
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f8fb;

  @media screen and (max-width: 767px) {
    padding: 0;
    padding-top: 2em;
  }

  .group_headline {
    margin-bottom: 4em;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-bottom: 1em;
    }
  }

  p {
    max-width: 500px;
  }

  .mat-mdc-text-field-wrapper {
    max-height: 50px;
    display: flex;
    padding: 0;
    align-items: center;
    background-color: $color-b-w-0;
  }

  .license-assign-form {
    background-color: $color-b-w-0;
    padding: 1em;
    border-radius: 12px;
    padding-bottom: 0;

    .paragraph--s {
      color: $color-slate-550;
    }
  }
}

.select_students_amount {
  .mat-mdc-text-field-wrapper {
    max-height: 50px;
    display: flex;
    padding: 0;
    align-items: center;
    max-width: 323px;
    margin: 0 auto;
  }
}

.number-wrap {
  display: flex;
  align-items: center;
  padding-inline: 8px;

  input {
    margin: 0 !important;
    border: none !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    text-align: center;
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
    width: unset;
    margin-bottom: 8px;
  }

  .mat-icon {
    color: $color-b-w-900 !important;
  }
}

.disabled-col {
  .paragraph {
    color: $color-slate-400;
  }
}

.license-info-table {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 40px;

  th {
    text-align: left;
    min-width: 150px;
  }

  td {
    min-width: 150px;
  }

  .license-info-item {
    border-bottom: 1px solid $color-slate-500;
    padding: 8px 0;
  }
}

.error {
  color: $color-red-500;
  text-align: center;
}

.ng-invalid {
  border-color: $color-red-500 !important;
}

.training-minutes-line {
  display: flex;
  justify-content: space-between;

  .training-minutes-wrap {
    display: flex;

    .minutes-left {
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      margin-right: 3px;
    }
  }

  .warn {
    color: $color-red-500;
  }

  .medium {
    color: $color-orange-600;
  }
}

.license-box.training-available {
  background: $color-green-50 !important;

  .minutes-left {
    color: $color-green-600;
  }
}

.license-box.training-warning {
  background: $color-yellow-100 !important;

  .minutes-left {
    color: $color-b-w-900;
  }
}

.license-box.training-alarm {
  background: $color-red-50 !important;

  .minutes-left {
    color: $color-red-500;
  }
}

.admin-teacher-edit_wrap,
.admin-change-password_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 1em;
  margin-top: 20px;
  border-radius: 12px;
  background-color: $background-white;

  form {
    max-width: 100%;
    margin: 0;
  }

  .mat-mdc-form-field {
    width: 100%;

    .mdc-text-field__input {
      padding-left: 0.5em;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }
  form {
    @media screen and (max-width: 767px) {
      margin-top: 1em;
    }
  }

  .input--mid {
    max-width: 250px;
    @media screen and (max-width: 767px) {
      max-width: unset;
    }

    .mat-mdc-text-field-wrapper mat-icon.mat-icon {
      margin-block: 12px;
    }
  }
  .mat-mdc-unelevated-button.mat-secondary {
    margin-left: 0;
  }

  .close-button--right {
    right: 35px !important;
  }

  .teacher-edit-img,
  .change-password-img {
    width: 100%;
    margin-bottom: 15px;
  }

  .teacher-edit-img {
    max-width: 129px;
  }

  .change-password-img {
    max-width: 156px;

    @media screen and (max-width: 767px) {
      max-width: 80px;
    }
  }
  .instructionVideo_wrap {
    @media screen and (max-width: 767px) {
      margin-top: 1em;
    }
  }
}

.teacher-edit_wrap,
.change-password_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  //padding: 2em;
  background-color: #f6f8fb;

  .mat-mdc-form-field {
    width: 100%;

    .mdc-text-field__input {
      padding-left: 0.5em;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }
  form {
    @media screen and (max-width: 767px) {
      margin-top: 1em;
    }
  }

  .input--mid {
    max-width: 250px;
    @media screen and (max-width: 767px) {
      max-width: unset;
    }

    .mat-mdc-text-field-wrapper mat-icon.mat-icon {
      margin-block: 12px;
    }
  }
  .mat-mdc-unelevated-button.mat-secondary {
    margin-left: 0;
  }

  .close-button--right {
    right: 35px !important;
  }

  .teacher-edit-img,
  .change-password-img {
    width: 100%;
    margin-bottom: 15px;
  }

  .teacher-edit-img {
    max-width: 129px;
  }

  .change-password-img {
    max-width: 156px;

    @media screen and (max-width: 767px) {
      max-width: 80px;
    }
  }
  .instructionVideo_wrap {
    @media screen and (max-width: 767px) {
      margin-top: 1em;
    }
  }
}

.hide-password.mdc-fab.mat-mdc-fab {
  background-color: transparent !important;
  box-shadow: none;
  width: 50px;
  height: 50px;
  margin-right: 5px;
}

.awards-hover-box {
  background-color: $color-b-w-0;
  padding: 16px;
  border-radius: 10px;
  box-shadow:
    0px 18px 88px -4px rgba(24, 39, 75, 0.14),
    0px 8px 28px -6px rgba(24, 39, 75, 0.12);
  position: absolute;
  top: -85px;

  .paragraph--bold {
    margin-bottom: 6px;
  }
}

.hiddenCol {
  display: none;
}

.student-report-container {
  width: 100%;
  padding-bottom: 15px;

  .report_card__headline {
    margin-bottom: 10px;
  }

  .diagram-header {
    margin-bottom: 8px;
  }

  &-inside-height {
    height: 100%;
    overflow: hidden;
  }
}

.headline-info-tooltip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.warn-button {
  color: #ff6600 !important;

  mat-icon {
    color: #ff6600 !important;
  }
}

.buttons-below {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 24px;
}

.no_records.dashboard {
  height: calc(100vh - 275px);
}

.mdc-list-item--disabled {
  background-color: $color-slate-200;

  .paragraph {
    color: $color-slate-600;
  }
}

.teacher-selection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

mat-option.mat-mdc-option.mdc-list-item {
  padding-block: 0.3em;
  //border-bottom: 1px #d3d3d378 solid;
}

.teachers-group {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 60%;
  justify-content: flex-end;

  .paragraph {
    color: $color-slate-600;
  }

  .paragraph:last-child span {
    display: none;
  }
}

.teacher-selection-overlay-panel-backdrop {
  background: rgb(0 0 0 / 40%);
}

.teacher-selection-info {
  display: flex;
  flex-direction: column;
  align-items: center;

  .mat-icon {
    width: 120px !important;
    height: 120px !important;
    margin-bottom: 26px;
    color: $color-slate-700;
  }

  .teacher-selection-info_button {
    margin-top: 2em;
    gap: 1em;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .h3 {
    text-align: center;
  }

  form {
    max-width: 100%;

    .paragraph {
      margin: 20px 0;
      text-align: center;
    }
  }
  .mat-mdc-form-field {
    width: 100%;
  }
}

.table_group {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;

  @media screen and (max-width: 812px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .link {
    margin-right: 5px;
  }

  .link:last-child span {
    display: none;
  }

  span {
    color: $color-b-w-800;
  }

  .class_chip {
    border: 1px solid $color-b-w-200;
    border-radius: 24px;
    padding: 3px 10px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;

    &.teacher_dashboard {
      display: flex;
      align-items: center;
      gap: 5px;
      font-weight: unset;
      font-size: 13px;
      min-width: 113px;

      mat-icon.mat-icon {
        margin-left: -6px;
      }
    }
  }
}

.router-link {
  cursor: pointer;
}

.student_tabs_row {
  margin-top: 32px;
}

.book_list-container {
  margin-inline: 2em;
  overflow-y: auto;

  @media screen and (max-width: 767px) {
    height: calc(100vh - 170px);
    margin-inline: 1em;
  }
}

.admin-chip {
  font-size: 9px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.45px;
  padding: 4px 7px;
  border-radius: 25px;
  background: $color-gradient-06;
  color: $color-b-w-0;
  align-self: center;
}

.admin-icon {
  display: flex;
  position: relative;

  .admin-chip-mobile {
    font-size: 9px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    padding: 3px 6px;
    border-radius: 50%;
    background: $color-gradient-06;
    color: $color-b-w-0;
    position: absolute;
    top: 0;
    right: 5px;
    margin: 0;
    z-index: 10;
  }

  .mat-icon {
    min-width: 36px !important;
    min-height: 37px !important;
  }
}

.book_info_wrap-cover.ng-star-inserted {
  background-image: url('./assets/task-preview.png');
  background-color: $background-white;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 100%;
  height: calc(100vh - 89px);

  .book_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  img {
    max-width: 350px;
    width: 100%;
  }
}

.pause-col {
  padding: 0 !important;
}

.finished-step.create-group {
  .last_step_container {
    height: calc(100vh - 250px);
  }

  .mat-unelevated-button.mat-primary {
    max-width: 315px;
    margin-top: 60px;
  }
}

.training-task_wrap {
  .book-list-filter {
    margin: 0 30px;
  }
}

.book_list-container {
  .book-list-filter {
    max-width: 800px;
    margin: auto;
    width: 100%;

    .col-md-12.full-width {
      padding-inline: 0;
    }
  }
}
app-group-create-stepper {
  .step_padding {
    padding: 1em !important;
  }
}

app-students-add-stepper,
app-group-create-stepper {
  .step_padding {
    padding: 3em;
    padding-right: 4em;

    &--height-full {
      height: 100vh;
    }

    &--scroll {
      overflow: auto;
      height: 100vh;
    }
  }
  .create-training-task-panel .training-task_wrap form {
    min-height: calc(100vh - 275px);
  }

  .training-task_select_container {
    display: flex;
    flex-direction: column;

    &.add_learners_details {
      height: calc(100vh - 166px) !important;

      .training-task_create_headline {
        margin-block: unset;
      }
    }
  }

  .training-task_selector.active {
    border: 1px solid $color-blue-600;
  }

  .training-task-stepper-col {
    padding: 0;
  }

  .training-task_container {
    z-index: 99999;
  }

  .selected-group-name {
    // width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 56px;
    background: $color-blue-50;
    border-radius: 10px;
    padding: 4px 12px;

    @media screen and (max-width: 767px) {
      margin-bottom: 1em;
    }

    .h3 {
      background: $color-blue-50;
      padding: 8px 12px;
      border-radius: 12px;
    }
  }

  .select_students_number {
    .mat-form-field.mat-form-field--number-action {
      width: 100%;

      .mat-mdc-form-field-infix {
        display: flex;
      }

      .number-field {
        display: flex;
      }

      .input-placeholder {
        display: flex;
        align-items: center;

        .mat-icon {
          margin-left: 9px;
        }
      }
    }
  }

  .children_row {
    background: #f6f8fb;
    border-radius: 16px;
    padding: 24px 24px;
    margin-bottom: 16px;
  }

  .gender-selection {
    display: flex;
    flex-direction: column;

    mat-label {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: #5b7385;
    }

    mat-radio-group.mat-radio-group {
      display: flex;
      gap: 16px;
    }
  }

  .gender-index {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    background: #116089;
    border-radius: 100px;
    width: 28px;
    height: 28px;
  }

  .training-task_create_headline {
    margin-block: 48px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      margin-bottom: 1em;
    }

    .h3 {
      margin-bottom: 0;
    }

    .paragraph.paragraph--xl {
      margin-top: 16px;
    }
  }

  mat-label {
    color: $color-b-w-900;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }

  .group_headline {
    display: flex;
    margin-bottom: 24px;
    align-items: flex-end;

    .paragraph {
      color: $color-b-w-900 !important;
    }
  }

  .finished-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 80vh;
    padding: 2em;

    @media screen and (max-width: 767px) {
      padding-left: 4em;
      height: 60vh;
      width: 95%;
    }
  }

  .finished-step .pdf-info {
    text-align: center;
  }

  .h3 {
    margin-bottom: 16px;
  }
}

.children_row {
  background: #f6f8fb;
  border-radius: 16px;
  padding: 24px 20px;
  margin-bottom: 16px;
  position: relative;
}

.training-task_create_headline.create-group {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
  }

  .mat-unelevated-button.mat-primary {
    max-width: 136px;

    @media screen and (max-width: 1100px) {
      margin-top: 30px;
    }
  }

  button.import-csv-btn {
    max-width: 200px;
  }

  .group-gender-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .gender_header {
    gap: 20px;
    max-width: unset;
    margin-bottom: 0;

    .mat-icon {
      margin-right: 0px !important;
    }
  }
}

.group_row {
  width: 95%;
  margin-left: auto;
  margin-right: 0;

  mat-label {
    color: #5b7385 !important;
  }

  .mat-radio-outer-circle {
    border-color: $color-b-w-900 !important;
  }

  .mat-radio-button {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    span.mat-radio-label-content {
      display: flex;
      gap: 4px;
    }

    .mat-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

.group_row_number {
  position: absolute;
}

.create-group-step {
  .training-task_create_headline {
    margin-bottom: 32px;
  }
}

.table-username {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-warning-circle {
  color: $color-red-500;
  width: 20px !important;
  height: 20px !important;
}

.training-task_select_container.edit-group {
  @media screen and (max-width: 767px) {
    height: inherit;
  }

  .training-task_create_headline {
    margin-bottom: 32px;

    .h3 {
      margin-bottom: 0;
    }
  }

  .group-info {
    text-align: center;

    mat-icon {
      margin-right: 0 !important;
      width: 20px !important;
      height: 20px !important;
    }
  }

  .gender_header {
    display: flex;
    max-width: 100%;
    margin-top: 8px;
    margin-bottom: 32px;
    justify-content: center;
    gap: 20px;
  }

  .gender-index {
    display: flex;
    margin-bottom: 15px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    background: #116089;
    border-radius: 100px;
    width: 28px;
    height: 28px;
  }

  .gender-selection {
    display: flex;
    flex-direction: column;

    mat-label {
      margin-bottom: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 160%;
      color: #5b7385;
    }

    mat-radio-group.mat-radio-group {
      display: flex;
      gap: 16px;
    }
  }

  .username-edit {
    margin-bottom: 20px;
  }

  .mat-form-field {
    margin-bottom: 20px;
  }
}

.finished-step.edit-group {
  display: flex;
  text-align: center;
  justify-content: center;
  height: 80vh;
  margin: 3em;

  .mdc-button.mdc-button--unelevated.mat-primary {
    margin-top: 2em;
  }
}

.managing-create-passwords {
  margin-top: auto;
  margin-bottom: 40px;
  .mdc-form-field > label {
    margin-top: 5px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 2em;
  }
}
.finished-table {
  th.mat-header-cell.cdk-header-cell.cdk-column-button.mat-column-button {
    max-width: 50px;
  }

  th.mat-sort-header.mat-header-cell.cdk-header-cell.mat-column-align--center.cdk-column-description.mat-column-description {
    max-width: 142px;
  }

  td.mat-cell.cdk-cell.cdk-column-description.mat-column-description {
    max-width: 142px;
  }
}

.select-teacher-form,
.student-amount-form {
  min-height: calc(100vh - 224px) !important;
}
.group-create-form {
  min-height: calc(100vh - 224px) !important;
  max-width: 530px !important;
  margin: 0 auto;
  .mat-mdc-form-field {
    margin-top: 10px !important;
  }
}

app-group-create-stepper {
  .student-amount-form {
    min-height: calc(100vh - 291px) !important;
    max-width: 590px !important;
    margin: 0 auto;
    .student_selection_wrap {
      background: $color-b-w-10;
      padding: 24px;
      border-radius: 12px;
      text-align: center;
      margin-bottom: 15px;
    }
  }

  /* .training-task_select_container.group-type {
        height: calc(100vh - 166px) !important;
    }

    .training-task_select_container {
        height: calc(100vh - 246px) !important;
    } */
}

app-students-add-stepper {
  .training-task_select_container {
    min-height: calc(100vh - 246px) !important;
  }
}

.deadline_wrap {
  margin-bottom: -10px;

  @media screen and (max-width: 767px) {
    margin-bottom: -15px;
    max-width: 240px;
    width: 100%;
  }

  .paragraph {
    margin-bottom: 0.25em;
  }
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 52px;
    height: 52px;
  }
}

.task_summary-headline {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.task_overview-chip-wrap {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  .task_overview-chip {
    border-radius: 900px;
    background: #f0f7fe;
    padding: 4px 8px !important;
    width: fit-content;
    display: flex;
    gap: 8px;
    align-items: center;
    margin-top: 20px;

    &.with-shadow {
      box-shadow: 2px 2px 5px lightgray;
    }

    mat-icon.mat-icon {
      width: 16px !important;
      height: 16px !important;
    }

    .user-img {
      display: flex;
      gap: 8px;
      align-items: center;

      img {
        width: 24px;
        height: 24px;
      }

      span {
        font-weight: 400;
      }
    }
  }
}

.book-preference-button {
  background-color: #e1edff !important;
  color: #3e61df !important;
  border-radius: 30px !important;
  max-width: 182px;
}

.microphone-check-overlay-panel_backdrop,
.books-preferences-overlay-panel_backdrop {
  background: rgb(0, 0, 0, 0.4);
}

.book-preferences-container {
  .book-preferences-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }

    .start-img {
      margin-bottom: 16px;
      max-width: 450px;
    }

    .preference-img {
      margin-bottom: 40px;
      max-width: 153px;
    }

    @media screen and (max-width: 1080px) {
      .preference-img {
        margin-bottom: 20px;
        max-width: 140px;
      }
    }

    .preferences-selection-wrap {
      margin-top: 40px;
    }

    .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
      display: flex;
      gap: 0.5em;
      justify-content: center;
      margin-bottom: 2em;

      @media screen and (max-width: 767px) {
        justify-content: flex-start;
      }

      .mat-mdc-chip.mat-mdc-chip-option {
        cursor: pointer;
        background: #e1edff;
        padding: 8px 16px;
        border-radius: 30px;
        font-size: 1.15em;
        font-weight: 500;
        height: 2.25em;
        color: #232323;

        img {
          width: 30px;
          height: auto;
          margin-right: 14px;
        }

        &.active {
          background: $color-blue-600;
          .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
            color: $color-b-w-0;
          }
        }
      }

      .mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
        display: flex;
        align-items: center;
      }
    }
    .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic
      .mdc-evolution-chip__graphic {
      display: none;
    }
  }

  .mdc-button.mdc-button--unelevated.mat-primary-student {
    padding-inline: 2em;
    margin-top: 2em;

    @media screen and (max-width: 767px) {
      align-self: center;
    }
  }
}

@media screen and (max-width: 1080px) {
  .mdc-button.mdc-button--unelevated.mat-primary-student {
    margin-top: 0 !important;
  }
}

mat-option.mdc-list-item.mdc-list-option {
  .inline_text {
    gap: 12px;
  }

  .avatar_img--small {
    width: 31px;
    height: 31px;
    margin-right: 0;
    margin-left: 16px;
  }
}

.teacher-create-container {
  padding-left: 2em;
  padding-right: 2.5em;
}

.teacher_edit_overlay_container {
  padding-top: 60px;

  form {
    max-width: unset;
  }

  .personal-info {
    height: calc(100vh - 266px);
  }
}

input {
  font-weight: 400 !important;
}

.notification-menu {
  max-width: 520px !important;
  padding: 30px !important;
  border-radius: 18px !important;
  max-height: 575px !important;
}

.notification-wrap:last-child {
  margin-bottom: 0;
}

.notification-wrap {
  background: $color-slate-50;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px;

  .student_chip.student_chip__teacher {
    background: #fff;
  }
  .message-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .message-container {
    background-color: $color-b-w-0;
    padding: 16px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .message-info {
    margin-top: 20px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.task-error-overlay-task-panel {
  .task-error-overlay_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 484px;
    margin: 0 auto;
    text-align: center;

    img {
      max-width: 126px;
      width: 100%;
      margin-bottom: 40px;
    }

    .h3 {
      margin-bottom: 16px;
    }

    .view_button {
      margin-top: 40px;
      min-width: 234px;
    }
  }
}

.task-completed-overlay-task-panel {
  .task-completed-overlay_container,
  .task-error-overlay_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 484px;
    margin: 0 auto;
    text-align: center;

    img {
      max-width: 106px;
      width: 100%;
      margin-bottom: 16px;
    }

    .h3 {
      margin-bottom: 16px;
    }

    .view_button {
      margin-top: 40px;
      min-width: 234px;
    }
  }
}

.book-complexity-rating-overlay-panel {
  .mat-mdc-dialog-container {
    position: relative;
    background: $color-b-w-10;
    box-shadow:
      0px 8px 28px -6px rgba(24, 39, 75, 0.12),
      0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    border-radius: 16px;
    max-width: 756px;
    margin: 0 auto;
    padding: 60px;
    background-image: url('./assets/book-rating.svg');
    background-repeat: no-repeat;
  }

  .book-complexity-rating-overlay_container {
    .rating-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 56px;
    }

    .rating-header {
      color: $color-slate-700;
      text-align: center;
    }

    .view_button {
      display: flex;
      gap: 16px;
      margin-top: 32px;
    }

    .mat-mdc-form-field {
      padding-bottom: 0 !important;
    }

    textarea {
      min-height: 118px;
    }

    .star-rating {
      display: flex;
      gap: 8px;
      margin-top: 20px;
      margin-bottom: 20px;

      mat-icon.mat-icon {
        width: 76px !important;
        height: 76px !important;
        color: $color-slate-350;
      }

      mat-icon.mat-icon.active {
        color: $color-orange-600;
      }

      mat-icon.mat-icon.hidden {
        display: none;
      }

      .star-wrap {
        min-width: 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .paragraph {
          color: $color-slate-550;
          text-align: center;
        }
      }
    }
  }
}

.paginator-container {
  width: 100%;
  .mat-mdc-paginator-container {
    background-color: #f1f3f6;
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.logout-button {
  color: $color-red-500;

  .mat-icon {
    color: $color-red-500;
  }
}

//OVERRIDES MATERIAL V15 PRESETS
.rounded_tabs {
  .mat-mdc-tab-labels .mdc-tab.mat-mdc-tab {
    background-color: #f0f7fe;
    border-radius: 30px !important;
    color: #5f798c;
    max-width: 15em;
    margin-right: 2em;
    font-weight: 700;
    margin-bottom: 1em;
    font-size: 1em;

    .mdc-tab-indicator .mdc-tab-indicator__content--underline {
      display: none;
    }

    &.mdc-tab--active span.mdc-tab__text-label {
      color: white;
      border-radius: 30px;
    }
  }
  .mat-mdc-tab-labels .mdc-tab.mat-mdc-tab.mdc-tab--active {
    background-color: $button-color;
  }
  .mat-mdc-tab-labels .mdc-tab.mat-mdc-tab.mdc-tab--inactive:hover {
    background-color: $button-color-bright;
  }

  &--student .mat-mdc-tab-labels {
    margin-left: 2em;

    @media screen and (max-width: 767px) {
      margin-left: 0.5em;

      .mdc-tab.mat-mdc-tab {
        margin-right: 0.5em;
      }
    }

    .mdc-tab.mat-mdc-tab.mdc-tab--active {
      background-color: $color-blue-600;
    }
  }

  @media screen and (max-width: 767px) {
    mat-tab-header.mat-mdc-tab-header {
      margin-inline: -12px;

      &.mat-ripple.mat-mdc-tab-header-pagination {
        margin-bottom: 15px;
      }
    }
  }
}

.student_tabs mat-ink-bar.mat-ink-bar {
  display: none;
}

.license-tabs-wrap {
  margin-top: 40px;

  .toggle-tabs {
    .mat-mdc-tab-labels {
      max-width: 300px;
    }
    .mdc-tab__content .mdc-tab__text-label {
      font-size: 1.2em;
    }
  }

  .mat-mdc-row.mdc-data-table__row.example-element-row {
    height: 89px;
  }
}

.request-chip {
  padding: 5px 10px;
  border-radius: 24px;
  text-align: center;

  &--requested {
    background-color: $color-blue-50;
    color: $color-slate-600;
  }

  &--accept {
    background-color: $color-green-100;
    color: $color-green-600;
  }

  &--decline {
    background-color: $color-red-100;
    color: $color-red-500;
  }
}

.status-chip {
  padding: 5px 10px;
  border-radius: 24px;
  text-align: center;

  &--active {
    background-color: $color-green-100;
    color: $color-green-600;
  }

  &--inactive {
    background-color: $color-blue-50;
    color: $color-slate-600;
  }

  &--warn {
    background-color: $color-red-100;
    color: $color-red-500;
  }
}

app-dashboard-license {
  .mat-mdc-tab-body-content {
    overflow: hidden;
  }

  .table-container {
    margin-top: 2em;
    border-radius: 12px;
  }
}

.license-accept-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .license-info {
    margin-top: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .group_headline {
    align-items: center;
  }

  .license-info-box {
    margin-top: 16px;
    padding: 24px;
    background: $color-b-w-0;
    border-radius: 12px;
    width: 100%;
  }

  .license-info-box-header {
    display: flex;
    justify-content: space-between;
  }

  .license-info-box-content {
    display: flex;
    justify-content: space-between;
  }

  .license-content {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .license-teacher {
    display: flex;
    gap: 8px;

    .mat-icon {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .license-amount {
    color: $color-slate-500;
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .license-input {
    margin-top: 12px;
    position: relative;
  }

  .info_headline {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;

    mat-icon.mat-icon {
      width: 132px !important;
      height: 132px !important;
      color: $color-green-600;
      margin-bottom: 20px;
    }

    .h3 {
      margin-bottom: 20px;
    }
  }

  .info_headline.decline {
    mat-icon.mat-icon {
      color: $color-red-500;
    }
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    max-width: 300px;
    width: 100%;

    .license-amount {
      position: unset !important;
      flex-basis: 90%;
    }

    .mat-mdc-form-field-infix {
      display: flex;
      align-items: center;
    }

    .mat-mdc-form-field-flex {
      width: 100%;
    }

    .mat-mdc-text-field-wrapper {
      margin-top: 0.25em;
    }

    .mat-mdc-input-element.mat-mdc-form-field-input-control.mdc-text-field__input {
      padding-left: 0.5em;
    }
  }
  .button-wrap {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    width: 100%;

    .mat-unelevated-button.mat-primary {
      max-width: 310px;
    }
  }

  .primary {
    color: $color-green-700;
  }

  .license-warn-box {
    background-color: $color-yellow-100;
    padding: 16px 20px;
    display: flex;
    gap: 16px;
    border-radius: 12px;
    margin-top: 16px;

    .mat-icon {
      width: 32px !important;
      height: 32px !important;
      color: $color-orange-600;
    }
  }

  .mat-form-field.mat-form-field {
    margin-top: 16px;

    .mat-mdc-form-field-infix {
      position: absolute;
      left: 0;
      top: 0;
      padding: 0;
      width: 100%;
    }

    input {
      border: unset;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-form-field {
      padding-bottom: 0;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-mdc-text-field-wrapper {
      height: 50px;
    }
  }

  .decline-license-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    button {
      flex-basis: 48%;
    }
    button:first-of-type {
      margin-left: 0;
    }
  }

  .warning {
    color: $color-red-500;
  }
}

.mat-mdc-form-field.custom-input {
  .mat-mdc-form-field-infix {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    width: 100%;
  }

  input {
    border: unset;
    margin-top: 0 !important;
    height: 55px !important;
  }

  .mat-mdc-form-field {
    padding-bottom: 0;
  }

  .mat-mdc-text-field-wrapper {
    height: 55px;
  }
}

.license-transfer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4em 2em 2em;

  @media screen and (max-width: 767px) {
    padding: 0;
    padding-top: 2em;

    .h3.h3--bold {
      text-align: center;
    }
  }

  form {
    max-width: unset;
  }

  .mat-mdc-text-field-wrapper {
    background: $color-b-w-0;
  }

  .mat-mdc-form-field {
    width: 380px;
    width: 100%;
  }

  .group_headline {
    margin-bottom: 40px;

    .mat-icon {
      width: 36px !important;
      height: 37px !important;
    }
  }

  .headline {
    margin-bottom: 20px;
  }

  .mat-mdc-text-field-wrapper {
    max-height: 50px;
    display: flex;

    align-items: center;
    background-color: $color-b-w-0;
  }

  .licenses-box {
    background-color: $color-b-w-0;
    padding: 1em;
    border-radius: 12px;
    padding-bottom: 0;

    .mat-mdc-form-field {
      width: 100%;
    }

    .paragraph--s {
      color: $color-slate-550 !important;
    }
  }

  .buttons_wrap {
    display: flex;
    gap: 20px;
    margin-top: 40px;
  }
}

.round-checkbox-22 {
  .mdc-checkbox .mdc-checkbox__background {
    width: 22px !important;
    height: 22px !important;
  }
  .mat-mdc-checkbox .mdc-checkbox {
    margin-bottom: 0 !important;
  }
}

//ROUND CHECKBOX STANDARD
.round-checkbox {
  .mdc-list-item__start.mat-mdc-list-option-checkbox-before,
  .mat-mdc-checkbox .mdc-form-field {
    .mdc-checkbox .mdc-checkbox__background .mdc-checkbox__checkmark {
      color: white !important;
      padding: 3px;
    }
    .mdc-checkbox .mdc-checkbox__background {
      border-radius: 50%;
      width: 28px;
      height: 28px;
    }
  }

  .mat-mdc-checkbox {
    .mdc-checkbox .mdc-checkbox__background {
      top: calc(
        (var(--mdc-checkbox-state-layer-size, 46px) - 28px) / 2
      ) !important;
      left: calc(
        (var(--mdc-checkbox-state-layer-size, 46px) - 28px) / 2
      ) !important;
    }

    .mdc-checkbox__ripple {
      width: 46px;
      height: 46px;
      color: $button-color-lighter;
    }
    .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
      top: calc(
        (
            var(--mdc-checkbox-state-layer-size, 46px) - var(
                --mdc-checkbox-state-layer-size,
                46px
              )
          ) /
          2
      );
      right: calc(
        (
            var(--mdc-checkbox-state-layer-size, 46px) - var(
                --mdc-checkbox-state-layer-size,
                46px
              )
          ) /
          2
      );
      left: calc(
        (
            var(--mdc-checkbox-state-layer-size, 46px) - var(
                --mdc-checkbox-state-layer-size,
                46px
              )
          ) /
          2
      );
      width: var(--mdc-checkbox-state-layer-size, 46px);
      height: var(--mdc-checkbox-state-layer-size, 46px);
    }
  }

  &.no-checkmark.mat-mdc-list-item
    .mdc-list-item__start.mat-mdc-list-option-checkbox-before
    .mdc-checkbox
    .mdc-checkbox__background
    .mdc-checkbox__checkmark {
    display: none;
  }

  &.small {
    .mdc-list-item__start.mat-mdc-list-option-checkbox-before
      .mdc-checkbox
      .mdc-checkbox__background {
      width: 20px;
      height: 20px;
    }
    .mat-mdc-list-option
      .mdc-checkbox
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background {
      background-color: $button-color !important;
      border: 1px solid $button-color !important;
    }
  }

  .mat-mdc-checkbox .mdc-checkbox {
    margin-bottom: 0.25em;

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
        [data-indeterminate='true']
      )
      ~ .mdc-checkbox__background {
      border-color: $color-slate-550;
      margin-bottom: 0.75em;
    }

    &.mat-mdc-list-item.mat-mdc-list-option
      .mdc-checkbox__native-control:enabled:checked
      ~ .mdc-checkbox__background {
      background-color: #fff !important;
      border: 5px solid $button-color !important;
    }
    .mdc-checkbox__native-control:enabled:indeterminate
      ~ .mdc-checkbox__background,
    .mdc-checkbox__native-control[disabled]:indeterminate
      ~ .mdc-checkbox__background {
      background-color: $button-color;
      border: 5px solid $button-color !important;
    }

    .mdc-checkbox__native-control:enabled
      ~ .mdc-checkbox__background
      .mdc-checkbox__mixedmark,
    .mdc-checkbox__native-control[disabled]
      ~ .mdc-checkbox__background
      .mdc-checkbox__mixedmark {
      border-color: white !important;
      width: 95%;
      border-width: 1px;
    }
  }
  .mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-hover-icon-color: #13794f !important;
  }
  &.not-laletu {
    .mat-mdc-checkbox.mat-accent {
      --mdc-checkbox-selected-hover-icon-color: #003366 !important;
    }
  }
}

.diagnostic-date-changer {
  display: flex;
  gap: 20px;
  margin-top: 40px;
  align-items: center;

  .mat-icon {
    color: $color-slate-550;
  }
  .mat-mdc-checkbox .mdc-checkbox {
    margin-bottom: 0.25em;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
}

.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: $button-color !important;
}

.last_step_container.edit {
  .mat-mdc-unelevated-button.mat-primary {
    width: 200px;
    margin-top: 40px;
  }
}

.deadline_tooltip-wrap {
  display: flex;
  gap: 20px;

  .mat-icon {
    color: $color-slate-550;
  }
}

.archivments-panel {
  max-width: 1020px !important;
  overflow-y: scroll;
  height: 96vh;
  @media screen and (max-width: 1080px) {
    max-width: 88vw !important;
  }

  .mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.paswords-warn-panel {
  .mat-mdc-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgb(24 39 75 / 12%),
      0px 18px 88px -4px rgb(24 39 75 / 14%);
    border-radius: 12px;
    max-width: 604px !important;
    margin: 0 auto;
    width: 100%;
    position: relative;
  }
  .passwords-warn_container {
    padding: 60px;
  }
  .passwords-warn_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      max-width: 120px;
      width: 100%;
      margin-bottom: 26px;
    }
  }

  .button-wrap {
    display: flex;
    gap: 16px;
    margin-top: 40px;
    width: 100%;
  }
}

.record-rtc_result_overlay_wrap.norecords {
  display: flex;
  flex-direction: column;
  align-items: center;

  .h3 {
    margin-bottom: 40px;
  }
}

.inactive-status-text {
  margin-left: 10px;
}

.expired-header-chips {
  display: flex;
  gap: 10px;

  .student_chip__deadline {
    .mat-icon {
      color: $color-slate-500;
    }
  }
}

img.cat_mobile {
  display: none;
}

img.speech_bubble_img {
  position: relative;
  top: 30px;
}

.number-mobile {
  display: none;
}

/* === PARENTS LANDING PAGE === */

.for-parents-bg-color {
  background-color: #f6f8fb;
}

.for-parents-container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;

  @media only screen and (max-width: 1199px) {
    margin-inline: 1em;
  }

  @media only screen and (max-width: 767px) {
    margin-inline: 0;
  }
}

.for-parents-bg-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 767px) {
    position: relative;
    background-image: url('./assets/for-parents-leaves-left.png');
    background-repeat: no-repeat;
    padding-top: 2em;
  }
}

.for-parents-bg-img-right {
  position: absolute;
  top: 4%;
  right: 0;
  z-index: 0;
  height: 390px;
  width: auto;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.for-parents-header-wrap {
  color: #1a1a1a;
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Manrope;
  padding: 0 2em;
  display: flex;
  text-align: left;
  align-items: center;
  max-width: 1175px;
  gap: 2em;
  z-index: 8;
  position: relative;

  @media screen and (max-width: 1024px) {
    display: block;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }

  .title {
    flex-basis: 50%;
    color: #1a1a1a;
    padding-right: 4em;

    @media only screen and (max-width: 1024px) {
      margin-inline: 2em;
    }

    @media only screen and (max-width: 767px) {
      padding-right: 0;
    }
  }

  h1 {
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 0.5em;
    line-height: 1.3em;
    font-family: 'Manrope', sans-serif;

    @media screen and (max-width: 1200px) {
      font-size: 2.25em;
    }
  }

  h4 {
    font-family: 'POLOGSV-BUCH';
    font-size: 1.25em;
    font-weight: 200;
    line-height: 30px;
    color: #707070;
  }
}

/* FOR PARENTS FORM  */
.for-parents-form-bg {
  background-color: #fff;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  flex-basis: 50%;
}

.input-flex {
  display: flex;
  gap: 1em;

  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.for-parents-form-wrapper {
  padding: 0em 2em 2em;
  font-family: 'POLOGSV-BUCH';

  @media only screen and (max-width: 1199px) {
    padding: 1em;
    margin-top: 1em;
  }

  .col-md-12 {
    padding-inline: 0 !important;
  }

  form {
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media screen and (max-width: 1200px) {
      display: block;
      padding: 1em;
      margin: 0;
    }

    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: -2em;
    }

    .btn-wrapper {
      width: 100%;

      button {
        margin: 0;
        margin-bottom: 1.34375em;
        font-family: 'POLOGSV-BUCH';

        &.mdc-button.mdc-button--unelevated.mat-primary:disabled {
          background-color: #5b5b66 !important;
          opacity: 0.3;

          mat-icon.mat-icon {
            background-color: #5b5b66;
            color: white;
          }
        }
        span.inline_text {
          gap: 0;
        }
      }
    }
  }

  .mat-form-field {
    padding: 0 !important;
    border-radius: 0 !important;
    width: 100%;

    &-lastname {
      margin-left: 1em;
    }

    &-email {
      margin-left: 1em;
      margin-right: 1em;
    }

    @media only screen and (max-width: 767px) {
      margin-right: 0;
      margin-left: 0;
    }

    &-flex {
      border-radius: 0;
      background-color: white;
      padding: 6px 20px;
      margin-top: -7px;
    }
  }

  mat-checkbox {
    margin-left: -7px;

    .mdc-checkbox {
      margin-right: 0.5em;
      margin-bottom: 0.25em;
      margin-left: 0;
    }

    label {
      color: rgba(112, 112, 112, 0.8);
      font-family: 'POLOGSV-BUCH';
      font-weight: 200;
      white-space: normal !important;
      font-size: 14px;
      line-height: 25px;
    }

    a {
      color: #ff6600;
      text-decoration: none;
      font-size: inherit;
    }
  }

  .input-wrapper {
    width: 100% !important;

    .mdc-notched-outline__leading {
      border-top-left-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }

    .mdc-notched-outline__trailing {
      border-top-right-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }

    .mat-mdc-form-field {
      width: 100%;
    }
    .mat-mdc-form-field-error {
      padding-top: 0;
    }

    @media only screen and (max-width: 767px) {
      padding-top: 2em;
    }

    @media only screen and (max-width: 767px) {
      display: block !important;
    }
  }
}

// MIDDLE SECTION OF PARENTS LANDING PAGE
.for-parents-middle {
  margin: 0 auto;
  max-width: 1175px;
  padding-top: 8em;
  padding-bottom: 4em;
  padding-inline: 0;
  display: flex;
  align-items: flex-end;

  p {
    margin-top: 1em;
    max-width: 90%;
    font-size: 1em;
    line-height: 25px;
    font-family: 'POLOGSV-BUCH';
    font-weight: 200;
    color: #707070;
  }

  h1 {
    color: #ff6600;
    background-color: #fff;
    border-bottom: 3px solid #eee;
    border-right: 3px solid #eee;
    padding: 0.1em 0.25em 0.1em 0.1em;
    margin: 0 0 0.05em 0;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 3em;
    line-height: 1em;
    font-family: 'POLOGSV-BUCH';
    font-weight: 400;
  }

  h1 + p {
    margin-top: 4em;

    @media screen and (max-width: 767px) {
      margin-top: 1.5em;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0 2em;
    padding-top: 6em;
  }

  @media screen and (max-width: 767px) {
    padding-top: 4em;
    margin-inline: 1em;
  }

  .text-col {
    /* @media only screen and (max-width: 767px) {
            margin-left:-1em;
        } */
  }

  .img-col {
    max-height: 580px;
    width: auto;
    margin-left: -2em;
    margin-bottom: -1em;

    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }
}

// BOTTOM SECTION FORM PARENTS LANDING PAGE
.for-parents-green-bg {
  color: #f3f3f3;
  width: 100%;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('./assets/for-parents-green-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  margin: 0;
  padding-block: 4em;

  form {
    color: black;
  }

  @media only screen and (max-width: 1024px) {
    margin: 0;
    height: 170%;
    padding-bottom: 2em;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 0;
  }

  h1 {
    margin-top: 32px;
    margin-bottom: 0.5em;
    font-size: 3em;
    font-weight: 400;

    @media only screen and (max-width: 767px) {
      margin-top: 1em;
    }
  }

  h1 + h3 {
    margin-top: 0.5em;
  }

  .bottom-form-wrapper {
    max-width: 1175px;
    display: flex;
    gap: 5em;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: 1024px) {
      display: block;
    }

    .flex-column {
      display: flex;
      align-items: flex-start;
      font-family: 'POLOGSV-BUCH';

      .title {
        padding-left: 1em;
        padding-right: 5em;

        @media only screen and (max-width: 1024px) {
          padding-inline: 2em;
        }

        @media only screen and (max-width: 767px) {
          padding: 2em;
        }
      }

      h3 {
        line-height: 30px;
        font-weight: 200;
      }

      img {
        padding-top: 1em;
        max-height: 280px;
        width: auto;
        margin-left: 1.5em;

        @media only screen and (max-width: 1199px) {
          display: none;
        }
      }
    }

    .checkbox {
      /* color: white;
            margin-left: 11px; */

      @media only screen and (max-width: 767px) {
        margin-bottom: 3em;
        margin-left: 1.5em;
      }
    }
  }
}

// ICON SECTION - BOTTOM OF PARENTS LANDING PAGE

.for-parents-icons-section {
  max-width: 1175px;
  margin: 0 auto;
  padding: 3em 0;
  gap: 6em;
  font-family: 'POLOGSV-BUCH';

  @media screen and (max-width: 1200px) {
    gap: 0;
    padding: 3em;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
    padding: 3em 0 !important;
  }

  .icon-wrapper {
    margin: 0 auto;
    max-width: 19em;
    font-weight: 600;
    line-height: 1.75;
    font-size: 1.25em;

    @media only screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      gap: 2em;
      padding: 1em 0;
      margin: 0;
    }

    img {
      margin-bottom: 1.25em;
      width: 6em;
    }
  }
}

/* DESKTOP MENU CONTAINER */
.desktop-menu-container {
  margin-left: 0;
  padding-left: 5px;
  display: none;
  .mdc-button.mdc-button--unelevated.mat-primary {
    width: unset;

    &.home-primary_inverted {
      width: unset;
      padding-inline: 3em;
    }

    &.home-primary_student.register-btn {
      width: unset;

      .inline_text {
        gap: 0;
        padding-left: 1em;
      }
    }
  }

  /* button.mat-button.mat-button--unelevated {
    max-width: 175px;
    width: 100%;
  }

  .inline_text {
    justify-content: center;
    align-items: center;
    gap: 0;
    padding-left: 60px;
    padding-right: 35px;
  }

  ul.static_nav
    button.mat-unelevated-button.mat-primary.home-primary_student
    .mat-button-wrapper {
    padding-left: 2em;
    padding-right: 0.5em;
    width: 200px;
  } */

  @media only screen and (min-width: 1070px) {
    display: block;
  }
}

/* MOBILE MENU  */
.mobile-menu-container {
  display: none;

  @media only screen and (max-width: 1070px) {
    display: block;
    max-height: 38px;
  }

  button.mdc-button.mdc-button--unelevated {
    font-family: 'PoloCEF-Light';

    border-radius: 4px;
    padding: 0 1em;
    font-size: 16px;
    border: 1px solid #066089;
    background: #066089;
    color: white;
    height: 38px;

    img {
      width: 40%;
      margin: 2px 5px;
    }

    span mat-icon {
      margin-left: 5px;
      margin-right: -5px;
    }
  }
}

// MOBILE MENU BUTTON STYLE CHANGE
.outlined {
  background-color: white;
  color: #066089;

  .mat-icon {
    color: #066089;
  }
}

.filled {
  background-color: #066089;
  color: white;
}

//

.mobile-menu-items {
  display: block;
  background-color: white;
  position: fixed;
  width: 100%;
  padding: 1em 1.5em;
  z-index: 10;

  .link {
    padding: 2em 1.5em;
    border-bottom: 1px solid #eeeeee;

    a {
      text-decoration: none;
      font-weight: 600;
      font-size: 1.25em;
    }
  }

  button {
    margin: 1em 0 !important;
  }
  .mdc-button.mdc-button--unelevated.mat-primary {
    max-width: 1089px;
  }

  @media screen and (min-width: 1076px) {
    display: none;
  }
}

//TEXT IN MOBILE MENU AND DESKTOP MENU SELECTABLE COLOR CHANGE
.black a {
  color: #1a1a1a;
}

.blue a {
  color: #066089;
}

.bar {
  font-size: 1.2em;
  padding-right: 0.25em;
  color: #ff6600;
}

/* == END OF PARENTS LANDING PAGE == */

.home-parents-topbar-text {
  font-family: 'PoloCEF-Light' !important;
  margin-left: 4em;
  display: flex;
  gap: 1em;

  @media only screen and (max-width: 1069px) {
    display: none;
  }

  a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 600;
  }

  &--right {
    padding-left: 2em;
  }
}

.home-dialog-panel {
  max-width: 1000px !important;
  overflow: auto;
  background: #f6f8fb;
  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  color: $color-text-light-black;
  border-radius: 4px;

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 4px;
  }

  .mat-icon {
    min-width: 9px;
    min-height: 9px;
  }
}

.home-trial-dialog-panel {
  max-width: 655px !important;

  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  color: $color-text-light-black;
  border-radius: 12px;

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    border-radius: 12px;
    background: $color-b-w-10;
  }

  .mat-icon {
    min-width: 9px;
    min-height: 9px;
  }
}

.home-trial-login-dialog {
  padding: 3em;
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    overflow-y: scroll;
    max-height: 90vh;
  }

  .text-center p {
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }
}
.home-login-dialog {
  padding: 1em 1em 0.5em;
  border-radius: 4px;

  @media screen and (max-width: 767px) {
    overflow-y: scroll;
    max-height: 90vh;
  }

  .text-center p {
    @media screen and (max-width: 767px) {
      margin: 0;
    }
  }

  /* login menu for mobile */
  &-title {
    margin-bottom: 1em;
    font-weight: 800 !important;

    @media screen and (max-width: 767px) {
      font-size: 1em;
    }
  }
  &-text {
    @media screen and (max-width: 767px) {
      font-size: 0.85em;
    }
  }
  &-btn-wrap {
    display: flex;
    justify-content: center;
  }
  &-btn {
    border-radius: 0 !important;
    margin: 1.5em auto !important;
    font-weight: 600;
    font-family: 'PoloCEF-Light' !important;
    font-size: 16px;

    &.mdc-button.mdc-button--unelevated.mat-primary {
      width: 320px;
    }
  }

  &-footer {
    background-color: $color-green-100;
    padding: 1em;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
      margin-bottom: 1em !important;
      font-size: 14px;
    }
  }

  .mdc-button.mdc-button--unelevated {
    padding-inline: 2em;
  }

  .red {
    color: red;
  }

  /* login menu for tablet or bigger */
  &-content {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 1em;

    img {
      max-width: 400px;
      padding-bottom: 0;
    }
    &--desc {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      max-width: 400px;
      min-width: 300px;
      text-align: center;
      padding-inline: 1em;

      h3 {
        margin-bottom: 1em;
      }
    }
    &--btns {
      flex-basis: 45%;

      button {
        max-width: 320px;
      }
    }
  }
}

.home-subscribe-dialog {
  display: flex;
  position: relative;

  .close-button--right {
    z-index: 50;
  }

  a {
    color: $color-orange-600;
    font-size: 0.95em;
    text-decoration: none;
  }

  @media screen and (max-width: 991px) {
    padding: 2em 1em;
  }

  &--woman-img {
    background-image: url('./assets/woman-computer.png');
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 624px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &--right-col {
    flex-direction: column;
    display: flex;
    justify-content: center;
    padding: 0em 4em 0em 3.5em;
    color: $color-b-w-800;
    margin-right: -2em;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      h1 {
        margin: 1em 0 !important;
        font-size: 28px;

        @media screen and (max-width: 530px) {
          font-size: 24px;
          margin-bottom: 0.5em;
        }
      }

      img {
        width: 137px;
        margin-left: 1em;

        @media screen and (max-width: 530px) {
          width: 120px;
        }
      }
    }

    p {
      margin: 1em 0;
      font-size: 1.25em;
    }

    &-btn-wrap {
      display: flex;
      justify-content: space-between;
      margin-left: unset !important;

      @media screen and (max-width: 767px) {
        display: block;
      }

      a {
        width: 49%;
        text-decoration: none;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        button {
          width: 100%;
        }
      }

      button {
        width: 49%;

        @media screen and (max-width: 767px) {
          width: 100%;
          margin-top: 1em;
        }
      }
    }

    &-footer {
      text-align: center;
      margin: 0.75em auto;

      p {
        font-size: 0.9em;
        margin-bottom: 0.5em;
      }
    }
  }
}

.orange-btn {
  background-color: $color-orange-600 !important;
  color: #fff !important;
}

.text-center {
  text-align: center;
  font-size: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 1em;
  }
}

@media screen and (max-width: 1199px) {
  section.section-laletu-info {
    max-width: $layout-max-width;
    margin: 0 auto;
    padding-bottom: 120px;

    .headline {
      font-size: 24px !important;
      line-height: 35px !important;
    }

    .subheadline {
      margin-bottom: 24px !important;
    }

    .paragraph {
      color: #707070;
      font-size: 18px !important;
      line-height: 27.5px !important;
    }

    .reverse_row {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }

  section.sponsor_section {
    margin-top: 100px !important;
    margin-bottom: 64px !important;
  }

  .section_price {
    margin-bottom: 140px !important;
  }

  .section_gray_img {
    padding-bottom: 72px !important;
    padding-top: 0px !important;
  }

  .speech_bubble_section {
    margin-top: 72px !important;
  }

  .home_box_teacher {
    h3 {
      font-size: 22px !important;
    }
  }

  .section_gray_img {
    .section_headline {
      margin-top: 0;
      font-size: 24px !important;
      line-height: 35px !important;
    }

    .section_text {
      font-size: 18px;
      line-height: 27.5px;
      color: #707070;
    }
  }

  .home_container .speech_bubble--container {
    font-size: 18px !important;
  }

  .home_container .speech_bubble_bg p {
    font-size: 18px !important;
    line-height: 27.5px !important;
  }

  .headline_box {
    font-size: 48px !important;
    line-height: 54px !important;
  }

  .home_container {
    .headline {
      font-size: 28px !important;
    }

    .subheadline {
      font-size: 24px !important;
      line-height: 40px !important;
    }

    .paragraph {
      font-size: 18px !important;
    }
  }

  .home_box_teacher {
    margin-bottom: 64px !important;
  }

  .number-mobile,
  img.cat_mobile {
    display: block;
  }

  .app-section {
    section.sponsor_section {
      margin-top: 100px !important;
      margin-bottom: 64px !important;
    }

    .section_price {
      margin-bottom: 140px !important;
    }

    .section_gray_img {
      padding-bottom: 72px !important;
      padding-top: 0px !important;
    }

    .speech_bubble_section {
      margin-top: 72px !important;
    }

    .home_box_teacher {
      h3 {
        font-size: 22px !important;
      }
    }

    .section_gray_img {
      .section_headline {
        margin-top: 0;
        font-size: 24px !important;
        line-height: 35px !important;
      }

      .section_text {
        font-size: 18px;
        line-height: 27.5px;
        color: #707070;
      }
    }

    .home_container .speech_bubble--container {
      font-size: 18px !important;
    }

    .home_container .speech_bubble_bg p {
      font-size: 18px !important;
      line-height: 27.5px !important;
    }

    .headline_box {
      font-size: 48px !important;
      line-height: 54px !important;
    }

    .home_container {
      .headline {
        font-size: 28px !important;
      }

      .subheadline {
        font-size: 24px !important;
        line-height: 40px !important;
      }

      .paragraph {
        font-size: 18px !important;
      }
    }

    .home_box_teacher {
      margin-bottom: 64px !important;
    }

    .number-mobile,
    img.cat_mobile {
      display: block;
    }

    .app-section {
      h1 {
        text-align: center;
        font-size: 48px !important;
        line-height: 56px !important;
        margin-bottom: 24px !important;
      }

      h4 {
        text-align: center;
        font-size: 22px !important;
        line-height: 30px !important;
      }

      .app-info {
        max-width: 704px;
        margin: 0 auto;

        .paragraph--xl {
          text-align: center;
        }
      }
    }

    section.home_register_section.dashboard_register_section {
      min-width: unset !important;
      opacity: 1 !important;
      z-index: 999;
    }

    .home_container {
      .home_header_content {
        max-width: unset !important;
        padding-left: 0 !important;
      }
    }

    .speech_bubble_section {
      display: flex;
      flex-direction: column-reverse;
    }

    .speech_bubble_bear {
      display: none;
    }

    .speech_bubble:nth-child(2),
    .speech_bubble:nth-child(4),
    .speech_bubble:nth-child(6) {
      position: relative;
      top: 0 !important;
    }

    .speech_bubble:nth-child(4),
    .speech_bubble:nth-child(6) {
      margin-right: 0px !important;
    }

    .speech_bubble_wrap:before {
      display: none !important;
    }

    img.cat_mobile {
      position: absolute;
      max-width: 192px;
      left: 56px;
      bottom: 165px;
    }

    .speech_bubble_bg {
      width: calc(100% + 32px) !important;
      height: unset !important;
      background: #116089;
      position: relative !important;
      transform: rotate(0) !important;
      left: -16px !important;
      top: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 56px !important;

      p {
        position: relative !important;
        left: 0 !important;
        color: #fff;
        bottom: 0px !important;
        width: 100% !important;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 300 !important;
        line-height: 27.5px !important;
      }
    }

    img.number-desktop {
      display: none;
    }

    .section_blue_bg {
      background-size: cover !important;

      .section_blue_header {
        margin-left: 0px !important;
        margin-top: 0px !important;
        margin-bottom: 84px;
      }

      .number_bottom {
        margin-bottom: 0 !important;
        gap: 16px;

        img {
          margin-top: 0 !important;
        }
      }

      .number_container {
        flex-wrap: wrap;
        max-width: unset !important;
      }

      .number_wrap {
        flex-direction: row !important;
        align-items: flex-start !important;
        padding: 16px 0;
        flex: 50% !important;

        img {
          max-width: 102px !important;
        }
      }

      .number-desktop {
        display: none;
      }

      .number_top {
        margin-top: 0 !important;
        gap: 16px;

        img {
          margin-bottom: 0 !important;
        }
      }

      .number_wrap p {
        color: #fff;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 300 !important;
        line-height: 25px !important;
        width: 100% !important;
        text-align: left;
      }
    }

    section.sponsor_section .sponsor_images {
      flex-wrap: wrap;
    }

    .section_accordion {
      .h3 {
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
      }

      .paragraph {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 27.5px;
      }

      .mat-expansion-panel-header-title {
        font-size: 24px !important;
        line-height: 32px !important;
      }
    }

    .home-accordion-box {
      padding: 48px !important;

      .accordion-img {
        min-width: unset !important;
      }
    }

    .section_price {
      .price-box-wrap {
        .price-box {
          flex: 50% !important;
        }

        .price-box.first {
          border-right: 1px solid #066089 !important;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  section.sponsor_section {
    margin-top: 100px !important;
    margin-bottom: 64px !important;
  }

  .section_price {
    margin-bottom: 140px !important;
  }

  .section_gray_img {
    padding-bottom: 72px !important;
    padding-top: 0px !important;
  }

  .speech_bubble_section {
    margin-top: 72px !important;
  }

  .home_box_teacher {
    h3 {
      font-size: 22px !important;
    }
  }

  .section_gray_img {
    .section_headline {
      margin-top: 0;
      font-size: 24px !important;
      line-height: 35px !important;
    }

    .section_text {
      font-size: 18px;
      line-height: 27.5px;
      color: #707070;
    }
  }

  .home_container .speech_bubble--container {
    font-size: 18px !important;
  }

  .home_container .speech_bubble_bg p {
    font-size: 18px !important;
    line-height: 27.5px !important;
  }

  .headline_box {
    font-size: 48px !important;
    line-height: 54px !important;
  }

  .home_container {
    .headline {
      font-size: 28px !important;
    }

    .subheadline {
      font-size: 24px !important;
      line-height: 40px !important;
    }

    .paragraph {
      font-size: 18px !important;
    }
  }

  .home_box_teacher {
    margin-bottom: 64px !important;
  }

  .number-mobile,
  img.cat_mobile {
    display: block;
  }

  .app-section {
    h1 {
      text-align: center;
      font-size: 48px !important;
      line-height: 56px !important;
      margin-bottom: 24px !important;
    }

    h4 {
      text-align: center;
      font-size: 22px !important;
      line-height: 30px !important;
    }

    .app-info {
      max-width: 704px;
      margin: 0 auto;

      .paragraph--xl {
        text-align: center;
      }
    }
  }

  section.home_register_section.dashboard_register_section {
    min-width: unset !important;
    opacity: 1 !important;
    z-index: 999;
  }

  .home_container {
    .home_header_content {
      max-width: unset !important;
      padding-left: 0 !important;
    }
  }

  .speech_bubble_section {
    display: flex;
    flex-direction: column-reverse;
  }

  .speech_bubble_bear {
    display: none;
  }

  .speech_bubble:nth-child(2),
  .speech_bubble:nth-child(4),
  .speech_bubble:nth-child(6) {
    position: relative;
    top: 0 !important;
  }

  .speech_bubble:nth-child(4),
  .speech_bubble:nth-child(6) {
    margin-right: 0px !important;
  }

  .speech_bubble_wrap:before {
    display: none !important;
  }

  img.cat_mobile {
    position: absolute;
    max-width: 192px;
    left: 56px;
    bottom: 165px;
  }

  .speech_bubble_bg {
    width: calc(100% + 32px) !important;
    height: unset !important;
    background: #116089;
    position: relative !important;
    transform: rotate(0) !important;
    left: -16px !important;
    top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px !important;

    p {
      position: relative !important;
      left: 0 !important;
      color: #fff;
      bottom: 0px !important;
      width: 100% !important;
      font-size: 18px !important;
      font-style: normal;
      font-weight: 300 !important;
      line-height: 27.5px !important;
    }
  }

  img.number-desktop {
    display: none;
  }

  .section_blue_bg {
    background-size: cover !important;

    .section_blue_header {
      margin-left: 0px !important;
      margin-top: 0px !important;
      margin-bottom: 84px;
    }

    .number_bottom {
      margin-bottom: 0 !important;
      gap: 16px;

      img {
        margin-top: 0 !important;
      }
    }

    .number_container {
      flex-wrap: wrap;
      max-width: unset !important;
    }

    .number_wrap {
      flex-direction: row !important;
      align-items: flex-start !important;
      padding: 16px 0;
      flex: 50% !important;

      img {
        max-width: 102px !important;
      }
    }

    .number-desktop {
      display: none;
    }

    .number_top {
      margin-top: 0 !important;
      gap: 16px;

      img {
        margin-bottom: 0 !important;
      }
    }

    .number_wrap p {
      color: #fff;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 300 !important;
      line-height: 25px !important;
      width: 100% !important;
      text-align: left;
    }
  }

  section.sponsor_section .sponsor_images {
    flex-wrap: wrap;
  }

  .section_accordion {
    .h3 {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
    }

    .paragraph {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 27.5px;
    }

    .mat-expansion-panel-header-title {
      font-size: 24px !important;
      line-height: 32px !important;
    }
  }

  .home-accordion-box {
    padding: 48px !important;

    .accordion-img {
      min-width: unset !important;
    }
  }

  .section_price {
    .price-box-wrap {
      .price-box {
        flex: 50% !important;
      }

      .price-box.first {
        border-right: 1px solid #066089 !important;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  section.section-laletu-info {
    padding-bottom: 140px !important;

    .paragraph {
      color: #707070;
      font-size: 16px !important;
      line-height: 25px !important;
    }

    .section_gray_header {
      margin-bottom: 32px !important;
    }

    .row-divider {
      margin-bottom: 64px !important;
    }

    .reverse_row {
      display: flex !important;
      flex-direction: column-reverse !important;
    }
  }

  section.sponsor_section {
    margin-top: 72px !important;
    margin-bottom: 72px;
  }

  .section_price {
    margin-bottom: 108px !important;
  }

  .home_box_teacher {
    h3 {
      font-size: 18px !important;
    }
  }

  .section_gray_img {
    padding-bottom: 72px !important;
    padding-top: 0px !important;
  }

  .home_container .section_gray_bg {
    margin: 190px auto 138px auto !important;
  }

  .speech_bubble_section {
    margin-top: 72px !important;
  }

  .home_container .speech_bubble_bg p {
    font-size: 16px !important;
    line-height: 27.5px !important;
  }

  .headline_box {
    font-size: 36px !important;
    line-height: 48px !important;
  }

  .home_container {
    .headline {
      font-size: 24px !important;
      line-height: 36px !important;
    }

    .subheadline {
      font-size: 20px !important;
      line-height: 32px !important;
    }

    .paragraph {
      font-size: 16px !important;
    }
  }

  .home_box_teacher {
    margin-bottom: 32px !important;
  }

  .accordion_headline {
    max-width: 300px;
  }

  .accordion-info-box {
    align-items: flex-start !important;
    flex-direction: column !important;

    h2 {
      font-size: 22px !important;
      line-height: 35px !important;
    }
  }

  .desktop-text {
    display: none !important;
  }

  .app-section {
    h1 {
      text-align: center;
      font-size: 32px !important;
      line-height: 36px !important;
    }

    h4 {
      text-align: center;
      font-size: 18px !important;
      line-height: 30px !important;
    }

    .app-info {
      max-width: 704px;
      margin: 0 auto;

      .paragraph--xl {
        font-size: 16px !important;
        line-height: 25px !important;
      }

      .button_wrap {
        gap: 16px !important;
        flex-direction: column !important;
      }
    }
  }

  .section_price {
    .price-selection {
      display: flex;
      align-items: flex-start !important;
      margin-bottom: 32px !important;
      flex-direction: column !important;
      gap: 48px;

      .teacher_tabs_header_buttons {
        border: none !important;
      }

      span.mat-button-toggle-label-content {
        border: 3px solid #eee !important;
      }

      .mat-button-toggle-checked
        .mat-button-toggle-button
        span.mat-button-toggle-label-content {
        border: 3px solid #ff6600 !important;
      }

      .teacher_tabs_header_buttons .mat-button-toggle-button {
        padding: 0 !important;
      }
    }

    .price-box-wrap {
      gap: 32px;

      .price-box {
        border: 1px solid #066089;
        padding: 36px 24px;
        flex: 100% !important;
        height: auto;
        display: flex;
        flex-direction: column;
      }

      &.first {
        border-right: 1px solid #066089 !important;
      }
    }
  }

  .section_accordion .mat-expansion-panel-header-title .mat-icon {
    width: 28px !important;
    height: 28px !important;
  }

  .home_header {
    background-size: cover;
    background-position: left;
  }

  .home_container .home_header_content {
    max-width: unset;
    width: 100%;
    padding-left: 0;
  }

  .home_container .speech_bubble_content {
    display: block;
    width: 100%;
  }

  .home_container .speech_bubble--container {
    max-width: unset;
  }

  .speech_bubble {
    display: flex;
    flex: 0 0 calc(50% - 30px);

    &:nth-child(4) {
      margin-right: 0 !important;
    }

    &:nth-child(6) {
      margin-right: 0 !important;
    }

    &--container {
      margin: 15px 0px !important;
      position: relative;
      background-color: $color-b-w-0;
      padding: 30px 20px;
      font-size: 20px;
      border-radius: 1rem;
      border: 2px solid #066089;
      max-width: 280px;
      display: flex;
      align-items: center;
    }
  }

  section.speech_bubble_section {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .speech_bubble_bg {
    top: 0px !important;
    position: relative !important;
    transform: rotate(0deg) !important;
    display: flex;
    align-items: center;
    padding: 0 20px;
    left: -16px !important;
    width: calc(100% + 32px) !important;
    margin-top: 32px;
  }

  .speech_bubble_bear {
    display: none !important;
  }

  .speech_bubble_bg p {
    position: relative !important;
    left: 0 !important;
    color: #fff;
    bottom: 0 !important;
    width: 100% !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    line-height: 27.5px !important;
    text-align: center;
  }

  .speech_bubble_wrap:before {
    display: none !important;
  }

  .speech_bubble:nth-child(2),
  .speech_bubble:nth-child(4),
  .speech_bubble:nth-child(6) {
    position: relative;
    top: 0 !important;
  }

  .speech_bubble_wrap {
    margin-top: 32px !important;
  }

  .headline_box {
    font-size: 36px !important;
    font-weight: 400 !important;
    line-height: 48px !important;
  }

  .home_container .section_gray_bg .row {
    padding: 48px 0 48px 0 !important;
  }

  .reverse_row {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .section_gray_header {
    left: 0 !important;
  }

  .section_blue_bg {
    background-size: cover !important;

    .section_blue_header {
      margin-left: 0px !important;
      margin-top: 0px !important;
      margin-bottom: 84px;
    }

    img.blue_section_img {
      display: none !important;
    }

    .number_container {
      display: flex !important;
      justify-content: center !important;
      max-width: unset !important;
      margin: 0 !important;
      height: 100% !important;
      flex-direction: column !important;
    }

    .number_bottom {
      margin-bottom: 0 !important;
      gap: 16px;

      img {
        margin-top: 0 !important;
      }
    }

    .number_wrap {
      flex-direction: row !important;
      align-items: flex-start !important;
      padding: 16px 0;

      img {
        max-width: 102px !important;
      }
    }

    .number-desktop {
      display: none;
    }

    .number_top {
      margin-top: 0 !important;
      gap: 16px;

      img {
        margin-bottom: 0 !important;
      }
    }

    .number_wrap p {
      color: #fff;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 300 !important;
      line-height: 25px !important;
      width: 100% !important;
      text-align: left;
    }
  }

  .section_gray_img {
    .section_gray_img_headline {
      margin-bottom: 0px !important;
    }

    .section_headline {
      margin-top: 0;
      font-size: 22px !important;
      line-height: 35px !important;
    }

    .section_text {
      font-size: 16px;
      line-height: 25px;
    }

    .row {
      border-bottom: 1px solid #eee;
      padding-bottom: 32px;
    }

    img {
      margin-bottom: 32px;
    }
  }

  .home_register_headline {
    margin-left: 0px !important;
  }

  .home_buttons {
    width: fit-content;
  }

  .home_buttons .mat-unelevated-button {
    display: flex !important;
    justify-content: flex-start;
  }

  .home_box_student {
    flex-direction: column !important;
    height: 100% !important;
  }

  .home_box_student .home_box_student_img {
    max-width: unset;
  }

  section.sponsor_section {
    .sponsor_images {
      gap: 24px !important;
      flex-wrap: wrap !important;
    }

    img {
      max-height: auto !important;
      max-width: 155px;
    }
  }

  footer {
    .footer-nav {
      flex-direction: column !important;
      text-align: center !important;
    }
  }

  section.home_register_section.dashboard_register_section {
    min-width: unset;
    z-index: 9999999;
    opacity: 1;

    .home_box_student_img.header {
      display: none;
    }

    .home_box_student_content.header {
      margin-top: 32px;
    }
  }

  .cat_mobile {
    display: none !important;
  }

  .section_accordion {
    margin-bottom: 72px !important;

    .home-accordion-box {
      padding: 16px !important;
      flex-direction: column-reverse;
      gap: 32px;

      .accordion-img {
        min-width: unset !important;
      }
    }

    .h3 {
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
    }

    .paragraph {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }
  }
}

.section_accordion {
  max-width: 1140px;
  margin: 0 auto;
  margin-bottom: 350px;

  .section_gray_header {
    position: relative;
    bottom: 36px !important;
    left: 0px !important;
  }

  .mat-expansion-panel-header.mat-expanded {
    border: none;

    .mat-expansion-panel-header-title {
      color: $color-orange-600;

      .mat-icon {
        transform: rotate(0deg);
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0 0 36px 0;
    background: #fff;
  }

  .mat-expansion-panel-header {
    padding: 36px 0 !important;
  }

  .mat-expansion-panel-header-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    letter-spacing: 0.64px;
    text-transform: uppercase;
    color: #066089;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .mat-icon {
      width: 40px !important;
      height: 40px !important;
      transform: rotate(45deg);
      transition: 0.3s all;
    }
  }

  .home-accordion-box.without_img {
    flex-direction: column;
  }

  .home-accordion-box {
    background: #066089;
    padding: 64px;
    display: flex;

    .accordion-img {
      min-width: 503px;
      width: 100%;
    }

    .h3,
    .paragraph {
      color: #fff;
    }

    .h3 {
      margin-bottom: 13px;
    }

    .paragraph {
      margin-bottom: 32px;
    }

    .link-wrap {
      width: fit-content;

      .link-icon {
        display: flex;
        align-items: center;

        .mat-icon {
          transform: rotate(-90deg);
          color: #fff;
        }
      }

      .link {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
      }

      .underline {
        width: 100%;
        height: 2px;
        background: #fff;
      }
    }
  }
}

.star-badge-wrap {
  position: relative;
  display: flex;
  align-items: center;

  &__icon {
    position: absolute;
    top: 0;
    left: 1.1em;
    margin-top: -14px;

    .mat-icon.star-icon {
      width: 27px !important;
      height: 27px !important;
    }
  }

  .avatar_img {
    border-radius: 50%;
    border-width: 2.5px;
    border-style: solid;
    padding: 3px;
    width: 42px;
    height: 42px;
    background-color: $color-yellow-200;
  }
  p {
    @media screen and (max-width: 857px) {
      display: none;
    }
  }

  &--level0 {
    .avatar_img {
      border-color: $color-slate-550;
    }
  }

  &--level1 {
    .avatar_img {
      border-color: $color-red-500;
    }
  }

  &--level2 {
    .avatar_img {
      border-color: $color-orange-600;
    }
  }

  &--level3 {
    .avatar_img {
      border-color: $color-yellow-700;
    }
  }

  &--level4 {
    .avatar_img {
      border-color: $color-green-600;
    }
  }

  &--level5 {
    .avatar_img {
      border-color: $color-blue-600;
    }
  }
}

.headline_box {
  text-transform: uppercase;
}

.price-section {
  max-width: 1920px;
  margin: 0 auto;
  margin-bottom: 64px;
}

.section_price {
  margin-bottom: 200px;

  .price-selection {
    display: flex;
    align-items: flex-end;
    //justify-content: space-between;
    gap: 2em;
    max-width: 1920px;
    margin: 0 auto;
    margin-bottom: 64px;

    span.mat-button-toggle-label-content {
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 27.5px;
      color: #066089;
      padding: 25px;
    }

    .mat-button-toggle-checked .mat-button-toggle-button {
      background: transparent;
      padding: 1px !important;

      span.mat-button-toggle-label-content {
        background: #ff6600;
        border-radius: 0;
        padding: 25px !important;
        color: #fff;
      }
    }

    .teacher_tabs_header_buttons .mat-button-toggle-button {
      height: 100% !important;
      padding: 1px !important;
    }

    mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
      gap: 12px;
    }

    .teacher_tabs_header_buttons {
      padding: 12px;
      border: 1px solid #eee;
    }
  }

  .section_gray_header {
    position: relative;
    bottom: 0px !important;
    left: 0px !important;
  }

  .price-box-wrap {
    display: flex;
    flex-wrap: wrap;
    max-width: 1920px;
    margin: 0 auto;
    gap: 14px;

    .price-box {
      border: 1px solid #066089;
      padding: 36px 20px;
      flex: 24%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mdc-button.mdc-button--unelevated {
        margin-left: 0 !important;
      }

      .price-box-header {
        h3,
        h4 {
          color: #066089;
          margin: 0;
        }

        margin-bottom: 16px;
      }

      .price-list-wrap {
        margin-bottom: 12px;

        .price-list {
          margin-bottom: 12px;

          .paragraph {
            color: #707070;
            width: fit-content;
          }
        }
      }

      button.mdc-button.mdc-button--unelevated {
        border-radius: 0px;
        width: 100%;
        padding: 8px 0 !important;
        font-weight: 400;
        margin-top: auto;
        border: 1px solid #066089;
        color: #066089;
      }

      &.best-option {
        border: 4px solid #066089;
        position: relative;

        /* button.mdc-button.mdc-button--unelevated {
          border: none;
          background-color: #ff6600;
          color: #fff;
        } */
      }

      .headline-wrap {
        position: absolute;
        height: auto;
        left: 0;
        top: -30px;
        display: flex;
        width: 100%;
        justify-content: center;
      }

      .headline {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 27.5px;
        letter-spacing: 0.32px;
        text-transform: uppercase;
        color: #fff;
        background-color: #066089;
        padding: 12px;
        max-width: 231px;
        width: 100%;
      }

      &.first {
        border-right: none;
      }

      .price-wrap {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 16px;

        .price-save {
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 27.5px;
          letter-spacing: 0.15px;
          color: #066089;
          background: #0660891a;
          padding: 8px;
          border-radius: 2px;
          height: fit-content;
        }

        .price {
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
          text-transform: uppercase;
          // margin-bottom: 16px;
          color: $color-orange-600;
        }
      }

      .price-list {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 12px;

        .mat-icon {
          color: #066089;
        }

        .paragraph {
          color: #707070;
          width: fit-content;
        }
      }

      .price-list:last-child {
        margin-bottom: 24px !important;
      }

      .mat-unelevated-button.mat-primary {
        border-radius: 0px;
        width: 100%;
        border: 1px solid #066089;
        padding: 8px 0 !important;
        font-weight: 400;
        margin-top: auto;
      }
    }
  }
}

section.app-section {
  background-image: url('./assets/footer-bg.png');
  min-height: 624px;
  display: flex;
  align-items: center;
  width: 100vw;
  margin-left: -16px;
  background-color: #000;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (max-width: 767px) {
    min-height: 424px;
  }

  .row {
    max-width: $layout-max-width;
    margin: 0 auto;
    padding: 24px;
  }

  .app-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: #fff;

    @media screen and (max-width: 1199px) {
      align-items: center;
      margin-bottom: 6em;
    }

    h1 {
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 72px;
      color: #fff;
    }

    h4 {
      margin: 0 !important;
    }
  }

  .app-info {
    display: flex;
    flex-direction: column;

    .h4 {
      color: #fff;
    }

    .paragraph--xl {
      color: #fff;
      opacity: 0.5;
    }

    .qr-code-wrap {
      display: flex;
      justify-content: space-evenly;

      img {
        max-width: 140px;
        height: auto;
      }
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
    .button_wrap {
      display: flex;
      justify-content: space-evenly;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }
    }

    button.mdc-button.mdc-button--unelevated {
      margin-bottom: 2em;
      background: white;
      border-radius: 0;
      width: 250px;

      .mdc-button__label {
        display: flex;
        align-items: center;
      }

      @media only screen and (max-width: 767px) {
        margin-bottom: 1em;
        width: 100%;
      }

      span img {
        height: 25px;
        width: auto;
        margin-right: 10px;

        &.play-icon {
          height: 21px !important;
        }
      }
    }
  }
}

.accordion-info-box {
  background-color: #f6f6f6;
  padding: 32px 24px;
  display: flex;
  gap: 24px;
  align-items: center;
  margin-top: 36px;
  justify-content: space-between;

  .mat-mdc-unelevated-button.mat-primary {
    max-width: 258px;
    background-color: $color-orange-600 !important;
    color: #fff !important;

    span.inline_text {
      gap: 0;

      .mat-icon {
        margin-right: 0;
      }
    }
  }

  .h2 {
    color: #066089;
  }
}

.helpdesk-panel {
  max-width: 580px !important;

  .helpdesk_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    padding: 1em;

    .mat-icon {
      width: 120px !important;
      height: 120px !important;
      color: $color-slate-700;
      margin-bottom: 26px;
    }

    .h3 {
      margin-bottom: 20px;
    }

    form {
      margin-top: 20px;
    }

    .mat-mdc-form-field {
      max-width: 100%;
      width: 100%;
    }
    .mat-mdc-text-field-wrapper.mdc-text-field--outlined
      .mat-mdc-form-field-infix {
      margin-left: -20px;
    }

    .button_wrap {
      display: flex;
      gap: 16px;
    }
  }
}

section.section-laletu-info {
  max-width: $layout-max-width;
  margin: 0 auto;
  padding-bottom: 200px;

  @media screen and (max-width: 767px) {
    padding-bottom: 140px;

    .section_gray_header {
      margin-bottom: 32px !important;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1199px) {
    max-width: $layout-max-width;
    margin: 0 auto;
    padding-bottom: 120px;

    .number_container {
      flex-wrap: wrap;
      max-width: unset !important;
    }

    .number_wrap {
      flex-direction: row !important;
      align-items: flex-start !important;
      padding: 16px 0;
      flex: 50% !important;

      img {
        max-width: 102px !important;
      }
    }

    .number-desktop {
      display: none;
    }

    .number_top {
      margin-top: 0 !important;
      gap: 16px;

      img {
        margin-bottom: 0 !important;
      }
    }

    .number_wrap p {
      color: #fff;
      font-size: 18px !important;
      font-style: normal !important;
      font-weight: 300 !important;
      line-height: 25px !important;
      width: 100% !important;
      text-align: left;
    }
  }

  .headline {
    color: $color-orange-600;

    @media screen and (max-width: 1199px) {
      font-size: 24px;
      line-height: 35px;
    }
  }

  .subheadline {
    color: #066089;
    margin-bottom: 32px;

    @media screen and (max-width: 1199px) {
      margin-bottom: 24px;
    }
  }

  .paragraph {
    color: #707070;

    @media screen and (max-width: 767px) {
      line-height: 25px;
    }

    @media screen and (min-width: 768px) and (max-width: 1199px) {
      line-height: 27.5px;
    }
  }

  .section_gray_header {
    position: relative;
    bottom: 0 !important;
    left: 0 !important;
    margin-bottom: 100px;
  }

  .reverse_row {
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  .row-divider {
    margin-bottom: 120px;

    @media screen and (max-width: 767px) {
      margin-bottom: 64px;
    }
  }
}

.group-delete_wrap.automode-wrap {
  padding: 1em;

  .button-wrap {
    display: flex;
    width: 100%;
    margin-top: 3em;
    justify-content: space-between;

    .mdc-button.mdc-button--unelevated {
      flex-basis: 48%;
    }
  }

  .group_headline {
    display: flex;
    margin-bottom: 40px;
  }
}

.route--task-preview .userback-button-container {
  display: none !important;
}

/* @media screen and (max-width: 976px) {
  .archivments-panel .archivment-overlay_container .archivment-body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 689px) {
  .archivments-panel .archivment-overlay_container .student-award:nth-child(n) {
    right: 0 !important;
  }
} */

.student_awards_row {
  padding: 0 20px;
}

.record-rtc-result-overlay-panel {
  max-width: 837px !important;
}

.task_detail_info-container {
  display: flex;
  gap: 16px;
  flex-direction: column-reverse;

  @media screen and (min-width: 929px) {
    flex-direction: row-reverse;
  }
}

.book_cover-wrap {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.button_wrap-last-step.admin-create {
  justify-content: center;
}

.admin-last-step {
  max-width: 310px;
  width: 100%;
}

.delete-admin-icon {
  color: $color-red-500 !important;
}

button.teacher-login {
  background: #066089 !important;
  color: #fff !important;
  border-radius: 0 !important;
  padding: 10px 12px !important;
  width: 100% !important;
  max-width: 316px !important;
}

app-admin-group-list {
  .mat-sort-header-container {
    justify-content: flex-start;
  }

  table {
    border-radius: 12px !important;
  }

  td.mat-column-teacher {
    padding-left: 1em;
    min-width: 150px;
  }

  td.mat-column-test_license,
  td.mat-column-training_license {
    //min-width: 150px;
    // text-align: center;
  }

  td.mat-column-expand {
    width: 100px !important;
    min-width: unset !important;
  }

  th.mat-header-cell:last-of-type {
    text-align: center;
  }
}

.parents-pdf {
  margin-top: 20px;

  .link {
    color: #fff;
    font-size: 18px;
  }
}

.statistic-col {
  mat-button-toggle-group.mat-button-toggle-group {
    display: flex;
    height: 46px;

    @media screen and (max-width: 767px) {
      flex-direction: row;
      margin-top: 1em;

      .mat-icon {
        display: none;
      }
    }
  }
  .teacher_tabs_header_buttons .mat-button-toggle-group {
    max-width: 400px;
    margin-left: 1em;
  }
}

.report_text {
  .class_detail_info {
    flex-wrap: wrap;
  }
}

.container-overflow {
  overflow-x: hidden;
}

.reading-level-overlay-panel {
  max-width: 830px !important;

  .reading-level_container {
    position: relative;

    .divider {
      width: 95%;
      height: 2px;
      background: $color-slate-300;
      position: absolute;
      top: 25px;
      left: 11px;
    }

    .reading-level_img-wrap {
      display: flex;
      margin-bottom: 80px;
      gap: 40px;
      margin-top: 25px;

      .reading-level_img-wrap {
        width: 52px;
        height: 52px;
      }

      img {
        max-width: 52px;
        width: 100%;
        max-height: 52px;
        height: 100%;
        z-index: 2;
      }
    }

    .reading-level-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .h3 {
        color: $color-slate-700;
        margin-bottom: 16px;
      }

      .paragraph {
        color: $color-slate-600;
      }
      .mdc-button.mdc-button--unelevated.mat-primary-student {
        margin-top: 2em;
      }
    }

    .star-badge-wrap {
      position: relative;
      display: flex;
      justify-content: center;

      .star-badge-wrap__icon {
        left: unset;
        z-index: 3;
      }
    }

    .active-level {
      transform: scale(2);
    }

    .avatar-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .img-left-upgrade {
      position: absolute;
      left: -18px;
      max-width: 33px !important;
      height: auto !important;
      top: -20px;
    }

    img.img-bottom-upgrade.ng-star-inserted {
      position: absolute;
      bottom: -17px;
      left: -13px;
      max-width: 23px !important;
      height: auto !important;
    }

    .img-right-upgrade {
      position: absolute;
      right: -11px;
      top: -17px;
      max-width: 42px !important;
      height: auto !important;
      z-index: 1;
    }

    img.img-left {
      position: absolute;
      left: -16px;
      max-width: 40px !important;
      height: auto !important;
      top: -19px;
    }

    img.img-right {
      position: absolute;
      right: -25px;
      top: -27px;
      max-width: 48px !important;
      height: auto !important;
    }

    img.img-top {
      position: absolute;
      top: -1.5em;
      max-width: 33px !important;
      height: auto !important;
    }

    .button-wrap {
      width: 100%;
      margin-top: 40px;

      .mat-unelevated-button.mat-primary-student {
        max-width: 234px;
        width: 100%;
        margin: 0 auto;
      }
    }
  }
}

.license-info-tooltip {
  display: flex;
  align-items: center;
  gap: 8px;
}

.email-wrap {
  width: 100%;
  max-width: 646px;

  .h3 {
    margin-top: 0;
  }
}

app-managing-stepper {
  span.teacher-status {
    background: $color-green-100;
    border-radius: 24px;
    padding: 9px 16px;
    color: $color-green-600;

    &--active {
      background: $color-green-100;
      color: $color-green-600;
    }

    &--inactive {
      background: $color-blue-50;
      color: $color-slate-600;
    }
  }
}

.csv-import-panel {
  max-width: 604px !important;
  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);

  .mdc-dialog__surface {
    padding: 4em;
    background-color: #f6f8fb;
    width: 100%;

    @media screen and (max-width: 767px) {
      padding: 1.5em;
      max-width: 95vw;
    }
  }

  table {
    width: 100%;
    position: relative;
    margin-top: 40px;

    th {
      text-align: left;
    }

    td {
      padding: 8px 0 !important;
    }

    // .csv-border {
    //   height: 1px;
    //   width: 100%;
    //   background-color: $color-slate-500;
    //   position: absolute;
    // }

    .mat-icon {
      color: $color-green-600;
    }
  }

  .view_button {
    display: flex;
    gap: 16px;
    margin-top: 40px;
  }

  .csv-loading-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  mat-icon.mat-icon.warn-icon {
    width: 120px !important;
    height: 120px !important;
    color: $color-yellow-700;
  }

  .error-icon {
    color: $color-yellow-700;
  }

  .csv-sended-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .mat-icon {
      width: 132px !important;
      height: 132px !important;
      color: $color-green-600;
      margin-bottom: 20px;
    }

    .view_button {
      display: flex;
      gap: 16px;
      margin-top: 40px;
      width: 100%;
      justify-content: center;
    }

    .mat-unelevated-button.mat-primary {
      max-width: 310px;
    }

    .h3 {
      margin-bottom: 20px;
    }
  }
}

.beta-info-wrap {
  .h1 {
    margin-bottom: 20px;
  }

  button {
    margin-top: 20px;
    width: 100%;
  }

  li {
    text-align: left;
    list-style-type: disc;
  }
}

.beta-info-panel {
  max-width: 655px !important;
  max-height: 100vh;
  box-shadow:
    0px 8px 28px -6px rgb(24 39 75 / 12%),
    0px 18px 88px -4px rgb(24 39 75 / 14%);

  .helpdesk_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    text-align: center;
    margin: 0 auto;

    @media screen and (max-width: 1080px) {
      padding: 2em 1em;
    }

    mat-icon.mat-icon {
      width: 120px !important;
      height: 120px !important;
      color: $color-slate-700;
      margin-bottom: 26px;
      @media screen and (max-width: 1080px) {
        width: 80px !important;
        height: 80px !important;
        margin-bottom: 1em;
      }
    }

    .h3 {
      margin-bottom: 20px;
    }

    form {
      max-width: unset;
      margin-top: 20px;
    }

    .button_wrap {
      display: flex;
      gap: 16px;
    }
  }
  @media screen and (max-width: 1080px) {
    width: 90% !important;
    max-width: unset !important;
  }
}

.task-action-wrap,
.table-buttons {
  display: flex;
  gap: 8px;
}

.mdc-fab.mat-mdc-fab.mat-accent {
  background-color: $color-green-600;
  color: #fff;
}
/* 
td.mat-column-align--right .not-filled {
  margin-right: 2em;
} */

.mdc-fab--mini.mat-mdc-mini-fab {
  //OVERRIDES MATERIAL V15 PRESETS
  width: 30px;
  height: 30px;
  background-color: $button-color;
  // background-color: $background-white;  LLTS-202
  color: #fff;
  box-shadow: none !important;

  .mat-icon {
    padding: 3px;

    &.eye-icon {
      padding-top: 3px;
      padding-bottom: 2px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.not-filled {
    background-color: white; //LLTS-202
    color: #232323;

    &.expand .mat-icon {
      padding: 0 !important;
    }
  }
}

.teacher-task-list-filter-wrap {
  margin: 30px 0;
  width: 100%;

  .col-md-4 {
    padding: 6px;
  }

  .task_status_select {
    width: 100%;
    //overflow: hidden;

    .mat-select {
      max-width: 76%;
    }
  }

  .task_type_select {
    width: 100%;
    //overflow: hidden;

    .mat-select {
      max-width: 52%;
    }

    .mat-mdc-text-field-wrapper {
      background: #fff;
    }
  }

  .task-label-wrap {
    white-space: nowrap;
  }
}

.datepicker_select {
  .mat-mdc-form-field-icon-infix {
    display: flex;
    align-items: center;
  }

  .mdc-icon-button {
    padding-right: 35px;
    padding-block: 9px;
  }
}

.microphone-check-overlay-panel {
  .view_button {
    margin-top: 40px;
  }

  .h3 {
    margin-bottom: 12px;
  }

  .microphone-check_start {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    img {
      max-width: 345px;
    }

    .mat-primary-student {
      min-width: 234px;
    }
  }

  .microphone-check_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &--rec {
      .mic-button {
        background: $color-slate-400 !important;
        border: 2px solid transparent;
        position: relative;
        aspect-ratio: 1;
        border: 2px solid #ddd;
        border-radius: 50%;
        position: relative;
      }

      .loader {
        width: 80px;
        aspect-ratio: 1;
        background: $color-slate-400 !important;
        border: 2px solid #ddd;
        border-radius: 50%;
        position: relative;
        transform: rotate(45deg);
        display: flex;
        align-items: center;
        justify-content: center;

        mat-icon.mat-icon {
          transform: rotate(-45deg);
        }
      }

      .loader::before {
        content: '';
        position: absolute;
        inset: -2px;
        border-radius: 50%;
        border: 4px solid $color-orange-600;
        animation: l18 16s linear;
      }

      @keyframes l18 {
        0% {
          clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
        }

        25% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
        }

        50% {
          clip-path: polygon(
            50% 50%,
            0 0,
            100% 0,
            100% 100%,
            100% 100%,
            100% 100%
          );
        }

        75% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
        }

        100% {
          clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
        }
      }
    }

    .button-border {
      background: #fff;
      padding: 16px;
      border-radius: 50%;
    }

    &--audio-screen {
      img {
        max-width: 275px !important;
      }

      .view_button {
        display: flex !important;
        gap: 24px !important;
        width: 100% !important;
      }
    }

    &--finished {
      img {
        max-width: 180px !important;
      }

      .mat-primary-student {
        min-width: 234px;
      }
    }

    .mat-progress-bar {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    mat-accordion.mat-accordion {
      margin-top: 20px;
      background: $color-b-w-0;
      border-radius: 10px;
      padding: 0 16px 16px 16px;

      .mat-expansion-indicator::after {
        border-color: $color-blue-600;
      }

      .paragraph {
        color: $color-b-w-800 !important;
      }

      .accordion-headline {
        color: $color-blue-600 !important;
      }

      .mat-expansion-panel-content {
        background-color: $color-b-w-0;
        text-align: left;
      }

      mat-expansion-panel-header {
        border-radius: 0;
        border-color: $color-slate-350;
      }

      mat-expansion-panel.mat-expanded {
        border-radius: 0;
        border-color: $color-slate-350;

        mat-expansion-panel-header {
          border: none;
        }
      }
    }

    .audio-container {
      display: flex;
      width: 100%;
      gap: 16px;

      .audio_wrap {
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: #fff;
        padding: 16px 24px;
        border-radius: 100px;
        max-width: 100%;
        justify-content: center;
      }
    }

    .mic-button--repeat {
      background-color: $color-blue-500 !important;
    }

    img {
      max-width: 180px;
    }

    .mic-button {
      min-width: 80px;
      min-height: 80px;
      height: 100%;
      background: $color-orange-600;
      position: relative;
    }

    .stop-rec-button {
      min-width: 80px;
      min-height: 80px;
      height: 100%;
      background: $color-green-500;
      color: #fff;
    }

    .button-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      mat-icon.mat-icon {
        width: 36px !important;
        height: 36px !important;
      }

      .paragraph {
        color: $color-slate-550;
      }
    }
  }
}

.hidden-sidebar {
  display: none;
}

.sidebar_menu {
  max-width: 315px !important;

  .info-text {
    margin-top: 15px;
    display: block;
  }
}

.task-filter-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;

  .mdc-button.mdc-button--unelevated.mat-unset {
    color: $button-color-bright;
  }
}

.resend-email {
  padding-top: 0 !important;
  margin-top: 40px;
  max-width: 360px;
}

.student-progress-level-container {
  height: 5px;
  margin: 35px 0px 0px 0;
  background: grey;
  position: relative;
  /* relative here */

  &--numbers {
    margin-bottom: 15px;
  }

  &--reading-points {
    margin: 10px 0px 0px 0 !important;

    .statusbar-selector {
      left: 0 !important;
      position: relative;
      width: 50%;
      height: 5px;
      background: #232323;
      top: -5px;
      transform: rotate(0deg);
    }

    .status-bar-indicator {
      height: 100%;
      border-radius: 25px;
      transition: 2s width;
      background: $color-slate-300;
    }
  }

  &--multiple-colors {
    .status-bar-indicator {
      height: 100%;
      border-radius: 25px;
      /* this will do the magic */
      -webkit-mask: linear-gradient($color-b-w-0 0 0);
      mask: linear-gradient($color-b-w-0 0 0);
      transition: 2s width;
      background: linear-gradient(
        to right,
        $color-red-500,
        $color-red-500 20%,
        $color-yellow-500 20%,
        $color-yellow-500,
        $color-yellow-500 40%,
        $color-green-500 40%,
        $color-green-500,
        $color-green-500 60%,
        $color-green-500 60%,
        $color-green-500,
        $color-green-500 80%,
        $color-green-500 80%
      );
    }

    .statusbar-selector {
      left: calc(50% - 6.5px);
      position: relative;
      width: 13px;
      height: 3px;
      background: $color-b-w-900;
      top: -4px;
      transform: rotate(90deg);
    }
  }

  .status-bar {
    position: relative;
    height: 3px;
    left: 0;
  }

  .status-numbers {
    top: -41px;
  }

  .only-status-bar {
    top: -20px;
  }

  .statusbar-text {
    left: calc(50% - 6.5px) !important;
    position: relative;
    top: -15px;
    height: max-content;
    width: max-content;

    @media screen and (max-width: 929px) {
      left: calc(60% - 6.5px) !important;
    }
  }

  .numbers-wrap {
    display: flex;
    justify-content: space-around;
    margin-top: 6px;
  }
}

.school_filter {
  margin: 24px 0;
  width: 100%;
  display: flex;

  .mat-select {
    overflow: hidden;
  }
}

.search-school {
  .mat-mdc-text-field-wrapper {
    border-radius: 30px;
    padding: 13px 20px;
  }
}

.toggle-buttons-wrap {
  mat-button-toggle-group.mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
    border: none;
    gap: 6px;
  }

  .mat-button-toggle {
    border-radius: 10px;
    background-color: $color-b-w-10;

    span.mat-button-toggle-label-content {
      color: $color-b-w-900;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .mat-button-toggle-group-appearance-standard
    .mat-button-toggle
    + .mat-button-toggle {
    border: none;
  }

  .mat-button-toggle-checked {
    background: $color-green-600;

    span.mat-button-toggle-label-content {
      color: #fff;
    }
  }
}

.gender-selection.student-edit {
  padding-bottom: 1.25em;

  mat-radio-group.mat-radio-group {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
  }

  span.mat-radio-label-content {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.school-info-wrap {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  height: 100%;
  position: relative;

  .school-info {
    margin-top: 16px;
  }
}

.school-detail-container {
  .admin-license-widget {
    margin-top: 0;
  }

  .school-info-row {
    margin-top: 34px;

    .admin-license-widget .admin-license-widget_header {
      position: absolute;
    }

    .mat-mdc-tab-labels {
      display: flex;
      justify-content: flex-end;
    }
  }

  .license_tabs {
    margin-bottom: 20px;
  }
}

.school_teachers-tabs {
  margin-top: 20px;

  .license-action-wrap .mat-icon-button .mat-icon {
    // width: 19px !important;
    // height: 17px !important;
  }
}

.backText {
  color: $color-slate-600;
}

.school-name-text {
  color: $color-b-w-800;
}

.school_filter.school_filter-detail {
  margin: 20px 0 16px 0;

  .mat-mdc-form-field {
    padding-bottom: 0;
  }

  .mat-mdc-text-field-wrapper {
    background: #fff;
  }
}

span.gender-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.help-desk-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button.mat-focus-indicator.mat-unelevated-button.mat-button-base.mat-primary {
    max-width: fit-content;
  }
}

.color-slate {
  color: $color-slate-550;
}

.license-check-panel {
  max-width: 1024px !important;
  width: 100%;
  background: #f6f8fb;
  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  border-radius: 12px;

  textarea {
    border: 1px solid $color-blue-200;
    border-radius: 12px;
    padding-left: 20px !important;
  }

  .mat-mdc-dialog-container {
    padding: 60px;
    background: #f6f8fb;
    max-height: 96vh;
  }

  .closeOverlay {
    background: #fff;
    position: absolute;
    right: -20px;
    top: -40px;
    color: $color-slate-600;

    .mat-icon {
      min-width: 9px !important;
      min-height: 9px !important;
    }
  }

  .instructionVideo_wrap {
    display: flex;
    gap: 16px;
  }

  form {
    width: 100%;
    max-width: unset;
  }

  .container-fluid {
    padding: 0;
  }

  .license-add-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button_wrap {
      display: flex;
      width: 100%;
      gap: 20px;
      margin-top: 40px;
    }

    .class_header {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      mat-icon.mat-icon {
        margin-right: 10px;
        background: #ebeeef;
        border-radius: 100px;
        min-width: 36px !important;
        min-height: 36px !important;
        padding: 6px;
        color: $color-slate-600;
      }
    }

    .info_headline {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      mat-icon.mat-icon {
        width: 132px !important;
        height: 132px !important;
        color: $color-green-600;
        margin-bottom: 20px;
      }

      .h3 {
        margin-bottom: 16px;
      }
    }

    .button-wrap {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: center;

      .mat-unelevated-button.mat-primary {
        max-width: 202px;
      }
    }

    .license-add-apply-box {
      padding: 16px;
      border-radius: 12px;
      background-color: $color-b-w-0;
      width: 100%;
      margin-top: 40px;

      .button_wrap {
        display: flex;
        gap: 20px;
        margin-top: 24px;
      }

      .license-amount {
        position: absolute;
        right: 0;
        top: 19px;
        right: 16px;
        color: $color-slate-550;
      }

      .mat-mdc-form-field {
        max-width: 190px !important;
      }

      mat-label {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
        color: $color-b-w-900;
      }
    }
  }

  input::placeholder {
    color: $color-slate-500;
  }

  .license_input {
    position: relative;
    margin-bottom: 8px;

    .mat-icon {
      position: absolute;
      top: 12px;
      right: 15px;
      color: $color-slate-500;
    }
  }

  .license-input {
    height: unset;
    border: unset;
    min-height: unset;
    display: block;
    margin-bottom: 0;
    padding-left: 0;
  }

  input {
    min-height: 5%;
    width: 100%;
    height: 50px;
    border: 1px solid $color-slate-500;
    border-radius: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #3f3f3f;
    text-align: left;
    padding: 0 24px;
  }

  .instructionVideo_wrap {
    margin-top: 40px;
  }

  mat-header-cell {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: $color-b-w-800;
  }

  mat-header-row.mat-header-row {
    border: none;
  }

  .mat-row.mat-row.cdk-row {
    background: #fff;
  }

  .mat-table {
    background: #f6f8fb;
    max-height: 500px;
    overflow-y: overlay;
    overflow-x: hidden;
    height: auto;
  }

  mat-cell.mat-cell {
    font-weight: 700;
    font-size: 16px;
    line-height: 25px;
    color: #3f3f3f;
  }

  button.mat-button-base.mat-primary.done {
    max-width: 309px;
  }
}

.school-license-panel {
  max-width: 1200px !important;
  width: 100%;
  background: #f6f8fb;
  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  border-radius: 12px;

  .mat-mdc-dialog-container {
    padding: 60px;
    background: #f6f8fb;
    max-height: 96vh;
  }

  .closeOverlay {
    background: #fff;
    position: absolute;
    right: -20px;
    top: -40px;
    color: $color-slate-600;

    .mat-icon {
      min-width: 9px !important;
      min-height: 9px !important;
    }
  }

  .school-license-detail-header {
    display: flex;
    justify-content: center;
  }
}

.school-license-filter {
  margin: 24px 0 0px 0;
  display: flex;
  justify-content: space-between;

  .mat-form-field {
    max-width: 330px;
    width: 100%;
    margin-top: 0;
  }

  .mat-mdc-text-field-wrapper {
    background: #fff;
    max-height: 48px;
  }

  // .toggle-buttons-wrap .mat-button-toggle {
  //     background-color: $color-blue-50 !important;
  //     width: fit-content;
  //     height: fit-content;
  //     border-radius: 100px;
  //     min-width: 75px;

  //     .toggle-buttons-wrap .mat-button-toggle span.mat-button-toggle-label-content {
  //         color: $color-slate-600 !important;
  //     }
  // }

  .mat-button-toggle {
    border-radius: 10px;
    background-color: $color-blue-50;
    width: fit-content;
    height: fit-content;
    border-radius: 100px;

    span.mat-button-toggle-label-content {
      color: $color-slate-600 !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }

  .toggle-buttons-wrap .mat-button-toggle-checked {
    background: $color-green-600 !important;
    width: fit-content;
    height: max-content;

    span.mat-button-toggle-label-content {
      color: $color-b-w-0 !important;
    }
  }
}

.create_school-container {
  .circle.light.task_close {
    left: -20px;
  }

  .training-task_wrap.teacher form {
    min-height: -webkit-fill-available;
  }

  .training-task_wrap.teacher {
    padding: 60px 60px 0px 44px;
    height: 90vh;
  }

  .training-task_select_button {
    width: calc(100% + 120px);
  }
}

.school_edit-button {
  position: absolute !important;
  right: 20px !important;
}

.school-create-header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.school-delete_container {
  .mat-unelevated-button.mat-primary {
    max-width: 310px;
    margin: 0 auto;
  }
}

.icon-border-group-create {
  border: 1.5px solid $color-green-600;
  border-radius: 50px;
  padding-left: 2px;
  width: 28px !important;
  height: 28px !important;
}

mat-icon.mat-icon.icon-border-group-create.group-question {
  height: 40px !important;
  top: 0 !important;
  width: 40px !important;
  padding: 0 !important;
  cursor: pointer;
  color: $color-green-600;
  margin: 0;
}

.teacher_icon_wrap-tablet {
  position: relative;

  .admin-icon {
    position: unset;
  }

  .teacher_icon {
    padding: 0;
    margin-right: -3px;
    height: 36px;
    width: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .admin-icon {
    position: absolute;
    right: -13px;
    top: -3px;
  }
}

.visible-tablet-or-smaller.mat-unelevated-button {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  padding: 9px 14px !important;
}

.automode-day-selection-wrap {
  .mat-mdc-chip-set .mdc-evolution-chip-set__chips {
    display: flex;
    justify-content: center;
    gap: 0.15em;
    flex-wrap: nowrap;
  }
  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .mat-mdc-chip-focus-overlay {
    background-color: $button-color-lighter;
  }

  .mat-mdc-chip.mat-primary.mdc-evolution-chip {
    border: 1px gray solid;
    height: 50px !important;
    width: 64px !important;
    border-radius: 12px !important;
    cursor: pointer;

    .paragraph {
      color: $color-b-w-900;
    }
    &.active {
      background-color: $button-color-lighter !important;
      border-color: $button-color !important;

      .paragraph {
        color: $button-color;
      }
    }
    &.disabled {
      border: 1px solid $color-slate-500;
      background: $color-b-w-0 !important;
      .paragraph {
        color: $color-slate-500;
      }
    }
  }
}

.duration-form-field {
  max-width: 300px;
  margin: 0 auto;
}

.mat-form-field--honey {
  display: none !important;
}
//MAKES WIDTH OF PANE IN NON SCHOOL REGISTER AUTO SIZED
.expandable-overlay-panel {
  width: auto !important;
}

app-non-school-register-stepper {
  .title {
    margin-bottom: 24px;
  }

  .tel_form-field {
    input {
      margin-left: 68px;
    }
  }

  .country-selection {
    position: absolute;
    left: 5px;
    top: 10px;

    .mat-icon {
      max-width: 16px !important;
      max-height: 16px !important;
    }

    .mat-select-value {
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .mdc-text-field--outlined .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__notch {
        border: none;
      }
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;
      height: 34px;
      left: -10px;
      width: 65px;

      &.mdc-text-field--outlined .mat-mdc-form-field-infix {
        padding: 0;
      }

      .mat-mdc-select {
        background: $color-slate-200;
        border-radius: 10px;
        padding: 0.25em;
      }
      .mat-mdc-select-arrow-wrapper {
        align-items: center;

        .mat-mdc-select-arrow {
          margin-right: 5px;
        }
      }
    }
    .mat-mdc-select-value {
      margin-left: 5px;
    }

    /* .mat-select-trigger.ng-tns-c16-5 {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
    } */
  }
}

.country-search-wrap {
  position: relative;

  .country-search {
    height: 40px;
    border-bottom: 1px solid $color-slate-350 !important;
    padding-left: 45px !important;
    //width: 82% !important;
  }

  mat-icon {
    max-width: 20px;
    max-height: 20px;
    position: absolute;
    top: 9px;
    left: 12px;
  }
}

.non-school-stepper {
  .step-wrap {
    position: relative;
  }
  .paragraph--xs {
    position: absolute;
    bottom: -17px;
    width: max-content;
  }
  .step-divider {
    width: 85px;
    top: -2px !important;
  }
}

.placeholder-flag {
  position: absolute;
  left: 10px;
  top: 8px;
}

.avatar-selection {
  margin-top: 44px;
  // text-align: center;
  padding-bottom: 100px;
  .mdc-radio-background {
    width: 28px;
    height: 28px;
  }

  .mdc-radio__native-control:checked + .mdc-radio__background {
    .mdc-radio__outer-circle {
      border: 6px $button-color solid !important;
    }
    .mdc-radio__inner-circle {
      display: none;
    }
  }
  mat-radio-group.mat-mdc-radio-group {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 32px;
  }
  mat-radio-button.mat-mdc-radio-button {
    .mdc-form-field {
      display: flex;

      > label {
        text-align: left;
        display: flex;
        max-width: 800px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: $button-color-lighter !important;
    --mdc-radio-selected-hover-icon-color: $button-color-lighter !important;
    --mdc-radio-selected-icon-color: $button-color-lighter !important;
    --mdc-radio-selected-pressed-icon-color: $button-color-lighter !important;
  }

  /* MAKES THE RADIO AND ALL ITS FEATURES BIGGER  */
  .mdc-radio {
    width: 30px;
    height: 30px;
  }
  .mdc-radio__background {
    width: 28px;
    height: 28px;
  }
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    top: calc(
      (
          var(--mdc-radio-state-layer-size, 50px) - var(
              --mdc-radio-state-layer-size,
              50px
            )
        ) /
        2
    );
    right: calc(
      (
          var(--mdc-radio-state-layer-size, 50px) - var(
              --mdc-radio-state-layer-size,
              50px
            )
        ) /
        2
    );
    left: calc(
      (
          var(--mdc-radio-state-layer-size, 50px) - var(
              --mdc-radio-state-layer-size,
              40px
            )
        ) /
        2
    );
    width: var(--mdc-radio-state-layer-size, 50px);
    height: var(--mdc-radio-state-layer-size, 50px);
    --mat-mdc-radio-checked-ripple-color: $button-color-lighter !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
    top: calc((50px - 50px) / 2);
    right: calc((50px - 50px) / 2);
    left: calc((50px - 50px) / 2);
    width: 50px;
    height: 50px;
  }
  .mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
    top: calc(-1 * (var(--mdc-radio-state-layer-size, 49px) - 28px) / 2);
    left: calc(-1 * (var(--mdc-radio-state-layer-size, 49px) - 28px) / 2);
    width: var(--mdc-radio-state-layer-size, 50px);
    height: var(--mdc-radio-state-layer-size, 50px);
  }
  .mdc-radio__inner-circle {
    top: -5px;
    left: -5px;
    width: 40px;
    height: 40px;
    border-width: 13px;
  }

  .avatar_recommended {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $color-green-600;
    background: $color-green-100;
    border-radius: 100px;
    padding: 2px 6px;
    // margin-left: 16px;
  }

  mat-radio-button {
    border: 1px solid transparent;
    border-radius: 12px;
    padding: 20px;
    background: $color-b-w-50;
    &.mat-mdc-radio-checked {
      border: 1px solid $color-blue-600;
      .mdc-radio__outer-circle,
      .mdc-radio__inner-circle {
        border-color: $color-blue-600 !important;
      }
    }
    .mat-ripple-element {
      background-color: $color-blue-600 !important;
    }
  }

  .mat-radio-checked {
    border: 1px solid $color-green-600;
  }

  img {
    max-width: 216px;
    width: 100%;
  }

  .mat-radio-container {
    width: 32px;
    height: 32px;
  }

  .mat-radio-outer-circle {
    width: 32px;
    height: 32px;
  }
  .mat-radio-inner-circle {
    background: transparent !important;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $button-color;
    background: $button-color;
    width: 32px;
    height: 32px;
  }

  .mat-radio-button.mat-accent.mat-radio-checked
    .mat-radio-outer-circle::after {
    content: '';
    background: url('./assets/check.svg') no-repeat center center;
    background-size: contain;
    width: 20px; // Passe die Breite und Höhe an deine Bedürfnisse an
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.mat-mdc-radio-button.mat-accent {
  --mat-mdc-radio-ripple-color: button-color-bright !important;
  --mat-mdc-radio-checked-ripple-color: $button-color-bright !important;
}

.table_icon {
  top: 5px;
  position: relative;
}

.set_inline.student_task_book_name {
  margin-left: 48px;
}

app-dashboard-help-desk {
  .container-fluid--full-height {
    height: 100%;
  }
}

.expert-group-overlay-panel {
  max-width: 980px !important;

  .mat-mdc-dialog-container .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 4em;
  }

  .expert_group-logo {
    max-width: 190px;
  }

  .expertgroup_title {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    color: $color-text-gray;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  form {
    max-width: unset;
  }

  .exert_group_info {
    text-align: center;
    margin-top: 40px;
    .paragraph--xl {
      color: $color-orange-600;
      margin-bottom: 10px;
    }
  }

  button.mdc-button.mdc-button--unelevated.mat-primary.teacher_button_primary {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .material-icons_list {
    width: 50px !important;
    height: 50px !important;
    font-size: 50px;
  }
  .expertgroup_footer {
    width: 100%;
    .expertgroup_title {
      width: 100%;
    }
  }
}

.expert_group_header {
  display: flex;
  align-items: center;

  @media screen and (max-width: 1200px) {
    width: 380px;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (max-width: 987px) {
    display: none;
  }

  .expert_group_header_new {
    color: $color-orange-600;
    font-weight: bold;
  }

  .expert_group_header_new_logo {
    max-width: 105px;
  }

  .expert_group_overlay {
    text-decoration: underline;
    cursor: pointer;
  }
}

.language-toggle {
  font-size: 14px;
  border: none;

  .mat-icon {
    font-size: 20px;
    height: 19px !important;
    width: 19px !important;
    margin-right: 5px;
    margin-top: 5px;
  }
}

.language-dropdown {
  align-items: center !important;
  text-transform: uppercase;
  margin-right: 1em;

  .mat-icon {
    margin-top: 5px;
  }
  @media screen and (max-width: 767px) {
    .mdc-button .mdc-button__label {
      font-size: 12px;
      margin-left: -10px;
    }
  }
}

.language-dropdown-panel {
  mat-list-item {
    font-weight: 500;
    &:hover {
      cursor: pointer;
    }
  }
}

.session-upload-overlay-panel {
  max-width: 735px !important;
  overflow: auto;
  max-height: 96vh;
  border-radius: 16px;

  img {
    max-width: 300px;
  }

  .mat-dialog-container {
    max-width: 735px;
    margin: 0 auto;
    border-radius: 16px;
    padding: 60px;
    position: relative;
    background-color: $color-b-w-10;
  }

  .session-upload-wrap {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }

  .mat-progress-bar {
    margin-top: 20px;
  }
}

.expert-group-overlay-panel {
  .mat-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgb(24 39 75 / 12%),
      0px 18px 88px -4px rgb(24 39 75 / 14%);
    border-radius: 12px;
    max-width: 980px;
    margin: 0 auto;
    width: 100%;
    padding: 80px 60px 60px 60px;
    position: relative;
    background-color: $color-b-w-10;
  }

  button.mat-focus-indicator.closeOverlay.mat-icon-button.mat-button-base {
    position: absolute;
    right: 20px;
    background: #fff;
    top: 20px;
  }

  .expert_group-logo {
    max-width: 190px;
  }

  .expertgroup_title {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    color: $color-text-gray;
    flex-wrap: wrap;
  }

  form {
    max-width: unset;
  }

  .exert_group_info {
    text-align: center;
    margin-top: 40px;
    .paragraph--xl {
      color: $color-orange-600;
      margin-bottom: 10px;
    }
  }

  button.mat-focus-indicator.teacher_button_primary {
    max-width: 300px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .material-icons_list {
    width: 50px !important;
    height: 50px !important;
    font-size: 50px;
  }
}

.language-alert-container {
  .h3 {
    margin-bottom: 20px;
  }
}

.license-accept.distribute-license {
  background: red;

  .mat-icon {
    color: #fff;
  }
}

.license-distribution-panel {
  .mat-mdc-dialog-container {
    box-shadow:
      0px 8px 28px -6px rgb(24 39 75 / 12%),
      0px 18px 88px -4px rgb(24 39 75 / 14%);
    border-radius: 12px;
    max-width: 980px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    background-color: $color-b-w-10;
  }

  .h1 {
    text-align: center;
  }
  table {
    width: 100%;
    margin-bottom: 40px;
  }
  .license-distribution_headline {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    text-align: center;
    margin: 40px 0;
    .distribution_headline_item {
      display: flex;
      flex-flow: column;
      align-items: center;
    }
  }
}

button.mdc-button.more_action {
  //@media screen and (min-width: 1024px) {
  border: 1px solid $color-b-w-200;
  max-width: 130px;
  padding-inline: 0.5em;
  height: 40px;
  //}

  &.action_select {
    background-color: $color-b-w-200;
    border: 1px solid $color-b-w-200;
    svg {
      rotate: 180deg;
      cursor: pointer;
    }
  }
  &:hover {
    background-color: $background-card;
  }
  &.small {
    max-width: 64px;
    margin-left: auto;
  }
}
.rotate_icon {
  rotate: 180deg;
}

.student_option_menu {
  margin: 10px;
  padding: 0;
  li {
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-light-black;
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 10px 12px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: $color-b-w-10;
      color: $button-color-darker;
      mat-icon {
        svg {
          path {
            stroke: $button-color-darker !important;
          }
        }
      }
    }

    button {
      background-color: transparent;
      border: 0;
      max-width: 100%;
      display: flex;
      cursor: pointer;
      align-items: center;
      gap: 12px;
      justify-content: flex-start;
      padding: 0;
      // color: inherit;
      &[disabled] {
        cursor: not-allowed;
        opacity: 0.4;
      }
      &:hover {
        color: $button-color-darker;
      }
      &.delete-btn,
      &.delete-btn:hover {
        color: $color-red-500;
        mat-icon {
          svg {
            path {
              stroke: $color-red-500 !important;
            }
          }
        }
      }
    }
  }
  hr {
    height: 1px;
    border-width: 0;
    color: gray;
    background-color: $color-b-w-200;
  }
}
.delete_msg {
  font-size: 14px;
  font-weight: 400px;
  line-height: 19px;
  cursor: not-allowed;
  padding: 1em 1em 0 1em;

  p {
    display: flex;
    align-items: center;
    gap: 1em;
    color: $color-slate-500;
  }
  p + p {
    margin-top: 1em;
  }
}
.wider-mat-menu .mat-mdc-menu-panel.mat-mdc-menu-panel {
  min-width: 280px !important;
  border-radius: 10px !important;
}

.search_teacher_control {
  position: relative;
  mat-form-field {
    .mdc-text-field--outlined {
      height: 42px !important;
    }
    .mat-mdc-form-field-infix {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
    .mat-mdc-floating-label {
      top: 20px !important;
    }
  }
  .search_icon {
    position: absolute;
    right: 3px;
    top: 5px;
    background-color: $color-b-w-50;
    border-radius: 100px;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.dropdown-select-with-arrow {
  position: relative;
  .mat-select-arrow {
    position: absolute;
    right: 0px;
    top: 13px;
    cursor: pointer;
  }
}
.group-select {
  position: relative;
  .mat-select-arrow {
    position: absolute;
    right: 0px;
    top: 13px;
    cursor: pointer;
  }
}

.mdc-list-item__primary-text {
  width: 100% !important;
}

.circle_check_box {
  .mdc-checkbox__background {
    border-radius: 100% !important;
  }
  .mdc-checkbox__checkmark {
    width: 15px !important;
    transform: translateX(15%) translateY(15%) !important;
  }
}
.icon-80 {
  width: 80px;
}

.trial_notification {
  margin-block: 1em;
  .notification_box {
    border-radius: 16px;
    padding: 16px 16px;

    .tn-heading {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }

    &.trial-mode {
      .mdc-button {
        margin-left: auto;
        & + .mdc-button {
          max-width: 220px;
        }
      }
      .tn-heading {
        color: $color-blue-600;
      }
      .paragraph {
        color: $color-blue-600;
      }
      border: 1px solid $color-blue-200;
      background-color: $color-blue-50;
      .mat-primary {
        background-color: $color-blue-600 !important;
      }
      .mat-secondary {
        background-color: transparent !important;
        color: $color-blue-600 !important;
        border: 1px solid $color-blue-600 !important;
      }
    }
    &.expiry-mode {
      .tn-heading {
        color: $color-orange-600;
      }
      .paragraph {
        color: $color-orange-600;
      }
      border: 1px solid $color-yellow-300;
      background-color: $color-secondary-pale-yellow;

      .mat-primary {
        background-color: $color-orange-600 !important;
      }
      .mat-secondary {
        background-color: transparent !important;
        color: $color-orange-600 !important;
        border: 1px solid $color-orange-600 !important;
      }
    }
  }
}

.button_wrap {
  display: flex;
  gap: 16px;
  margin-top: 40px;
  width: 100%;
  justify-content: center;
}

.substitute_teacher {
  .selected-group {
    background-color: transparent !important;
  }
}

.trial-banner-panel {
  max-width: 606px !important;
  width: 100%;

  .mat-mdc-dialog-container {
    position: relative;
    background-color: $color-b-w-10;
    overflow: auto;
    height: auto;
    border-radius: 12px;
  }
}
.add_learner_btn {
  display: flex;
  align-items: center;
  P {
    color: $color-blue-600;
  }
}

.add-teacher-form {
  mat-label {
    display: flex;
  }
}

.avatar-hint-box {
  position: absolute;
  right: -430px;
  top: 50px;
  .hint-box_arrow {
    left: -75px !important;
    transform: scale(-1) rotate(45deg);
    -webkit-transform: scale(-1) rotate(45deg);
  }
}

.radio-check {
  mat-radio-button {
    .mdc-radio {
      width: 32px !important;
      height: 32px !important;
      .mdc-radio__background {
        width: 32px !important;
        height: 32px !important;
      }
    }
  }
}

.color-red-500 {
  color: $color-red-500;
}

.mat-calendar-body-selected {
  color: white;
}
@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(
      .mat-calendar-body-comparison-identical
    ) {
    color: white;
  }
}
.zoom-warning-panel {
  .mat-mdc-dialog-container {
    border-radius: 12px !important;

    .mat-mdc-dialog-surface.mdc-dialog__surface {
      padding: 2em;
      background-color: #f6f8fb;
      max-width: 50%;
      position: absolute;
      bottom: 10px;
      right: 30px;
      height: auto;
      box-shadow: --4px -4px 5px 2px rgba(82, 81, 81, 0.051);
      -webkit-box-shadow: -4px -4px 5px 2px rgba(82, 81, 81, 0.051);
      -moz-box-shadow: -4px -4px 5px 2px rgba(82, 81, 81, 0.051);
    }
  }
}

.task_creation_btn {
  margin-block: 1em !important;
  .create-task-inside-span {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    gap: 1em;
    font-weight: 600;

    @media screen and (max-width: 1199px) {
      .mat-icon {
        margin-right: -5px !important;
        margin-left: -3px !important;
      }
      .task-create-btn-text {
        width: 138px !important;
        margin-left: -5px !important;
        font-weight: 600;
      }
    }

    @media screen and (min-width: 1200px) {
      width: 256px;
    }
  }
}

app-loading-spinner.full-height .loading-spinner {
  height: 50vh;
}
.recording-finished-panel
  .mat-mdc-dialog-container
  .mat-mdc-dialog-surface.mdc-dialog__surface {
  background-color: $color-b-w-10;
  padding: 3em;
}

.cursor-pointer {
  cursor: pointer !important;
}
.upload-csv-box {
  a.cursor-pointer {
    font-size: 16px;
    color: blue;
  }
}

.button-up {
  margin-bottom: 3.5em;
}

.group-select-header .mat-expansion-indicator::before,
.mat-expansion-indicator::after {
  border-color: black !important;
}
.table_of_content {
  .max-w-xs {
    max-width: 100% !important;
    width: 100%;

    .border-l-2 {
      width: 100%;
    }
  }
}

.blue-icon.mat-icon {
  color: $color-blue-600 !important;
}

@media screen and (max-width: 1080px) {
  .admin-teacher-edit_wrap {
    padding: 2em 4em;
    .row {
      display: contents !important;
      .col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
  .admin-change-password_wrap {
    padding: 2em 5em;
    .row {
      flex-direction: column !important;
      .col-md-6 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
      }
    }
  }
  app-admin-group-list td.mat-column-teacher {
    min-width: 250px !important;
  }
  .license-action-wrap,
  .option_button_wrap {
    .more_action {
      .mdc-button__label {
        display: none;
      }
    }
  }
  .option_button_wrap {
    width: 60px !important;
    button.mdc-button.more_action {
      max-width: 50px !important;
      margin-right: 0 !important;
      border: none !important;
    }
    .mdc-button.mdc-button--unelevated.mat-unset .mat-icon {
      margin-right: 0 !important;
    }
  }
  .license-action-wrap {
    button.mdc-button.more_action {
      max-width: 50px !important;
      border: 1px solid $color-slate-300;
      background-color: white;
    }
    .mdc-button.mdc-button--unelevated.mat-unset .mat-icon {
      margin-right: 0 !important;
      margin-top: 4px;
      margin-left: -1px;
    }
  }
}

.teacher-transfer-panel {
  max-width: 100vw !important;
  height: 100vh;
}

app-teacher-transfer.ng-star-inserted {
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teacher-transfer-panel .mdc-dialog__surface {
  background: transparent !important;
}

.transfer-teacher-selection-content {
  label {
    font-size: 18px;
    line-height: 29px;
    font-weight: 700;
    padding: 20px !important;
    cursor: pointer;
    width: 100%;
  }

  mat-radio-button {
    width: 100%;
    border: 1px solid #dbe7ed;
    border-radius: 12px;
  }

  .mdc-radio {
    padding: 0 !important;
    width: 32px !important;
    height: 32px !important;
    margin-right: 10px;
    padding-left: 20px !important;
    cursor: pointer;
  }

  .mdc-form-field {
    width: 100%;
  }
  .mdc-radio__background {
    width: 32px !important;
    height: 32px !important;
  }

  .mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: transparent !important;
    background: #13794f;
  }

  .mdc-radio__native-control:enabled:checked:after {
    font-family: 'Material Icons';
    content: 'done';
    color: $color-b-w-0;
  }
  .mdc-radio__inner-circle {
    border-color: transparent !important;
  }
  mat-radio-button {
    position: relative;
  }
  .custom-radio .mat-icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
    color: white;
    left: 37px;
  }
}

/** IPAD Issue **/

@media screen and (max-width: 1080px) {
  .timemode-overlay {
    max-width: 615px !important;
  }
  .task-start-overlay-task-panel,
  .record-overlay-panel {
    max-width: 600px !important;
  }
  app-students-add-stepper,
  app-group-create-stepper {
    .step_padding {
      &--scroll {
        overflow: inherit !important;
      }
    }
  }
  .task-preview-buttons {
    margin-bottom: 60px !important;
  }
  mat-label {
    font-size: 14px !important;
  }

  .view_button {
    margin-top: 20px;
  }

  .record-overlay-panel .record_overlay_buttons {
    margin-top: 3em;
  }
  .record-overlay-panel .record_overlay_wrap .record_button {
    margin-top: 2em;
  }
  .ds-reader-wrapper {
    height: 97vh !important;
  }
}

.diagnostic_according {
  .mat-expansion-panel-header-title {
    width: 100%;
  }
  .group-select {
    justify-content: space-between;
    width: 100%;
  }
  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    margin-right: 0 !important;
  }
}

// APP ***** Capacitor plugin styles

html.platform-network--offline {
  overflow: hidden;
}

.network_status_check {
  position: fixed;
  z-index: -100;
  pointer-events: auto;
  transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;

  &_message {
    position: fixed;
    display: flex;
    align-items: center;
    background: white;
    gap: 10px;
    padding: 24px;
    border-radius: 8px;
    transform: translateY(100px);
    transition: all 0.3s;
  }

  &.offline {
    opacity: 1;
    z-index: 99999999999;

    .network_status_check_message {
      transform: translateY(-35px);
    }
  }

  &.online {
    opacity: 0;

    .network_status_check_message {
      transform: translateY(100px);
    }
  }
}

.update_information_dialog {
  margin: 0 20px;
  text-align: center;
  z-index: 9999999 !important;

  .mat-mdc-dialog-container {
    border-radius: 12px;
  }

  .mat-mdc-dialog-actions {
    justify-content: center;
  }
  .button_wrap {
    display: flex;
    flex-flow: row;
    gap: 30px;
  }
  button.mdc-button.mat-mdc-button.mat-accent.mat-mdc-button-base.ng-star-inserted {
    background-color: $color-red-500;
    color: $color-b-w-0;
    border-radius: 10px;
  }
}

.update_information_dialog_hide_actions {
  margin: 0 20px;
  text-align: center;
  z-index: 9999999 !important;

  .mat-mdc-dialog-container {
    border-radius: 12px;
  }

  .mat-mdc-dialog-actions {
    justify-content: center;
  }
  mat-dialog-actions.mat-mdc-dialog-actions.mdc-dialog__actions {
    display: none;
  }
}

.maintenance-wrapper {
  z-index: 99999999 !important;
}

.maintenance-backdrop {
  z-index: 9999999;
  background: rgb(0 0 0 / 60%);
}

.home-login_container {
  .home-login-dialog {
    .home-login-dialog-footer,
    .close-button {
      display: none;
    }
  }
}

// ios apple system default blue override
input:is([type='button'], [type='submit'], [type='reset']),
input[type='file']::file-selector-button,
button {
  color: initial;
}

.oauth-groups-update-panel {
  max-width: 100vw !important;
  height: 100vh;
}

app-oauth-error,
app-open-id-connect {
  position: relative;
  top: -90px;
  z-index: 100;
}
